<template>
    <span class="flex items-center space-x-2">
        <PIcon v-tooltip :name="status.icon" :content="status.label" :class="classes" data-testid="content-status" />
        <span v-if="isVisibleTextStatus">
            {{ status.label }}
        </span>
    </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'StatusView'
});
</script>

<script lang="ts" setup>
import { computed } from 'vue';
import { PIcon } from '@/shared/ui';
import { CampaignStatus } from '../model/types';
import type { IconName } from '@/shared/model/types/Icons';

interface Props {
    id: CampaignStatus;
    isVisibleTextStatus?: boolean;
}

const props = defineProps<Props>();

const status = computed(() => {
    const status: Record<
        CampaignStatus,
        {
            id: CampaignStatus;
            icon: IconName;
            label: string;
        }
    > = {
        [CampaignStatus.ACTIVE]: {
            id: CampaignStatus.ACTIVE,
            icon: 'circle-checked',
            label: 'Активна'
        },
        [CampaignStatus.INACTIVE]: {
            id: CampaignStatus.INACTIVE,
            icon: 'minus-circle-outline',
            label: 'Не активна'
        },
        [CampaignStatus.MODERATION]: {
            id: CampaignStatus.MODERATION,
            icon: 'clock',
            label: 'На модерации'
        },
        [CampaignStatus.REJECTED]: {
            id: CampaignStatus.REJECTED,
            icon: 'warning-outline',
            label: 'Отклонена'
        },
        [CampaignStatus.BOOKING]: {
            id: CampaignStatus.BOOKING,
            icon: 'addition',
            label: 'Забронировано'
        }
    };

    return status[props.id];
});

const classes = computed(() => {
    return {
        default: true,
        success: props.id === CampaignStatus.ACTIVE,
        primary: props.id === CampaignStatus.BOOKING,
        warning: props.id === CampaignStatus.REJECTED,
        'primary-secondary': props.id === CampaignStatus.MODERATION
    };
});
</script>

<style lang="scss" scoped>
.default {
    color: var(--text-color-light);
}

.success {
    color: var(--success);
}

.primary {
    color: var(--primary);
}

.primary-secondary {
    color: var(--primary-lighten);
}

.danger {
    color: var(--danger);
}

.warning {
    color: var(--warning);
}
</style>
