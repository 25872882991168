import client from '../client';
import type { Company, CompanyId, CompanyRelationships } from '@/shared/model/types/Company';
import type { CompanyManager } from '@/shared/model/types/CompanyManager';
import type { CompanyAddress } from '@/shared/model/types/CompanyAddress';
import type { Pagination, ServerPagination } from '@/shared/model/types/Pagination';
import * as CompanyMapper from '../mapper/Company';
import * as CompanyManagerMapper from '../mapper/CompanyManager';
import * as CompanyAddressMapper from '../mapper/CompanyAddress';
import * as CompanyBankAccountMapper from '../mapper/CompanyBankAccount';
import * as CompanyFormMapper from '../mapper/CompanyForm';
import * as CompanyDocumentMapper from '../mapper/CompanyDocument';
import * as CompanyContractMapper from '../mapper/CompanyContract';
import type { CompanyBankAccount } from '@/shared/model/types/CompanyBankAccount';
import type { CompanyForm } from '@/shared/model/types/CompanyForm';
import type { CompanyDocument } from '@/shared/model/types/CompanyDocument';
import type { CompanyContract } from '@/shared/model/types/CompanyContract';
import { dayjs } from '@/shared/lib/dayjs/dayjs';
import { saveAs } from 'file-saver';
import type { CompanyStatus, CompanyProtekId } from '@/shared/model/types/Company';
import type { RelationRule } from '@/shared/api/types/JSON_API';
import { getRelationships } from '@/shared/api/utils';
import type { UserId } from '@/shared/model/types/User';
import * as JsonApiDefaultMapper from '@/shared/api/mapper/JsonApiDefaultMapper';
import type { Staff } from '@/entities/Staff';
import { NotFoundError } from '@/errors/NotFoundError';
import type { WorkerId } from '@/entities/Staff/model/Staff';

interface CompanyQueryParams extends Partial<ServerPagination> {
    id?: CompanyId;
    ids?: CompanyId[];
    name?: string;
    wabcCode?: CompanyProtekId;
    wabcCodes?: CompanyProtekId[];
    advertiserId?: number;
    advertiserIds?: number[];
    commercialManagerId?: number;
    statuses?: CompanyStatus[];
    userId?: UserId;
    inn?: string;
    includes?: ('managers' | 'addresses' | 'bankAccounts' | 'form' | 'documents' | 'contracts' | 'commercialManager')[];
}

const convertParams = (params: CompanyQueryParams): Record<string, string | number> => {
    const result: Record<string, string | number> = {};
    if (params.id) {
        result.id = params.id;
    }

    if (Array.isArray(params.ids)) {
        result['id[in]'] = params.ids.join(',');
    }

    if (params.wabcCode) {
        result.wabcCode = params.wabcCode;
    }

    if (Array.isArray(params.wabcCodes)) {
        result['wabcCode[in]'] = params.wabcCodes.join(',');
    }

    if (Array.isArray(params.advertiserId)) {
        result['advertiserId'] = params.advertiserId;
    }

    if (Array.isArray(params.advertiserIds)) {
        result['advertiserId[in]'] = params.advertiserIds.join(',');
    }

    if (Array.isArray(params.includes)) {
        result.include = params.includes.join(',');
    }

    if (params.name) {
        result[`name[like]`] = params.name;
    }

    if (Array.isArray(params.statuses)) {
        result.status = params.statuses.join(',');
    }

    if (params.userId) {
        result['users.id'] = params.userId;
    }

    if (params.inn) {
        result.inn = params.inn;
    }

    if (params.currentPage) {
        result.page = params.currentPage;
    }

    if (params.itemsPerPage) {
        result.limit = params.itemsPerPage;
    }

    if (params.commercialManagerId) {
        result.commercialManagerId = params.commercialManagerId;
    }

    return result;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getCompanyRelationships = (data: any): CompanyRelationships => {
    const relationships = data.relationships || [];
    const rules: RelationRule[] = [
        {
            name: 'addresses',
            rename: 'addressIds'
        },
        {
            name: 'advertisingCampaignsDrafts',
            rename: 'advertisingCampaignsDraftIds'
        },
        {
            name: 'bankAccounts',
            rename: 'bankAccountIds'
        },
        {
            name: 'contracts',
            rename: 'contractIds'
        },
        {
            name: 'documents',
            rename: 'documentIds'
        },
        {
            name: 'managers',
            rename: 'managerIds'
        },
        {
            name: 'users',
            rename: 'userIds'
        },
        {
            name: 'wabcCodes',
            rename: 'wabcCodeIds'
        },
        {
            name: 'form',
            rename: 'formId',
            type: 'single'
        },
        {
            name: 'commercialManager',
            rename: 'commercialManagerId',
            type: 'single'
        }
    ];

    return {
        id: data.id,
        ...getRelationships(relationships, rules)
    } as CompanyRelationships;
};

/**
 * Получить список всех компаний
 */
export async function getCompanies(
    params: CompanyQueryParams = {},
    abortController = new AbortController()
): Promise<{
    companies: Company[];
    companyRelationships: CompanyRelationships[];
    managers: CompanyManager[];
    addresses: CompanyAddress[];
    bankAccounts: CompanyBankAccount[];
    pagination: Pagination;
    forms: CompanyForm[];
    documents: CompanyDocument[];
    contracts: CompanyContract[];
    staffs: Staff[];
}> {
    const response = await client.get('/api/companies', {
        params: convertParams(params),
        signal: abortController.signal
    });
    const companies: Company[] = [];
    const companyRelationships: CompanyRelationships[] = [];
    const managers: CompanyManager[] = [];
    const addresses: CompanyAddress[] = [];
    const bankAccounts: CompanyBankAccount[] = [];
    const forms: CompanyForm[] = [];
    const documents: CompanyDocument[] = [];
    const contracts: CompanyContract[] = [];
    const staffs: Staff[] = [];

    const data = Array.isArray(response.data.data) ? response.data.data : [];
    for (const item of data) {
        companies.push(CompanyMapper.toClient(item));
        companyRelationships.push(getCompanyRelationships(item));
    }
    const included = Array.isArray(response.data.included) ? response.data.included : [];

    for (const item of included) {
        if (item.type === 'companies-managers') {
            managers.push(CompanyManagerMapper.toClient(item));
        } else if (item.type === 'companies-addresses') {
            addresses.push(CompanyAddressMapper.toClient(item));
        } else if (item.type === 'companies-bank-accounts') {
            bankAccounts.push(CompanyBankAccountMapper.toClient(item));
        } else if (item.type === 'companies-forms') {
            forms.push(CompanyFormMapper.toClient(item));
        } else if (item.type === 'companies-documents') {
            documents.push(CompanyDocumentMapper.toClient(item));
        } else if (item.type === 'companies-contracts') {
            contracts.push(CompanyContractMapper.toClient(item));
        } else if (item.type === 'tmp-staff') {
            staffs.push(JsonApiDefaultMapper.toClient(item));
        }
    }

    return {
        companies,
        companyRelationships,
        managers,
        addresses,
        bankAccounts,
        forms,
        documents,
        contracts,
        staffs,
        pagination: response.data.meta
    };
}

export async function getCompany(
    id: CompanyId,
    params?: Omit<CompanyQueryParams, 'id' | 'ids'>
): Promise<{
    company: Company;
    companyRelationship: CompanyRelationships;
    managers: CompanyManager[];
    addresses: CompanyAddress[];
    bankAccounts: CompanyBankAccount[];
    pagination: Pagination;
    form: CompanyForm;
    documents: CompanyDocument[];
    contracts: CompanyContract[];
    staffs: Staff[];
}> {
    const {
        companies: [company],
        forms: [form],
        companyRelationships: [companyRelationship],
        ...rest
    } = await getCompanies({ id, ...params });
    if (!company) {
        throw new NotFoundError(`Компания ${id} не найдена`);
    }
    return {
        company,
        form,
        companyRelationship,
        ...rest
    };
}

export async function updateCompany(company: Partial<Company> & { id: CompanyId }): Promise<void> {
    await client.patch(`/api/companies/${company.id}`, CompanyMapper.toServer(company));
}

export async function createCompany(
    company: Partial<Omit<Company, 'id' | 'status'>> & { commercialManagerCdColl?: WorkerId | null }
): Promise<Company> {
    // у некоторых компаний вместо null стоит 0, а наш бек пропускает только положительные числа. 0 не положительное число
    const systemId = company.systemId === 0 ? null : company.systemId;
    const response = await client.post(`/api/v1/companies`, {
        ...company,
        systemId
    });
    return response.data;
}

export const exportCompanies = async (params: {
    ids?: CompanyId[];
    status: 'active' | 'request' | 'invited' | 'blocked' | 'unconfirmed';
    inn?: string;
    columns: string[];
}) => {
    const serverPayload: {
        id?: string;
        status: string;
        inn?: string;
        columns?: string;
    } = {
        status: params.status
    };
    if (Array.isArray(params.ids)) {
        serverPayload.id = params.ids.join(',');
    }
    if (params.columns.length) {
        serverPayload.columns = params.columns.join(',');
    }
    if (params.inn) {
        serverPayload.inn = params.inn;
    }
    const response = await client({
        method: 'get',
        url: `/api/v1/companies/report`,
        responseType: 'blob',
        params: serverPayload
    });
    const fileName = `Список поставщиков ${dayjs().format('DD.MM.YYYY HH.mm')}.xlsx`;
    saveAs(response.data, fileName);
};
