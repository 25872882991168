import { isNumericString } from '@/shared/model/utils';
import type { MedicineId } from './Medicine';
import type { MedicationsParams } from '../api/MedicineService';

export const formatParams = (searchString: string) => {
    const params: Pick<MedicationsParams, 'medicineCodes' | 'search'> = {};
    const string = searchString.trim();

    const getIds = (data: string): MedicineId[] => {
        return [...new Set(data.trim().split(' ').filter(isNumericString).map(Number) as MedicineId[])];
    };

    const isLikeIdsList = (str: string) => {
        return /^[\d\s]+$/.test(str.trim());
    };

    switch (true) {
        case isLikeIdsList(string):
            params.medicineCodes = getIds(string);
            break;
        case string && isNumericString(string):
            params.medicineCodes = Number(string) as MedicineId;
            break;
        default:
            params.search = string;
    }

    return params;
};
