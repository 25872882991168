<template>
    <div class="chart">
        <v-chart :options="options" autoresize />
        <div v-if="isEmptyData" class="empty">
            <div class="empty__message">
                <span>Нет данных</span>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ECharts from 'vue-echarts';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/tooltip';
export default defineComponent({
    name: 'PieChart',

    components: {
        'v-chart': ECharts
    }
});
</script>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';

interface Props {
    /**
     * 1 параметр массива - отверстие в диаграмме
     * 2 второй - общий размер заполнения блока графика диаграммой в % от родителя
     * */
    radius?: (string | number)[];
    /**
     * Отображение подсказки tooltip
     * */
    showToolTip?: boolean;
    /**
     * Отображение текста по центру диаграммы
     * */
    showCenterLabel?: boolean;
    /**
     * Параметры данных для каждой части pie,
     * обязательно должно быть указано data, для отображения данных в pie
     * [{value: 1, name: 'Example'}, {value: 2, name: 'Example2'}]
     */
    data?: { value?: string | number; name: string | number }[];
    /**
     * Параметр загрузки
     */
    loading?: boolean;
    /**
     * Строковый шаблон для отображения данных в tooltip.
     * https://echarts.apache.org/en/option.html#tooltip.formatter
     */
    // eslint-disable-next-line @typescript-eslint/ban-types
    tooltipFormatter?: Function | string;
}

const props = withDefaults(defineProps<Props>(), {
    radius: () => ['40%', '70%'] as (string | number)[],
    showToolTip: true,
    showCenterLabel: true,
    loading: false,
    data: () => [],
    tooltipFormatter: undefined
});

const colors = ref<unknown[]>([]);

const isEmptyData = computed(() => {
    return !props.data.length && !props.loading;
});

const options = computed(() => {
    return {
        radius: props.radius,
        avoidLabelOverlap: true,
        labelLine: {
            show: false
        },
        color: colors.value,
        tooltip: {
            show: props.showToolTip,
            trigger: 'item',
            axisPointer: {
                lineStyle: {
                    width: 1,
                    color: '#C4CCD8'
                }
            },
            textStyle: {
                fontFamily: 'inherit',
                color: '#1A1E32',
                fontSize: 10,
                lineHeight: 20,
                lineOverflow: 'truncate',
                ellipsis: '...'
            },
            padding: [8, 16],
            backgroundColor: 'rgba(255,255,255,.8)',
            backdropFilter: 'blur(6px)',
            extraCssText: 'box-shadow: var(--shadow-tooltip)',
            className: 'echarts-tooltip',
            formatter: props.tooltipFormatter
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
            backgroundColor: 'transparent'
        },
        xAxis: {
            type: 'category',
            axisTick: false,
            axisLine: {
                lineStyle: {
                    color: '#A7AEB8',
                    fontFamily: 'inherit',
                    opacity: 0
                }
            }
        },
        yAxis: {
            type: 'value',
            axisTick: false,
            splitNumber: 10,
            nameGap: 50,
            boundaryGap: [0, '10%'],
            splitLine: {
                show: true,
                interval: 'auto',
                lineStyle: {
                    color: 'rgba(0,0,0,.1)',
                    type: 'dashed',
                    fontFamily: 'inherit'
                }
            },
            axisLine: {
                lineStyle: {
                    color: '#A7AEB8',
                    fontFamily: 'inherit',
                    opacity: 0
                }
            }
        },
        /**
         * Основные настройки Pie
         * */
        series: {
            animation: 'auto',
            animationType: 'expansion',
            animationDuration: 1000,
            animationTypeUpdate: 'transition',
            animationEasingUpdate: 'cubicInOut',
            animationDurationUpdate: 1000,
            animationEasing: 'cubicInOut',
            stateAnimation: {
                duration: 1000,
                easing: 'cubicOut'
            },
            name: '',
            type: 'pie',
            radius: props.radius,
            avoidLabelOverlap: false,
            label: {
                show: false,
                position: 'center'
            },
            emphasis: {
                label: {
                    show: props.showCenterLabel,
                    fontSize: '16',
                    fontWeight: 'bold'
                }
            },
            labelLine: {
                show: false
            },
            data: props.data
        }
    };
});

onMounted(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props.data.forEach((v: any) => {
        if (v?.color) {
            colors.value.push(v.color);
        }
    });
});
</script>

<style lang="scss" scoped>
.chart {
    position: relative;

    .echarts {
        position: relative;
        width: 100%;
        height: 40vh;
        min-height: 400px;

        text-align: left;
        font-size: 1.4rem;
    }

    .empty {
        z-index: 10;
        position: absolute;
        top: 4rem;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &__message {
            display: flex;
            align-items: flex-end;
        }

        &::before {
            content: '';
            position: absolute;
            z-index: 10;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: var(--white);
            background-image: linear-gradient(30deg, black 0, transparent 1px);
            background-size: 15px 20px;
            background-repeat: repeat;
            opacity: 0.6;
        }
    }
}
</style>
