<template>
    <PSelect
        v-model="value"
        search
        :clearable="clearable"
        :disabled="disabled"
        :label="label"
        :loading="isSearching"
        :multiple="multiple"
        :options="options"
        :placement="placement"
        :return-object="returnObject"
        :tags="tags"
        @open="prefetchData"
        @add-item="changeItem"
        @remove-item="changeItem"
        @update:search-input="searchKeywords"
    >
        <template #list-item="{ item }: { item: { raw: { name: string } } }">
            <div>{{ item.raw.name }}</div>
        </template>
        <template #afterList>
            <div v-if="!isLoadedAllData" ref="lastItemEl" class="p-[10px_20px] text-gray-500 mb-4">
                <div v-if="isLoadingItemData" class="loading-item">Загрузка...</div>
            </div>
        </template>
    </PSelect>
</template>

<script setup lang="ts" generic="T extends KeywordId | Keyword">
import { computed, onMounted, ref } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import { useIntersectionObserver } from '@vueuse/core';
import { uniq } from 'lodash';
import { CanceledError } from 'axios';
import { PSelect } from '@/shared/ui';
import { NetworkError } from '@/errors/NetworkError';
import { notifyError } from '@/shared/model/utils/showNotify';
import sentry from '@/shared/lib/sentry/sentry';
import { logger } from '@/shared/model/utils';
import { type Placement } from '@/shared/lib/popper';
import type { Pagination } from '@/shared/model/types/Pagination';
import type { KeywordsParams } from '../api/KeywordsService';
import { useKeywordsStore } from '../model/KeywordsStore';
import type { Keyword, KeywordId } from '../model/Keyword';

enum Status {
    DEFAULT = 'default',
    LOADING_ITEM = 'loading-item',
    LOADING_SEARCH = 'loading-search',
    LOADING_ALL = 'loading-all',
    LOADING_INIT = 'loading-init'
}

type KeywordModel = T | T[] | null;

interface Props {
    label?: string;
    disabled?: boolean;
    multiple?: boolean;
    clearable?: boolean;
    tags?: boolean;
    returnObject?: boolean;
    onlySearch?: boolean;
    prefetch?: boolean;
    placement?: Placement;
}

const props = withDefaults(defineProps<Props>(), {
    label: 'Ключевые слова',
    disabled: false,
    multiple: false,
    returnObject: false,
    filters: undefined,
    onlySearch: false,
    prefetch: false,
    placement: 'bottom-start'
});

const emit = defineEmits<{
    (e: 'update:modelValue', val: KeywordModel): void;
    (e: 'change', val: KeywordModel): void;
}>();

const value = defineModel<KeywordModel>('modelValue', { required: true });
const keywordsStore = useKeywordsStore();
const searchValue = ref('');
const options = ref<Keyword[]>([]);
const lastItemEl = ref<HTMLElement>();
const status = ref<Status>(Status.DEFAULT);
const currentPage = ref(0);
let abortController: AbortController;

const isLoadedAllData = computed(() => status.value === Status.LOADING_ALL);
const isLoadingItemData = computed(() => status.value === Status.LOADING_ITEM);
const isSearching = computed(() => status.value === Status.LOADING_SEARCH);

const checkAllDataLoaded = (pagination: Pagination) => {
    return pagination.totalItems <= pagination.currentPage * pagination.itemsPerPage;
};

const getKeywordFromValue = (inputValue: KeywordModel): KeywordId[] => {
    const formatValues = Array.isArray(inputValue) ? inputValue : [inputValue];
    return props.returnObject ? (formatValues as Keyword[]).map(m => m.id) : (formatValues as KeywordId[]);
};

const prefetchData = async () => {
    if (props.prefetch) {
        await searchKeywords('');
    }
};

const initialData = async (keywordIds: KeywordId[]) => {
    if (props.onlySearch) return;

    try {
        abortController?.abort();
        abortController = new AbortController();
        status.value = Status.LOADING_INIT;
        options.value = await keywordsStore.fetchKeywordsByIdCached(keywordIds, abortController);
    } catch (error) {
        if (error instanceof CanceledError) {
            return [];
        }

        let message = 'Произошла ошибка при инициализации данных поиска по ключевым словам';

        if (error instanceof NetworkError) {
            message += '. ' + error.message;
        }

        notifyError(message);
        sentry.captureException(error);
        logger.error(error);
    } finally {
        status.value = Status.DEFAULT;
    }
};

const searchKeywords = async (search: string) => {
    try {
        searchValue.value = search;
        abortController?.abort();
        abortController = new AbortController();

        status.value = Status.LOADING_SEARCH;
        currentPage.value = 1;

        const response = await keywordsStore.fetchKeywords(
            {
                search: searchValue.value,
                pagination: { currentPage: currentPage.value }
            },
            abortController
        );

        options.value = response.data;

        if (checkAllDataLoaded(response.pagination)) {
            status.value = Status.LOADING_ALL;
            return;
        }

        status.value = Status.DEFAULT;
    } catch (error) {
        if (error instanceof CanceledError) {
            return [];
        }

        let message = 'Произошла ошибка при поиске ключевых слов';

        if (error instanceof NetworkError) {
            message += '. ' + error.message;
        }

        notifyError(message);
        sentry.captureException(error);
        logger.error(error);
        status.value = Status.DEFAULT;
    }
};

const changeItem = () => {
    emit('change', value.value);
};

const getNextPageKeywords = async (params: KeywordsParams) => {
    try {
        status.value = Status.LOADING_ITEM;
        return await keywordsStore.fetchKeywords(params);
    } catch (error) {
        let message = 'Произошла ошибка при запросе списка данных товаров';

        if (error instanceof NetworkError) {
            message += '. ' + error.message;
        }

        notifyError(message);
        sentry.captureException(error);
        logger.error(error);
    } finally {
        status.value = Status.DEFAULT;
    }
};

useIntersectionObserver(lastItemEl, async ([{ isIntersecting }]) => {
    if (isLoadedAllData.value || isLoadingItemData.value) {
        return;
    }

    if (isIntersecting) {
        const payload = {
            search: searchValue.value,
            pagination: {
                currentPage: (currentPage.value += 1)
            }
        };

        const response = await getNextPageKeywords(payload);

        if (!response?.data) {
            return;
        }

        options.value = uniq([...options.value, ...response.data]);

        if (checkAllDataLoaded(response.pagination)) {
            status.value = Status.LOADING_ALL;
        }
    }
});

onMounted(async () => {
    const keywordIds = value.value && getKeywordFromValue(value.value);

    if (keywordIds?.length) {
        await initialData(keywordIds);
    }
});

onBeforeRouteLeave(() => {
    abortController?.abort();
});
</script>

<style lang="scss" scoped>
.loading-item {
    display: flex;

    &:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 2px solid var(--text-color-light);
        border-top-color: transparent;
        animation: rotate 0.7s linear infinite;
        margin-right: 1rem;
    }

    @keyframes rotate {
        to {
            transform: rotate(1turn);
        }
    }
}
</style>
