<template>
    <PAlert :type="statusType" :visible-icon="false" class="p-[4px_8px] text-[14px] font-[500] whitespace-nowrap">
        {{ statusMessage }}
    </PAlert>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { PAlert, type AlertType } from '@/shared/ui';
import { B2BAsyncReportStatus } from '../model/B2BAsyncReport';

const props = defineProps<{ status: B2BAsyncReportStatus }>();

const statusMessage = computed(() => {
    return {
        [B2BAsyncReportStatus.ACCEPTED]: 'Задача поставлена в очередь',
        [B2BAsyncReportStatus.COMPLETED]: 'Отчет сформирован',
        [B2BAsyncReportStatus.IN_PROGRESS]: 'Отчет формируется',
        [B2BAsyncReportStatus.FAILED]: 'Ошибка при формировании'
    }[props.status];
});

const statusType = computed(() => {
    return {
        [B2BAsyncReportStatus.ACCEPTED]: 'info',
        [B2BAsyncReportStatus.COMPLETED]: 'success',
        [B2BAsyncReportStatus.IN_PROGRESS]: 'warning',
        [B2BAsyncReportStatus.FAILED]: 'error'
    }[props.status] as AlertType;
});
</script>
