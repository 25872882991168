<template>
    <PSelect
        v-model="value"
        label="Тип размещения"
        :disabled="disabled"
        :clearable="clearable"
        :options="placementTypeOptions"
        data-testid="placement-type-select"
    />
</template>

<script lang="ts" setup>
import { PSelect } from '@/shared/ui';
import { PlacementType } from '../model/Placement';
import { getPlacementTypeName } from '../model/utils';

withDefaults(
    defineProps<{
        disabled?: boolean;
        clearable?: boolean;
    }>(),
    {
        clearable: true
    }
);

const value = defineModel<PlacementType | null>('modelValue', { required: true });

const placementTypeOptions = [PlacementType.CPM, PlacementType.BY_TIME, PlacementType.EXTERNAL].map(paramType => ({
    id: paramType,
    name: getPlacementTypeName(paramType)
}));
</script>
