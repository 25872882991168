<template>
    <PSelect
        v-model="value"
        label="Тип контента"
        :disabled="disabled"
        :clearable="clearable"
        :options="contentTypeOptions"
        data-testid="content-type-select"
    />
</template>

<script lang="ts" setup>
import { PSelect } from '@/shared/ui';
import { PlacementContentType } from '../model/Placement';
import { getPlacementContentTypeName } from '../model/utils';

withDefaults(
    defineProps<{
        disabled?: boolean;
        clearable?: boolean;
    }>(),
    {
        clearable: true
    }
);

const value = defineModel<PlacementContentType | null>('modelValue', { required: true });

const contentTypeOptions = [PlacementContentType.BANNER, PlacementContentType.HTML].map(paramType => ({
    id: paramType,
    name: getPlacementContentTypeName(paramType)
}));
</script>
