<template>
    <h2>Выход</h2>

    <p>
        Вы уверены что хотите выйти<br />
        из личного кабинета?
    </p>

    <div>
        <PButton full @click="logout"> Выйти </PButton>
    </div>
</template>

<script lang="ts">
import { PButton } from '@/shared/ui';
import { defineComponent } from 'vue';
import * as ProcessAuth from '@/processes/Auth';
export default defineComponent({
    name: 'LogoutModal',

    components: {
        PButton
    },

    methods: {
        logout() {
            ProcessAuth.logout();
            this.$router.push({ name: 'login' });
        }
    }
});
</script>
