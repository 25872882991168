import { isNumericString } from '@/shared/model/utils';
import type { CategoryParams } from '../api/ZdravcityCategoryService';

export const formatParams = (searchString: string) => {
    const params: Pick<CategoryParams, 'name' | 'codes'> = {};

    const string = searchString.trim();

    if (isNumericString(string)) {
        params.codes = string;
    } else {
        params.name = string;
    }

    return params;
};
