import { dayjs } from '@/utils/dayjs';

/**
 * Конвертация даты
 * @param data
 * @param format
 * @returns string
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertDate(data: any, format = 'DD.MM.YYYY') {
    return dayjs(data).isValid() ? dayjs(data).format(format) : '';
}

/**
 * Конвертация даты custom
 * @param {string | date} data - строка для парсинга даты (String or Date object)
 * @param {string} readFormat - формат входной строки
 * @param {string} format - формат выходной строки
 * @returns string | boolean
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertDateCustom(data: any, readFormat = 'DD.MM.YYYY', format = 'DD.MM.YYYY'): string {
    return dayjs(data, readFormat).isValid() ? dayjs(data, readFormat).format(format) : '';
}

/**
 * Конвертация даты начала c .000Z
 * @returns string || 0
 * @param data
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertDateToISO(data: any) {
    return dayjs(data).isValid() ? dayjs(data).format('YYYY-MM-DDT00:00:00') + '.000Z' : null;
}

/**
 * Получение объекта с датами прошлого месяца
 * @returns object
 */
export function lastMonthDate() {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
    lastDay.setHours(23, 59, 59);
    return {
        startDate: firstDay,
        stopDate: lastDay
    };
}
