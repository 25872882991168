<template>
    <div class="notification" :class="{ 'notification--collapsed': isCollapsed }" :style="widthSize">
        <div class="notification__icon" :class="{ 'notification__icon--error': error }" @click="toggleCollapse">
            <PIcon name="file-blank-outline" />
        </div>

        <div class="notification__content">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="notification__title" v-html="sanitizedTitle"></div>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="notification__text" v-html="sanitizedText"></div>
            <div v-if="!error" class="progress-bar">
                <div class="progress-bar-striped"></div>
            </div>
        </div>

        <div class="notification__action">
            <PButton v-if="error" icon="close" variant="text" @click="closeItem" />
            <PButton v-else icon="chevron-big-right" variant="text" @click="toggleCollapse" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'DownloadFileNotify'
});
</script>

<script lang="ts" setup>
import { PButton, PIcon } from '@/shared/ui';
import { computed, ref } from 'vue';
import DOMPurify from 'dompurify';

/**
 * Этот тип дубль DownloadNotifyProps, так потому, что импорт типов
 * для объявления props в setup компонентах не поддерживается.
 * SEE: https://github.com/vuejs/rfcs/blob/master/active-rfcs/0040-script-setup.md#type-only-propsemit-declarations
 * Типы дублируются, чтобы избежать циклических зависимостей
 */
interface Props {
    title?: string;
    text: string;
    width: string;
    error?: boolean;
}
const props = defineProps<Props>();

const sanitizedTitle = computed(() => {
    let title = props.title ?? 'Создание отчёта';
    if (props.error) {
        title = props.title ?? 'Не удалось сформировать документ';
    }
    return DOMPurify.sanitize(title);
});
const sanitizedText = computed(() => DOMPurify.sanitize(props.text));

const widthSize = computed<{ width: string }>(() => {
    return {
        width: props.width + 'px'
    };
});

const emit = defineEmits<{
    (e: 'close'): void;
}>();

const isCollapsed = ref(false);
const toggleCollapse = () => {
    isCollapsed.value = !isCollapsed.value;
};
const closeItem = () => {
    emit('close');
};
</script>

<style scoped lang="scss">
.notification {
    display: grid;
    grid-template-columns: 4.8rem 1fr 5rem;
    font-size: var(--text-size-14);
    color: var(--text-color);
    background-color: var(--white);
    box-shadow: var(--shadow-notification);
    border-radius: var(--border-radius-8) 0 0 var(--border-radius-8);
    transition: width 0.4s ease-in-out;

    &--collapsed {
        width: 50px !important;
    }

    &--error {
        width: 50px !important;
    }

    &__icon {
        --accent-color: var(--primary-light);

        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        color: var(--accent-color);
        border-right: 2px solid var(--accent-color);
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--accent-color);
            opacity: 0.05;
        }

        &--error {
            --accent-color: var(--danger);
        }
    }

    &__content {
        padding: 2rem;
        display: grid;
        grid-gap: 1rem;
        line-height: 1.4;
    }

    &__title {
        font-size: initial;
        font-weight: 700;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__text {
        font-size: initial;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__action {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 3rem;
    }
}

.progress-bar {
    white-space: nowrap;
    background-color: var(--primary-light);
    transition: width 0.6s ease;
    border-radius: 8px;
}

.progress-bar-striped {
    height: 10px;
    animation: 1s linear infinite progress-bar-stripes;
    background-image: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
    );
    background-size: 1rem 1rem;
}

@keyframes progress-bar-stripes {
    0% {
        background-position-x: 1rem;
    }
}
</style>
