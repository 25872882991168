<template>
    <slot name="header">
        <div class="space-y-5">
            <Icon name="error-outline" size="50" style="color: var(--warning)" />
            <h2>Сохранить изменения перед выходом?</h2>
        </div>
    </slot>

    <slot>
        <p>Если выйти без сохранения, изменения не сохранятся.</p>
    </slot>

    <slot name="footer">
        <div class="grid gap-5 grid-cols-2">
            <PButton variant="outline" @click="cancel"> Не сохранять </PButton>

            <PButton @click="confirmed"> Сохранить </PButton>
        </div>
    </slot>
</template>

<script lang="ts">
import { PButton, PIcon } from '@/shared/ui';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'PConfirm',

    components: {
        PButton,
        Icon: PIcon
    }
});
</script>

<script lang="ts" setup>
const emit = defineEmits<{
    (e: 'confirm'): void;
    (e: 'cancel'): void;
}>();

const confirmed = () => {
    emit('confirm');
};

const cancel = () => {
    emit('cancel');
};
</script>
