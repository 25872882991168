<template>
    <div
        :class="[
            'file-upload',
            {
                'file-upload--is-upload': isUploaded,
                'file-upload--is-loading': loading,
                'file-upload--drop-zone-active': isDropActive,
                'file-upload--disabled': disabled
            }
        ]"
        @drop="drop"
        @dragover="dragover"
        @dragleave="dragleave"
    >
        <label v-show="!errorMessage && !loading && !isUploaded" class="file-upload-wrapper">
            <input
                :id="id"
                ref="fileInputEl"
                type="file"
                class="input-file"
                :accept="accept"
                :disabled="disabled"
                @change="changeFiles"
            />
        </label>

        <div v-if="!errorMessage && !loading && !isUploaded" class="content">
            <slot>
                <div class="content__icon-wrapper">
                    <Icon class="content__icon" :name="nameIconUpload" size="32" />
                </div>

                <div class="content__info">
                    <h3><slot name="title"> Загрузите или перетащите файл на загрузку </slot></h3>
                    <p>
                        {{ notice }}
                    </p>
                </div>
            </slot>
        </div>

        <div v-show="loading" class="content" data-testid="view-loading">
            <div class="content__icon-wrapper">
                <Icon class="content__icon" name="cloud-up" size="32" />
            </div>
            <div class="content__info">
                <div class="progress-bar">
                    <div class="progress-bar-striped"></div>
                </div>
            </div>
        </div>

        <div v-show="isUploaded && !loading" class="content content--upload" data-testid="view-upload">
            <slot name="content-upload" :file="modelValue">
                <div class="content__icon-wrapper">
                    <Icon class="content__icon content__icon--upload" :name="iconExtension" size="32" />
                </div>

                <div class="content__info">
                    <slot name="file-name">
                        <FilePreview v-if="modelValue" :url="modelValue" />
                    </slot>
                </div>
            </slot>
            <PButton
                v-if="!readonly && !disabled"
                variant="text"
                icon="trash-full"
                data-testid="clear-state"
                @click="removeFile"
            >
                Удалить
            </PButton>
        </div>

        <div v-show="errorMessage && !loading" class="content" data-testid="view-error">
            <div class="content__icon-wrapper">
                <Icon class="content__icon content__icon--error" name="error-outline" size="32" />
            </div>

            <div class="content__info">
                <h3>Ошибка</h3>
                <p>{{ errorMessage }}</p>
            </div>
            <PButton variant="text" @click="resetStateFile"> Сбросить </PButton>
        </div>
    </div>
</template>

<script lang="ts" setup>
import Icon from '@/shared/ui/PIcon/PIcon.vue';
import PButton from '../../PButton/PButton.vue';
import FilePreview from '../../FilePreview/FilePreview.vue';
import { computed, ref } from 'vue';
import { bytesToSize } from '@/utils';
import type { IconName } from '@/shared/model/types/Icons';
import client from '@/shared/api/client';
import {
    validateExistExtension,
    validateExtension,
    validateSize,
    validationDimensions
} from '@/shared/ui/FileUpload/model/validator';
import { useDnDFiles } from '@/shared/ui/FileUpload/model/compositions/dndFiles';
import { getFileNameByUrl } from '@/shared/model/utils';
import type { PropExtensions } from '@/shared/ui/FileUpload/model/types';
import { isEmpty } from 'lodash';

interface Props {
    modelValue?: string | null;
    disabled?: boolean;
    readonly?: boolean;
    postAction?: string;
    accept?: string;
    types?: string;
    extensions?: PropExtensions;
    size?: number;
    width?: number;
    height?: number;
    /**
     * Способ валидации ширины и высоты изображения
     * equal - строго равное значение
     * within - в приделах заданного
     */
    validationDimensionsType?: 'equal' | 'within';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getUrl?: (data: any) => string;
    payload?: Record<string, unknown>;
    removeMethod?: () => Promise<unknown>;
    validate?: (file: File) => Promise<boolean>;
    uploadOnly?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    modelValue: '',
    disabled: false,
    readonly: false,
    postAction: '/api/files/upload?common=true',
    accept: '*',
    types: '',
    extensions: '',
    size: 3.2e7, // 32мб
    width: undefined,
    height: undefined,
    validationDimensionsType: 'equal',
    getUrl: undefined,
    payload: undefined,
    removeMethod: undefined,
    uploadOnly: false,
    validate: undefined
});

const emit = defineEmits<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: 'update:modelValue', val?: any): void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: 'remove', val?: any): void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: 'error', val?: any): void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: 'upload', val?: any): void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: 'change-file', val?: any): void;
}>();

const fileInputEl = ref<HTMLInputElement>();
const loading = ref(false);
const errorMessage = ref('');

const isUploaded = computed(() => {
    if (props.uploadOnly) {
        return;
    }
    return Boolean(props.modelValue);
});

const upload = async (file: File) => {
    try {
        loading.value = true;
        const formData = new FormData();
        formData.append('file', file);

        if (props.payload) {
            Object.entries(props.payload).forEach(([key, value]) => {
                formData.append(key, String(value));
            });
        }

        const { data } = await client.post<unknown>(props.postAction, formData);

        emit('upload', data);

        if (fileInputEl.value) {
            fileInputEl.value.value = '';
        }
        if (props.getUrl) {
            emit('update:modelValue', props.getUrl(data));
        }
    } catch (error) {
        emit('update:modelValue', null);
        emit('error', error);

        errorMessage.value = 'Загруженный файл не соответствует техническим требованиям';
    } finally {
        loading.value = false;
    }
};

const addFile = async (file: File) => {
    if (props.disabled) {
        return;
    }

    if (!validateExistExtension(file.name, props)) {
        errorMessage.value = `Файл должен быть с расширением ${props.extensions}`;
        return;
    }

    if (!(await validationDimensions(file, props))) {
        errorMessage.value = `Файл ${file.name} не соответствует размерам ${props.width}×${props.height}`;
        return;
    }

    if (!validateExtension(file.name, props)) {
        errorMessage.value = `Файл должен быть с расширением ${props.types || props.extensions}`;
        return;
    }

    if (!validateSize(file.size, props)) {
        errorMessage.value = `Файл превышает допустимый размер в ${bytesToSize(props.size)}`;
        return;
    }

    if (props.validate && !(await props.validate(file))) {
        // Input уже содержать meta-info о файле, поэтому сбрасываем эти данные принудительно
        resetStateFile();
        return;
    }

    emit('change-file', file);

    return upload(file);
};

const { isDropActive, drop, dragover, dragleave } = useDnDFiles({
    addFile
});

const changeFiles = (event: Event): void => {
    // @ts-expect-error FIXME
    for (const file of (event.target as HTMLInputElement).files) {
        addFile(file);
    }
};

const id = () => 'upload-id-' + Math.random().toString(36).substr(2, 9);

const notice = computed(() => {
    if (props.types) {
        return props.types;
    }

    let extensions: string;
    if (props.extensions === '*' || isEmpty(props.extensions)) {
        extensions = 'в любом формате';
    } else {
        extensions = `в формате ${props.extensions}`;
    }

    const size = `не более ${bytesToSize(props.size)}`;

    return `${extensions} ${size}`;
});

const fileName = computed<string>(() => {
    if (!props.modelValue || props.uploadOnly) {
        return '';
    }

    return getFileNameByUrl(props.modelValue);
});

const iconExtension = computed<IconName>(() => {
    const extension: string = fileName.value.split('.').at(-1)?.toLowerCase() ?? '';

    return ({
        pdf: 'file-pdf',
        xlsx: 'file-xls',
        xls: 'file-xls'
    }[extension] ?? 'file-blank-outline') as IconName;
});

const nameIconUpload = computed(() => (isDropActive.value ? 'file-blank-outline' : 'cloud-up'));
const resetStateFile = () => {
    errorMessage.value = '';
    emit('update:modelValue', null);
    emit('remove');
    if (fileInputEl.value) {
        fileInputEl.value.value = '';
    }
};
const removeFile = async () => {
    try {
        loading.value = true;
        if (props.removeMethod) {
            await props.removeMethod();
        }
    } finally {
        resetStateFile();
        loading.value = false;
    }
};
</script>

<style lang="scss" scoped>
.file-upload {
    position: relative;
    overflow: hidden;
    border-radius: var(--border-radius-8);
    border: 1px dashed var(--border-dark-1);
    user-select: none;
    min-height: 69px;

    &--is-upload {
        background-color: #f5f7fa;
        border-color: transparent;
    }

    &--disabled {
        opacity: 0.7;
        background-color: var(--disable-bg-color);
        pointer-events: none;
        cursor: not-allowed;

        .button {
            pointer-events: none;
            cursor: not-allowed;
        }
    }

    :deep(.file-uploads) {
        display: block;
        text-align: left;

        label {
            cursor: pointer;
        }
    }

    &--drop-zone-active:not(:root),
    &:focus-within {
        background-color: var(--disable-bg-color);
        .content__icon {
            transform: scale(1.1);
        }
    }

    .content {
        padding: 16px;
        display: grid;
        grid-template-columns: 45px auto max-content;
        grid-gap: 1rem;
        align-items: center;
        min-height: 69px;

        &__icon-wrapper {
            padding: 4px 2px;
            border: 1px solid var(--border-light);
            background-color: white;
            border-radius: 8px;
            display: flex;
            justify-content: center;
        }

        &__icon {
            color: var(--text-color-2);
            transition: transform 0.3s ease-in-out;
        }

        &__icon--error {
            color: var(--danger);
        }

        &__icon--upload {
            color: var(--primary-lighten);
        }

        &__info {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            h3 {
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 0.5rem;

                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                max-width: 100%;
            }

            p {
                font-size: 12px;
                color: var(--text-color-2);
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                max-width: 100%;
            }
        }
    }

    .progress-bar {
        white-space: nowrap;
        background-color: var(--primary-light);
        transition: width 0.6s ease;
        border-radius: 8px;
    }

    .progress-bar-striped {
        height: 10px;
        animation: 1s linear infinite progress-bar-stripes;
        background-image: linear-gradient(
            45deg,
            rgba(255, 255, 255, 0.15) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, 0.15) 50%,
            rgba(255, 255, 255, 0.15) 75%,
            transparent 75%,
            transparent
        );
        background-size: 1rem 1rem;
    }

    @keyframes progress-bar-stripes {
        0% {
            background-position-x: 1rem;
        }
    }

    &__error-message {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        background-color: var(--bg-color);

        .error-message {
            display: grid;
            grid-gap: 1rem;
            justify-items: center;
            color: var(--danger);
            font-weight: 700;
            max-width: 300px;
            text-align: center;
        }
    }
}

.file-upload--is-upload.file-upload--disabled {
    opacity: 1;
    pointer-events: auto;
    cursor: auto;
}

.file-upload-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    padding: 16px;
    display: grid;
    grid-template-columns: 45px auto max-content;
    grid-gap: 1rem;
    align-items: center;
    min-height: 69px;
}

.input-file {
    position: absolute;
    inset: 0;
    opacity: 0;
    cursor: pointer;

    &[disabled] {
        cursor: default;
    }
}
</style>
