import { createPopper } from '@popperjs/core';
import { onBeforeUnmount, ref } from 'vue';
import type { Instance, Options } from '@popperjs/core/lib/types';
export type { Placement } from '@popperjs/core';

export const usePopper = () => {
    const popperInstance = ref<Instance>();

    /**
     * Автоматическая установка позиционирования
     * */
    const setup = (reference: Element | undefined, popper: HTMLElement | undefined, options?: Partial<Options>) => {
        if (!popperInstance.value) {
            if (reference && popper) {
                const defaultOptions: Partial<Options> = {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 8]
                            }
                        }
                    ]
                };
                popperInstance.value = createPopper(reference, popper, { ...defaultOptions, ...options });
            }
        } else {
            popperInstance.value?.update();
        }
    };

    const destroy = () => {
        popperInstance.value?.destroy();
    };

    onBeforeUnmount(destroy);

    return {
        setup,
        destroy
    };
};
