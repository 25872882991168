<template>
    <div class="pagination">
        <button
            v-if="pageTotal > 1"
            :disabled="currentPage === 1 || disabled"
            data-testid="dt-pagination-prev"
            type="button"
            @click="previousPage"
        >
            Пред.
        </button>

        <button :disabled="disabled" :class="{ selected: currentPage === 1 }" type="button" @click="selectPage(1)">
            1
        </button>

        <span v-if="startDotted" data-testid="dt-pagination-start">...</span>

        <button
            v-for="indexPage in pagesPositionIndexes"
            :key="indexPage"
            :class="{ selected: indexPage === currentPage }"
            :data-testid="`dt-pagination-page-${indexPage}`"
            type="button"
            :disabled="disabled"
            @click="selectPage(indexPage)"
        >
            {{ indexPage }}
        </button>

        <span v-if="endDotted" data-testid="dt-pagination-end">...</span>

        <button
            v-if="pageTotal > 1"
            :class="{ selected: currentPage === pageTotal }"
            data-testid="dt-pagination-total"
            type="button"
            :disabled="disabled"
            @click="selectPage(pageTotal)"
        >
            {{ pageTotal }}
        </button>

        <button
            v-if="pageTotal > 1"
            :disabled="currentPage === pageTotal || disabled"
            data-testid="dt-pagination-next"
            type="button"
            @click="nextPage"
        >
            След.
        </button>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'PPagination'
});
</script>

<script lang="ts" setup>
import { computed } from 'vue';

interface Props {
    totalItems?: number;
    currentPage?: number;
    itemsPerPage?: number;
    disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    totalItems: 0,
    currentPage: 1,
    itemsPerPage: 10,
    disabled: false
});

const emit = defineEmits<{
    (e: 'update:currentPage', page: number): void;
}>();

const pageTotal = computed(() => {
    return Math.ceil(props.totalItems / props.itemsPerPage);
});

const pagesPosition = computed<number[]>(() => {
    if (pageTotal.value <= 5) {
        return Array.from({ length: pageTotal.value }).map((_, index) => index + 1);
    }

    if (props.currentPage <= 3) {
        return Array.from({ length: 5 }).map((_, index) => index + 1);
    }

    if (props.currentPage + 2 >= pageTotal.value) {
        return Array.from({ length: pageTotal.value })
            .map((_, index) => index + 1)
            .slice(pageTotal.value - 5, pageTotal.value);
    }

    return Array.from({ length: pageTotal.value })
        .map((_, index) => index + 1)
        .slice(props.currentPage - 4, props.currentPage + 3);
});

const pagesPositionIndexes = computed<number[]>(() => {
    return pagesPosition.value.slice(1, -1);
});

const startDotted = computed<boolean>(() => {
    return props.currentPage > 4;
});

const endDotted = computed<boolean>(() => {
    return props.currentPage + 4 <= pageTotal.value;
});

const selectPage = (page: number): void => {
    emit('update:currentPage', page);
};
const previousPage = (): void => {
    selectPage(props.currentPage - 1);
};

const nextPage = (): void => {
    selectPage(props.currentPage + 1);
};
</script>

<style scoped lang="scss">
.pagination {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    color: var(--text-color-light);

    button {
        border: none;
        background-color: transparent;
        color: var(--text-color-light);
        padding: 0.6rem 0.8rem;
        margin: 0 0.2rem;
        min-width: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 0.3s;
        text-indent: -0.1rem;
        border-radius: var(--border-radius-8);

        &.selected {
            background-color: var(--primary-light);
            color: var(--white) !important;
            text-align: center;
            pointer-events: none;
        }

        &:focus,
        &:hover {
            outline: none;
            color: var(--text-color);
        }

        &:disabled {
            pointer-events: none;
            cursor: not-allowed;
            opacity: 0.5;
        }
    }
}
</style>
