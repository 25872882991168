<template>
    <div class="alert" :class="type">
        <Icon v-if="visibleIcon" :name="iconName" class="icon" />
        <!-- @slot default slot -->
        <slot />
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import Icon from '@/shared/ui/PIcon/PIcon.vue';
import type { IconName } from '@/shared/model/types/Icons';

export type AlertType = 'default' | 'success' | 'info' | 'warning' | 'error';

interface Props {
    /**
     * Вариант отображения
     */
    type?: AlertType;

    /**
     *  Иконка для кнопки
     */
    icon?: IconName;

    /**
     *  Отображение иконки
     */
    visibleIcon?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    type: 'default',
    icon: undefined,
    visibleIcon: true
});

const iconName = computed(() => {
    if (props.icon) return props.icon;

    const iconMap: Record<AlertType, IconName> = {
        default: 'info-circle-outline',
        info: 'info-circle-outline',
        success: 'circle-check-outline',
        error: 'error-outline',
        warning: 'warning-outline'
    };

    return iconMap[(props.type as AlertType) || 'info'];
});
</script>

<style lang="scss" scoped>
.alert {
    isolation: isolate;
    position: relative;
    display: inline-flex;
    gap: 1rem;
    align-items: center;
    line-height: 1.4;
    color: var(--alert-accent-color);
    padding: 10px 20px;

    &::before {
        content: '';
        position: absolute;
        inset: 0;
        opacity: 0.1;
        z-index: -1;
        background-color: var(--alert-accent-color, var(--gray-light));
        border-radius: 8px;
    }

    .icon {
        margin-top: -2px;
        color: var(--alert-accent-color);
    }

    &.info {
        --alert-accent-color: var(--primary-light);
    }

    &.success {
        --alert-accent-color: var(--success);
    }

    &.warning {
        --alert-accent-color: var(--warning);
    }

    &.error {
        --alert-accent-color: var(--danger);
    }
}
</style>
