<template>
    <div v-loading="loading">
        <div class="data-table">
            <table :aria-label="ariaLabel">
                <thead>
                    <tr>
                        <th
                            v-for="(column, index) in visibleColumns"
                            :key="index"
                            :style="getColumnWidth(column)"
                            :data-testid="`th-${index}`"
                            :class="column.classNameHeaderCell"
                        >
                            <div
                                class="th-cell"
                                :class="{ 'th-cell--pointer': column.sortable }"
                                @click="orderBy(column)"
                            >
                                <slot :header-data="column" :name="column.slotHeader">
                                    {{ column.label }}
                                </slot>
                                <span v-if="column.sortable" class="th-cell__sortable">
                                    <Icon name="caret-up" size="16" :class="{ active: activeArrow(column, 'desc') }" />
                                    <Icon name="caret-down" size="16" :class="{ active: activeArrow(column, 'asc') }" />
                                </span>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="data.length && visibleColumns.length">
                        <template v-for="(item, index) in data" :key="`row-${index}`">
                            <tr
                                :class="rowClassNameMethod({ data: item, index })"
                                :data-testid="`tr-${index}`"
                                @click="rowClickMethod({ data: item, index })"
                            >
                                <!--  prettier-ignore -->
                                <td
                                    v-for="(column, columnIndex) in visibleColumns"
                                    :key="`${item[trackBy]}-${columnIndex}`"
                                    :class="[
                                        column.classNameCell && column.classNameCell(item[column.prop ?? '']),
                                        {
                                            'is-expanded': isVisibleExpandedRow(item[trackBy])
                                        }
                                    ]"
                                >
                                    <!--
                                        @slot Строка кастомизации ячейки
                                        @binding {object} data - данные текущей строки
                                        @binding {number} index - текущей строки
                                        @binding {function} onToggleExpandedRow - метод для переключения дополнительной подстроки
                                        @binding {boolean} isExpandedOpen - флаг, открыта ли подстрока или нет
                                    -->
                                    <span :class="{ 'empty-cell': item[column.prop ?? ''] === null || item[column.prop ?? ''] === undefined }">
                                        <slot
                                            :name="column.slotCell"
                                            :data="item"
                                            :index="index"
                                            :on-toggle-expanded-row="onToggleExpandedRow(item[trackBy])"
                                            :is-expanded-open="isVisibleExpandedRow(item[trackBy])"
                                        >
                                            <template v-if="column.format">
                                                {{ column.format(item[column.prop ?? '']) ?? '—' }}
                                            </template>
                                            <template v-else-if="column.render">
                                                {{ column.render(item) ?? '—' }}
                                            </template>
                                            <template v-else>
                                                {{ item[column.prop ?? ''] ?? '—' }}
                                            </template>
                                        </slot>
                                    </span>
                                </td>
                            </tr>
                            <tr v-if="isVisibleExpandedRow(item[trackBy])" :key="item[trackBy]">
                                <td :colspan="visibleColumns.length" class="expanded">
                                    <!-- @slot Слот для отображения раскрывающего блока -->
                                    <slot name="expanded" :data="item"></slot>
                                </td>
                            </tr>
                        </template>
                    </template>
                    <template v-else>
                        <tr>
                            <td :colspan="columns.length">
                                <!-- @slot Слот отображение при пустых данных -->
                                <slot name="empty-data">
                                    <div class="empty-message">
                                        <Icon name="file-blank-outline" size="20" />
                                        {{ loading ? 'Загрузка данных' : notFoundMessage }}
                                    </div>
                                </slot>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>

        <div v-if="pagination" class="pagination-wrapper">
            <!-- @slot Слот отображения пагинации -->
            <slot v-if="data.length" name="pagination">
                <PPagination
                    :current-page="pagination.currentPage"
                    :total-items="pagination.totalItems"
                    :items-per-page="pagination.itemsPerPage"
                    @update:current-page="changePage"
                />
            </slot>
            <!-- @slot Слот отображения выбора кол-во отображаемых пунктов -->
            <slot v-if="data.length" name="itemsPerPage">
                <PageSize
                    :page-sizes="pageSizes"
                    :total-items="pagination.totalItems"
                    :page-size="pagination.itemsPerPage"
                    @change="onChangePageSize"
                />
            </slot>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import PageSize from './PageSizes.vue';
import Icon from '@/shared/ui/PIcon/PIcon.vue';
import PPagination from './PPagination.vue';

export type ColumnType<T = string | undefined> = {
    label?: string;
    prop?: T;
    sortable?: boolean;
    slotHeader?: string;
    slotCell?: string;
    width?: string;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    format?: (value: any) => any;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    render?: (item: any) => any;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    classNameHeaderCell?: string | Record<string, string>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    classNameCell?: (value: any) => any;
    visible?: boolean;
};

export default defineComponent({
    name: 'DataTable',

    components: {
        PPagination,
        PageSize,
        Icon
    }
});
</script>

<script lang="ts" setup>
import { computed, ref } from 'vue';

type PaginationType = {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    sortField: string | null;
    sortOrder: SortedDirType | null;
};

type SortedDirType = 'asc' | 'desc';

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any[];
    columns: ColumnType[];
    loading?: boolean;
    trackBy?: string;
    notFoundMessage?: string;
    defaultColumnWidth?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pagination?: any; // FIXME: correct type PaginationType
    pageSizes?: number[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rowClickMethod?: (payload: { data: any; index: number }) => any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rowClassNameMethod?: (payload: { data: any; index: number }) => any;
    ariaLabel?: string;
}

const props = withDefaults(defineProps<Props>(), {
    loading: false,
    trackBy: 'id',
    notFoundMessage: 'Нет данных',
    defaultColumnWidth: '150px',
    pageSizes: () => [10, 25, 50, 75, 100],
    rowClickMethod: () => ({}),
    rowClassNameMethod: () => ({}),
    pagination: undefined,
    ariaLabel: 'Нет описания'
});

const emit = defineEmits<{
    (e: 'update', val: PaginationType): true;
}>();

const sortField = ref<string>();
const sortOrder = ref<SortedDirType>();
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const expandedRows = ref<any[]>([]);

const visibleColumns = computed<ColumnType[]>(() => {
    return props.columns.filter((v: ColumnType) => v.visible ?? true);
});

const activeArrow = (item: ColumnType, order: SortedDirType): boolean => {
    return sortField.value === item.prop && sortOrder.value === order;
};
const getColumnWidth = (item: ColumnType): { width: string } => {
    return { width: item.width || props.defaultColumnWidth };
};

const paramsUpdate = (updateParams: Partial<PaginationType>): void => {
    const params = {
        ...props.pagination,
        sortField,
        sortOrder,
        ...updateParams
    };

    /**
     * Событие при изменении значения сортировки/пагинации, возвращается объект всей пагинации и сортировки
     * @event update
     * @property {currentPage:number; itemsPerPage: number; totalItems: number;  sortField: string | null;  sortOrder: 'asc' | 'desc' | null }
     */
    emit('update', params);
    expandedRows.value = [];
};

const orderBy = (column: ColumnType) => {
    if (!column.sortable) return;

    sortField.value = column.prop;
    sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc';

    paramsUpdate({
        sortField: sortField.value,
        sortOrder: sortOrder.value
    });
};

const changePage = (page: number): void => {
    paramsUpdate({ currentPage: page });
};

const onChangePageSize = (pageSize: number) => {
    paramsUpdate({
        itemsPerPage: pageSize,
        currentPage: 1
    });
};

const isVisibleExpandedRow = (id: number | string) => {
    return expandedRows.value.includes(id);
};

const onToggleExpandedRow = (id: number | string) => {
    return () => {
        const index = expandedRows.value.indexOf(id);

        if (index > -1) {
            expandedRows.value.splice(index, 1);
        } else {
            expandedRows.value.push(id);
        }
    };
};
</script>

<style lang="scss" scoped>
@import '@/shared/styles/mixin.scss';

.data-table {
    overflow-x: auto;
    @include scrollbar-x();
    padding-bottom: 1rem;
}

.th-cell {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &--pointer {
        cursor: pointer;
    }

    &__sortable {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        svg:last-child {
            margin-left: -0.5rem;
        }

        svg.active {
            color: var(--primary-light);
        }
    }
}

table {
    width: 100%;
    border-collapse: collapse;

    thead {
        th {
            text-align: left;
            padding: 0;
            font-weight: normal;
            font-size: var(--text-size-12);
            color: var(--text-color-light);
            vertical-align: middle;

            > div {
                padding: 0 1.6rem 1rem;
            }

            &:first-child > div {
                padding-left: 3.2rem;
            }
            &:last-child > div {
                padding-right: 3.2rem;
            }
        }
    }

    tbody {
        box-shadow: var(--shadow-card);

        tr:first-child {
            td:first-child {
                border-top-left-radius: var(--border-radius-16);
            }
            td:last-child {
                border-top-right-radius: var(--border-radius-16);
            }
        }
        tr:last-child {
            td:first-child {
                border-bottom-left-radius: var(--border-radius-16);
            }
            td:last-child {
                border-bottom-right-radius: var(--border-radius-16);
            }
        }

        td {
            background-color: white;
            padding: 1.6rem;

            &:first-child {
                padding-left: 3.2rem;
            }
            &:last-child {
                padding-right: 3.2rem;
            }

            &.expanded {
                background-color: var(--bg-light);
            }

            .empty-cell:not(:root) {
                color: var(--disable);
            }
        }

        tr:not(:last-child) td {
            border-bottom: 1px solid var(--border-light);
        }

        tr:hover > td:not(.expanded) {
            background-color: var(--bg-color);
            color: var(--primary-light);
        }

        tr > td.is-expanded {
            background-color: var(--bg-color);
            color: var(--primary-light);
        }
    }

    .empty-message {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 500;
        color: var(--text-color-light);
    }
}

.pagination-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0 0;
    color: var(--text-color-light);
}
</style>
