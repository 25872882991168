import { defineStore } from 'pinia';
import { computed, reactive } from 'vue';
import type { Pagination } from '@/shared/model/types/Pagination';
import { getKeywords, type KeywordsParams } from '../api/KeywordsService';
import type { Keyword, KeywordId } from './Keyword';

const cache = new Map<string, { data: Keyword[]; pagination: Pagination }>();

export const useKeywordsStore = defineStore('keywordStore', () => {
    const keywordsMap = reactive(new Map<KeywordId, Keyword>());

    const keywords = computed(() => {
        return Array.from(keywordsMap.values());
    });

    const getKeywordById = (keywordId: KeywordId) => {
        return keywordsMap.get(keywordId);
    };

    const addKeywords = (keywords: Keyword[]) => {
        for (const keyword of keywords) {
            keywordsMap.set(keyword.id, keyword);
        }
    };

    const fetchKeywords = async (params: KeywordsParams, abortController?: AbortController) => {
        const cacheKey = JSON.stringify(params ?? 'default');
        const cachedData = cache.get(cacheKey);

        if (cachedData) {
            return cachedData;
        }

        const response = await getKeywords(params, abortController);
        addKeywords(response.data);
        cache.set(cacheKey, response);

        return response;
    };

    const fetchKeywordsByIdCached = async (keywordIds: KeywordId[], abortController?: AbortController) => {
        const oldIdsSet = new Set(keywordsMap.keys());
        const newIds = keywordIds.filter(keywordId => !oldIdsSet.has(keywordId));

        if (newIds.length) {
            await fetchKeywords(
                {
                    ids: newIds,
                    pagination: {
                        currentPage: 1,
                        itemsPerPage: newIds.length
                    }
                },
                abortController
            );
        }

        const keywordIdsSet = new Set(keywordIds);
        return keywords.value.filter((keyword: Keyword) => keywordIdsSet.has(keyword.id));
    };

    return {
        keywords,
        getKeywordById,
        fetchKeywords,
        fetchKeywordsByIdCached,
        addKeywords
    };
});
