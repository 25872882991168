<template>
    <div class="space-y-3">
        <ZdravcityCategoryFilter
            v-model="categoryIds"
            multiple
            clearable
            :disabled="loading || selectedLimited"
            prefetch
        />

        <div v-if="selectedCategories.length" class="view-scroller">
            <div class="view-scroller__title">Выбранные категории</div>
            <ul class="view-scroller__list">
                <li v-for="category in selectedCategories" :key="category?.id">
                    <PButton
                        variant="text"
                        color="navy-blue"
                        icon="close-small"
                        text-wrap
                        icon-color="text-gray-400"
                        @click="removeCategory(category!)"
                    >
                        <span class="pr-2">[{{ category?.code }}]</span>
                        {{ category?.name }}
                    </PButton>
                </li>
            </ul>
        </div>

        <FileUpload
            post-action="/api/v1/zdravcity-categories/parse-template"
            upload-only
            @upload="uploadCategories"
            @error="catchFileUploadError"
        />

        <div v-if="errors.length" class="errorList">
            <div class="errorList_header">
                <span class="errorList_title">
                    Ошибка обработки строк из файла: <strong>{{ errors.length }}</strong>
                </span>
            </div>
            <ul class="errorList_container">
                <li v-for="(error, i) in errors" :key="`error${i}`" class="errorList_item">
                    <span class="errorList_line">Строка {{ error.line }}:</span>
                    <span class="errorList_text">{{ error.message }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { FileUpload, PButton } from '@/shared/ui';
import {
    useZdravcityCategoryStore,
    ZdravcityCategoryFilter,
    type ZdravcityCategory,
    type ZdravcityCategoryId
} from '@/entities/ZdravcityCategory';
import { HumanFriendlyError } from '@/errors/HumanFriendlyError';
import { notifyError } from '@/shared/model/utils/showNotify';

const CATEGORIES_LIMIT = 50;

defineProps<{ loading: boolean }>();

const categoryStore = useZdravcityCategoryStore();
const categoryIds = defineModel<ZdravcityCategoryId[]>('modelValue', { required: true });

const selectedCategories = computed(() => {
    return categoryIds.value.map(categoryStore.getCategoryById);
});

const removeCategory = (category: ZdravcityCategory) => {
    categoryIds.value = categoryIds.value.filter(id => id !== category.id);
};

const selectedLimited = computed(() => {
    return selectedCategories.value.length === CATEGORIES_LIMIT;
});

interface ErrorItem {
    line: number;
    message: string;
}
const errors = ref<ErrorItem[]>([]);
const uploadCategories = (payload: { data: ZdravcityCategory[]; errors: ErrorItem[] }) => {
    const newCategories = payload.data.filter(category => {
        return !categoryIds.value.some(categoryId => categoryId === category.id);
    });
    const newCategoriesIds = Array.from(new Set(newCategories.map(c => c.id)));

    categoryStore.addCategories(newCategories);
    categoryIds.value = [...categoryIds.value, ...newCategoriesIds];

    errors.value = payload.errors;
};

const catchFileUploadError = (error: Error) => {
    let message = 'Возникла ошибка при обработке списка категорий';
    if (error instanceof HumanFriendlyError) {
        message = error.message;
    }
    notifyError({
        title: 'Ошибка',
        text: message
    });
};
</script>

<style lang="scss" scoped>
@import '@/shared/styles/mixin.scss';

.view-scroller {
    border: 1px solid var(--border-light);
    background: var(--bg-light);
    border-radius: 8px;
    padding: 10px;

    &__title {
        font-weight: 500;
        font-size: 12px;
        color: var(--text-color-light);
        margin-bottom: 6px;
    }

    &__list {
        max-height: 200px;
        overflow-y: auto;
        overscroll-behavior: contain;
        @include scrollbar-x(6px);
    }

    :deep(.button > span:not(:root)) {
        display: inline-block;
    }
}

.errorList {
    margin-top: 40px;
    padding: 12px 16px 14px;
    border: 1px solid #eb5757;
    background-color: #fcefef;
    color: #eb5757;
    border-radius: 8px;

    &_header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2.8rem;
    }

    &_container {
        list-style: none;
        max-height: 32rem;
        overflow: auto;

        @include scrollbar();
    }

    &_item {
        display: flex;
        line-height: 2rem;
        width: 100%;
    }

    &_line {
        display: flex;
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: bold;
        min-width: 94px;
        padding-right: 12px;
    }

    &_btn {
        cursor: pointer;
        margin-left: 19px;
    }
}
</style>
