<template>
    <PViewList
        v-model="value"
        v-loading="loading"
        is-list-view
        :options="options"
        label-key="fullName"
        value-key="medicineCode"
        :disabled="disabled"
    >
        <template #delete-title>Удалить все товары</template>

        <template #item-after="{ item }">
            <p class="text-red-500">{{ item.raw.errorMessage }}</p>
        </template>
    </PViewList>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { PViewList } from '@/shared/ui';
import { NetworkError } from '@/errors/NetworkError';
import { notifyError } from '@/shared/model/utils/showNotify';
import sentry from '@/shared/lib/sentry/sentry';
import { logger } from '@/shared/model/utils';
import type { Medicine, MedicineId } from '../model/Medicine';
import { useMedicineStore } from '../model/MedicineStore';

defineOptions({
    name: 'MedicineList'
});

const props = defineProps<{
    modelValue: MedicineId[];
    errors: { medicineCode: MedicineId; message: string }[];
    disabled?: boolean;
}>();

const medicineStore = useMedicineStore();
const value = defineModel<MedicineId[]>('modelValue', { required: true, local: true });
const medications = ref<Medicine[]>([]);
const loading = ref<boolean>(false);

const initialData = async () => {
    try {
        if (value.value.length === 0) {
            medications.value = [];
            return;
        }

        loading.value = true;
        medications.value = await medicineStore.fetchMedicationsByMedicineCodesCached(value.value);
    } catch (error) {
        let message = 'Произошла ошибка при инициализации данных поиска по товарам';

        if (error instanceof NetworkError) {
            message += '. ' + error.message;
        }

        notifyError(message);
        sentry.captureException(error);
        logger.error(error);
    } finally {
        loading.value = false;
    }
};

const options = computed(() => {
    if (!props.errors.length) {
        return medications.value;
    }

    return medications.value.map(medicine => {
        const errorMessage = props.errors.find(error => error.medicineCode === medicine.medicineCode)?.message;
        return {
            ...medicine,
            errorMessage
        };
    });
});

watch(value, initialData, { immediate: true, deep: true });
</script>
