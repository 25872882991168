<template>
    <div
        :class="[
            'banner-upload',
            {
                'banner-upload--is-upload': isUploaded && !errorMessage,
                'banner-upload--is-loading': loading,
                'banner-upload--drop-zone-active': isDropActive
            }
        ]"
        :style="getBannerSizeStyle"
        @drop="drop"
        @dragover="dragover"
        @dragleave="dragleave"
    >
        <label class="file-upload">
            <input
                :id="id"
                ref="fileInputEl"
                type="file"
                class="input-file"
                :accept="accept"
                multiple
                :disabled="disabled"
                data-testid="multi-file-input"
                @change="changeFiles"
            />
            <div v-if="!isUploaded">
                <slot></slot>
            </div>
        </label>

        <div v-if="bannerUrl" class="banner-upload__preview-image">
            <img
                :height="heightBanner"
                :width="widthBanner"
                :src="String(bannerUrl)"
                alt="Баннер"
                @error="errorLoadingPreview"
            />
            <PButton v-if="!disabled" color="info" style="font-size: 14px" @click="onRemove">
                <Icon name="trash-full" size="20" />
                Удалить изображение
            </PButton>
        </div>

        <div v-if="loading" class="banner-upload__loading">Загрузка</div>
        <div v-if="errorMessage || isErrorURL" class="banner-upload__error-message">
            <div class="error-message">
                <Icon name="info-circle-outline" size="20" />

                <p>{{ errorMessage || 'Не удалось загрузить файл по ссылке' }}</p>

                <PButton variant="text" style="font-size: 16px" @click="onClearError"> Очистить </PButton>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import Icon from '@/shared/ui/PIcon/PIcon.vue';
import PButton from '../../PButton/PButton.vue';
import { bytesToSize } from '@/utils';
import { notifyError } from '@/shared/model/utils/showNotify';
import client from '@/shared/api/client';
import { computed, ref } from 'vue';
import { useDnDFiles } from '@/shared/ui/FileUpload/model/compositions/dndFiles';
import type { PropExtensions } from '@/shared/ui/FileUpload/model/types';
import {
    validateExtension,
    validateSize as validateSizeHandler,
    validationDimensions
} from '@/shared/ui/FileUpload/model/validator';

interface Props {
    modelValue: string | null;
    id?: string;
    postAction?: string;
    accept?: string;
    extensions?: PropExtensions;
    types?: string;
    size?: number;
    heightBanner: string | number;
    widthBanner: string | number;
    validateSize?: boolean;
    disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    id: 'upload-id-' + Math.random().toString(36).substring(2, 9),
    postAction: '/api/files/upload?common=true&optimize=true&direct=true',
    accept: 'image/png,image/gif,image/jpeg',
    types: 'gif,jpg,jpeg,png',
    extensions: () => ['gif', 'jpg', 'jpeg', 'png'],
    size: 1e6, // 1мб
    validateSize: true
});

const emit = defineEmits<{
    (e: 'update:modelValue', payload: string | null): void;
}>();

const isErrorURL = ref(false);
const loading = ref(false);
const fileInputEl = ref<HTMLInputElement>();

const getBannerSizeStyle = computed(() => {
    return {
        '--width-banner': props.widthBanner + 'px',
        '--height-banner': props.heightBanner + 'px'
    };
});

const isUploaded = computed(() => Boolean(props.modelValue));
const bannerUrl = computed(() => props.modelValue);
const errorMessage = ref('');

const upload = async (file: File) => {
    try {
        loading.value = true;
        const formData = new FormData();
        formData.append('file', file);
        const response = await client.post(props.postAction, formData);
        if (fileInputEl.value) {
            fileInputEl.value.value = '';
        }
        const sharedUrl = response.data?.sharedUrl;
        if (sharedUrl) {
            emit('update:modelValue', sharedUrl);
        }
    } catch (error) {
        emit('update:modelValue', null);

        errorMessage.value = 'Загруженный баннер не соответствует техническим требованиям';
    } finally {
        loading.value = false;
    }
};

const addFile = async (file: File) => {
    if (props.disabled) {
        return;
    }

    if (
        props.validateSize &&
        !(await validationDimensions(file, {
            ...props,
            height: props.heightBanner,
            width: props.widthBanner,
            validationDimensionsType: 'equal'
        }))
    ) {
        notifyError(`Файл ${file.name} не соответствует размерам ${props.widthBanner}×${props.heightBanner}`);
        return;
    }

    if (!validateExtension(file.name, props)) {
        notifyError(`Неправильное разрешение. ${props.types || props.extensions}`);
        return;
    }

    if (!validateSizeHandler(file.size, props)) {
        notifyError(`Файл превышает допустимый размер в ${bytesToSize(props.size)}`);
        return;
    }

    return upload(file);
};

const { isDropActive, drop, dragover, dragleave } = useDnDFiles({
    addFile
});

const changeFiles = (event: Event): void => {
    // @ts-expect-error FIXME
    for (const file of (event.target as HTMLInputElement).files) {
        addFile(file);
    }
};

const onClearError = () => {
    errorMessage.value = '';
};

const errorLoadingPreview = () => {
    errorMessage.value = 'Не удалось загрузить файл по ссылке';
};

const onRemove = () => {
    emit('update:modelValue', null);
};
</script>

<style lang="scss" scoped>
.banner-upload {
    position: relative;
    max-width: min(var(--width-banner), 60vw);
    height: min(var(--height-banner), 30vh);
    overflow: hidden;

    &--is-upload {
        --height-banner: auto !important;
        border: 1px solid var(--border-light) !important;
    }

    &--is-loading {
        border: 1px solid var(--border-light) !important;
    }

    &--drop-zone-active:not(:root) {
        position: relative;
        border: 4px dashed var(--border);
        box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
    }

    &__preview-image {
        position: relative;
        z-index: 5;

        img {
            object-fit: contain;
            width: 100%;
            height: auto;
            display: block;
        }

        button {
            opacity: 0;
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            width: 100%;
            color: var(--white) !important;
            padding: 1rem;
            font-weight: 400;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--primary-lighten);
            transition: 0.3s;
            border: none;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            cursor: pointer;

            svg {
                margin-right: 0.4rem;
            }
        }

        &:hover button {
            opacity: 1;
        }
    }

    &__loading {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
        background-color: var(--bg-color);
        font-size: 0;

        &::before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 2px solid var(--text-color-light);
            border-top-color: transparent;
            animation: rotate 0.7s linear infinite;
            margin-right: 1rem;
        }

        @keyframes rotate {
            to {
                transform: rotate(1turn);
            }
        }
    }

    &__error-message {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        background-color: var(--bg-color);

        .error-message {
            display: grid;
            grid-gap: 1rem;
            justify-items: center;
            color: var(--danger);
            font-weight: 700;
            max-width: 300px;
            text-align: center;
        }
    }
}

.file-upload {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 2rem 1rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.input-file {
    position: absolute;
    inset: 0;
    opacity: 0;
    cursor: pointer;

    &[disabled] {
        cursor: default;
    }
}
</style>
