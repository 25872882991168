<template>
    <PModal v-model="isOpenModal" width="500" :closed-on-backdrop="false" :loading="loading">
        <h2>Статистика по ключевому слову</h2>

        <PForm ref="formEl" @submit.prevent="onSubmit">
            <div class="grid gap-10 grid-cols-2 mb-14">
                <PlacementFilter v-model="statisticKeyword.placementId" value-key="id" disabled class="col-span-full" />

                <InputText v-model="statisticKeyword.year" label="Указанный год" disabled class="col-span-full" />

                <FormItem
                    v-slot="{ field }"
                    v-model="statisticKeyword.name"
                    name="name"
                    rules="required|min:2|max:255"
                    class="col-span-full"
                >
                    <InputText v-bind="field" label="Ключевое слово" :disabled="!!statisticKeywordName" />
                </FormItem>

                <FormItem
                    v-slot="{ field }"
                    v-model.number="statisticKeyword.january"
                    name="january"
                    rules="required|integer|min_value:1"
                >
                    <InputText v-bind="field" label="Январь" />
                </FormItem>

                <FormItem
                    v-slot="{ field }"
                    v-model.number="statisticKeyword.february"
                    name="february"
                    rules="required|integer|min_value:1"
                >
                    <InputText v-bind="field" label="Февраль" />
                </FormItem>

                <FormItem
                    v-slot="{ field }"
                    v-model.number="statisticKeyword.march"
                    name="march"
                    rules="required|integer|min_value:1"
                >
                    <InputText v-bind="field" label="Март" />
                </FormItem>

                <FormItem
                    v-slot="{ field }"
                    v-model.number="statisticKeyword.april"
                    name="april"
                    rules="required|integer|min_value:1"
                >
                    <InputText v-bind="field" label="Апрель" />
                </FormItem>

                <FormItem
                    v-slot="{ field }"
                    v-model.number="statisticKeyword.may"
                    name="may"
                    rules="required|integer|min_value:1"
                >
                    <InputText v-bind="field" label="Май" />
                </FormItem>

                <FormItem
                    v-slot="{ field }"
                    v-model.number="statisticKeyword.june"
                    name="june"
                    rules="required|integer|min_value:1"
                >
                    <InputText v-bind="field" label="Июнь" />
                </FormItem>

                <FormItem
                    v-slot="{ field }"
                    v-model.number="statisticKeyword.july"
                    name="july"
                    rules="required|integer|min_value:1"
                >
                    <InputText v-bind="field" label="Июль" />
                </FormItem>

                <FormItem
                    v-slot="{ field }"
                    v-model.number="statisticKeyword.august"
                    name="august"
                    rules="required|integer|min_value:1"
                >
                    <InputText v-bind="field" label="Август" />
                </FormItem>

                <FormItem
                    v-slot="{ field }"
                    v-model.number="statisticKeyword.september"
                    name="september"
                    rules="required|integer|min_value:1"
                >
                    <InputText v-bind="field" label="Сентябрь" />
                </FormItem>

                <FormItem
                    v-slot="{ field }"
                    v-model.number="statisticKeyword.october"
                    name="october"
                    rules="required|integer|min_value:1"
                >
                    <InputText v-bind="field" label="Октябрь" />
                </FormItem>

                <FormItem
                    v-slot="{ field }"
                    v-model.number="statisticKeyword.november"
                    name="november"
                    rules="required|integer|min_value:1"
                >
                    <InputText v-bind="field" label="Ноябрь" />
                </FormItem>

                <FormItem
                    v-slot="{ field }"
                    v-model.number="statisticKeyword.december"
                    name="december"
                    rules="required|integer|min_value:1"
                >
                    <InputText v-bind="field" label="Декабрь" />
                </FormItem>
            </div>

            <div class="grid gap-8 grid-cols-2">
                <PButton variant="outline" :loading="loading" @click="closeModal"> Отменить </PButton>
                <template v-if="isEditForm">
                    <PButton type="submit" :loading="loading" data-testid="update-keywords-statistics-button">
                        Обновить
                    </PButton>
                </template>
                <template v-else>
                    <PButton
                        type="submit"
                        color="success"
                        :loading="loading"
                        data-testid="add-keywords-statistics-button"
                    >
                        Добавить
                    </PButton>
                </template>
            </div>
        </PForm>
    </PModal>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';
import { FormItem, InputText, PButton, PForm, PModal } from '@/shared/ui';
import { PlacementFilter, type PlacementTypes, usePlacementStore } from '@/entities/Placement';
import { HumanFriendlyError } from '@/errors/HumanFriendlyError';
import { NetworkError } from '@/errors/NetworkError';
import { notifyError } from '@/shared/model/utils/showNotify';
import sentry from '@/shared/lib/sentry/sentry';
import { logger } from '@/shared/model/utils';
import type { StatisticsKeyword } from '../model/StatisticsKeyword';
import * as StatisticsKeywordsService from '../api/StatisticsKeywordsService';
import { STATISTICS_KEYWORD_DEFAULT } from '../model/constants';

interface StatisticKeywordForm extends Omit<StatisticsKeyword, 'id'> {
    id?: number;
}

const props = defineProps<{
    isOpen: boolean;
    year: string;
    placementId: PlacementTypes.PlacementId;
    statisticsKeywordId?: number | null;
    statisticKeywordName?: string;
}>();

const emit = defineEmits(['closeModal', 'afterSubmit']);

function getInitialStatisticKeyword(): StatisticKeywordForm {
    return cloneDeep({
        ...STATISTICS_KEYWORD_DEFAULT,
        year: Number(props.year),
        name: props.statisticKeywordName ?? '',
        placementId: props.placementId
    });
}

const loading = ref(false);
const formEl = ref<typeof PForm>();
const placementStore = usePlacementStore();
const isEditForm = computed(() => props.statisticsKeywordId !== null);
const isOpenModal = defineModel<boolean>('isOpen', { required: true });
const statisticKeyword = ref<StatisticsKeyword | StatisticKeywordForm>(getInitialStatisticKeyword());

watch(isOpenModal, async isOpen => {
    if (!isOpen) return;

    if (isEditForm.value && props.statisticsKeywordId) {
        await fetchStatisticKeywordById(props.statisticsKeywordId);
    } else {
        statisticKeyword.value = getInitialStatisticKeyword();
    }
});

const closeModal = () => emit('closeModal');

const onSubmit = async () => {
    try {
        const isValid = await formEl.value?.validate();

        if (!isValid) return;

        loading.value = true;
        const payload = omit(statisticKeyword.value, 'id');

        if (isEditForm.value && props.statisticsKeywordId) {
            await StatisticsKeywordsService.updateStatisticsKeywordById(props.statisticsKeywordId, payload);
        } else {
            await StatisticsKeywordsService.createStatisticsKeyword(payload);
        }

        closeModal();
        emit('afterSubmit');
    } catch (error) {
        let message = 'Не удалось сформировать данные по данному ключевому слову';

        if (error instanceof HumanFriendlyError) {
            message += '. ' + error.message;

            formEl.value?.setErrors({
                name: 'Данное ключевое слово уже существует'
            });
        }

        notifyError(message);
        sentry.captureException(error);
        logger.error(error);
    } finally {
        loading.value = false;
    }
};

const fetchStatisticKeywordById = async (statisticKeywordId: number) => {
    try {
        loading.value = true;
        statisticKeyword.value = await StatisticsKeywordsService.getStatisticsKeywordById(statisticKeywordId);
    } catch (error) {
        let message = 'Не удалось получить данные по ключевому слову';

        if (error instanceof NetworkError) {
            message += '. ' + error.message;
        }

        notifyError(message);
        sentry.captureException(error);
        logger.error(error);
    } finally {
        loading.value = false;
    }
};

onMounted(placementStore.fetchPlacements);
</script>
