<template>
    <div class="notification" :style="widthSize" :class="type">
        <div class="notification__icon">
            <PIcon :name="iconName" />
        </div>

        <div class="notification__content">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-if="sanitizedTitle" class="notification__title" v-html="sanitizedTitle"></div>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="notification__text" v-html="sanitizedText"></div>
        </div>

        <div class="notification__action">
            <PButton v-if="closed" icon="close" variant="text" @click="closeItem" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'DefaultNotify'
});
</script>

<script lang="ts" setup>
import { PButton, PIcon } from '@/shared/ui';
import type { IconName } from '@/shared/model/types/Icons';
import type { NotifyType } from '../../model/NotifyTypes';
import { computed } from 'vue';
import DOMPurify from 'dompurify';

/**
 * Этот тип дубль DefaultNotifyProps, так потому, что импорт типов
 * для объявления props в setup компонентах не поддерживается.
 * SEE: https://github.com/vuejs/rfcs/blob/master/active-rfcs/0040-script-setup.md#type-only-propsemit-declarations
 * Типы дублируются, чтобы избежать циклических зависимостей
 */
interface Props {
    title?: string;
    text: string;
    width?: string | number;
    closed?: boolean;
    type?: NotifyType;
}
const props = withDefaults(defineProps<Props>(), {
    title: undefined,
    width: 600,
    type: 'info',
    closed: true
});

const sanitizedTitle = computed(() => props.title && DOMPurify.sanitize(props.title));
const sanitizedText = computed(() => DOMPurify.sanitize(props.text));

const emit = defineEmits<{
    (e: 'close'): void;
}>();

const closeItem = () => {
    emit('close');
};

const widthSize = computed<{ width: string }>(() => {
    return {
        width: props.width + 'px'
    };
});

const iconName = computed<IconName>(() => {
    const type = props.type || 'info';
    const icons: Record<typeof type, IconName | ''> = {
        info: 'info-circle-outline',
        success: 'info-circle-outline',
        error: 'warning-outline',
        warning: 'warning-outline',
        download: '',
        invite: ''
    };
    return icons[type] || 'info';
});
</script>

<style scoped lang="scss">
@import '@/shared/styles/notification';
</style>
