import GTM from './modules/GoogleTagManager';
import Amplitude from './modules/Amplitude';
import Metrika from './modules/Metrika';
import { snakeCase } from 'lodash';
import type { EventBase } from './types';
import type { Router } from 'vue-router';
import { logger } from '@/shared/model/utils';
import { ANALYTICS_DEBUG } from '@/shared/config/env';

class Analytics {
    static installed = false;
    private static userId: number | null;

    private constructor() {
        if (Analytics.installed) return;
        Analytics.installed = true;

        Amplitude.init();
        Metrika.init();
    }

    static init(): void {
        new Analytics();
    }

    private static pageData: Record<string, unknown> = {};
    private static userData: Record<string, unknown> = {};

    static setPageData(payload: Record<string, unknown>): void {
        for (const key in payload) {
            Analytics.pageData[snakeCase(key)] = payload[key];
        }
    }

    static setUserData(payload: Record<string, unknown>): void {
        for (const key in payload) {
            Analytics.userData[snakeCase(key)] = payload[key];
        }
    }

    static clearPageData(): void {
        Analytics.pageData = {};
    }

    static clearUserData(): void {
        Analytics.userData = {};
    }

    static get commonData(): Record<string, unknown> {
        return {
            ...(Analytics.userId ? { user_id: String(Analytics.userId) } : null),
            ...Analytics.pageData,
            ...Analytics.userData
        };
    }

    static send(event: EventBase): void {
        const _event: EventBase = {
            ...this.commonData,
            ...event
        };

        if (ANALYTICS_DEBUG) {
            logger.info('Analytics Event', event);
        }

        GTM.send(_event as EventBase);
        Metrika.send(_event as EventBase);

        const { action: type, ...properties } = _event;
        Amplitude.logEvent({ type, properties });
    }

    static setUserId(id: number | null): void {
        const _id = id === null ? '' : String(id);
        Analytics.userId = id;

        GTM.setUserId(_id);
        Metrika.setUserId(_id);
        Amplitude.setUserId(_id);
    }

    static pageView(router: Router): void {
        GTM.pageView(router, this.commonData);
        /**
         * Сейчас метрика считает это автоматически, перед тем как включить тут,
         * нужно отключить отслеживание страниц в инициализации счётчика я.метрики
         */
        // Metrika.pageView();
        Amplitude.pageView(router, this.commonData);
    }

    static logout() {
        Amplitude.logout();
        Metrika.logout();
        Analytics.clearUserData();
        Analytics.setUserId(null);
    }
}

export default Analytics;
