<template>
    <div class="table-component">
        <div class="table-container">
            <div ref="parentRef" class="table-wrapper">
                <div :style="{ height: `${totalSize}px` }">
                    <table v-if="isVisibleColumns">
                        <thead>
                            <tr v-for="headerGroup in table.getHeaderGroups()" :key="headerGroup.id">
                                <th
                                    v-for="header in headerGroup.headers"
                                    :key="header.id"
                                    :style="{
                                        '--table-col-width': `${header.getSize()}px`,
                                        ...getPinningColumn(header.column)
                                    }"
                                    :class="header.column.columnDef.meta?.headerClassName"
                                >
                                    <div
                                        v-if="!header.isPlaceholder"
                                        class="header-cell"
                                        @click="isSortableColumn(header.column) && changeSortColumn(header)"
                                    >
                                        <FlexRender
                                            :render="header.column.columnDef.header"
                                            :props="header.getContext()"
                                        />
                                        <div v-if="isSortableColumn(header.column)" class="sortable">
                                            <PIcon
                                                name="caret-up"
                                                size="16"
                                                :class="{ active: activeSortColumn(header.id, 'desc') }"
                                            />
                                            <PIcon
                                                name="caret-down"
                                                size="16"
                                                :class="{ active: activeSortColumn(header.id, 'asc') }"
                                            />
                                        </div>
                                    </div>

                                    <div
                                        v-if="header.column.columnDef.enableResizing"
                                        class="resizer"
                                        :class="{ 'is-resizing': header.column.getIsResizing() }"
                                        @mousedown="header.getResizeHandler()?.($event)"
                                        @touchstart="header.getResizeHandler()?.($event)"
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody :style="`--table-body-height: ${rowVirtualizer.getTotalSize()}px`">
                            <tr
                                v-for="virtualRow in virtualRows"
                                :key="virtualRow.index"
                                :style="{ '--table-row-translate-y': `${virtualRow.start}px` }"
                                :class="{ 'is-row-clicked': !!rowClick }"
                                @click="rowClick?.(rows[virtualRow.index].original)"
                            >
                                <td
                                    v-for="cell in getVisibleCells(virtualRow.index)"
                                    :key="cell.id"
                                    :style="{
                                        '--table-col-width': `${cell.column.getSize()}px`,
                                        ...getPinningColumn(cell.column)
                                    }"
                                >
                                    <slot :name="cell.column.id" :data="cell.row.original" :index="cell.row.index">
                                        <template v-if="cell.getValue()">
                                            <div class="line-clamp-1 break-all">
                                                <FlexRender
                                                    :render="cell.column.columnDef.cell"
                                                    :props="cell.getContext()"
                                                />
                                            </div>
                                        </template>
                                        <template v-else>
                                            <span class="empty-cell"> — </span>
                                        </template>
                                    </slot>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-else-if="!loading" class="cover-fill">
                        <PIcon name="file-blank-outline" />
                        Нет данных
                    </div>
                </div>
            </div>
            <div v-if="loading" class="cover-fill">
                <div class="loader">Загрузка данных...</div>
            </div>
        </div>

        <div v-if="pagination && pagination?.totalItems > 0" class="table-pagination">
            <PPagination
                :current-page="pagination.currentPage"
                :total-items="pagination.totalItems"
                :items-per-page="pagination.itemsPerPage"
                :disabled="loading"
                @update:current-page="changePage"
            />

            <PPageSizes
                :page-sizes="pageSizes"
                :total-items="pagination.totalItems"
                :page-size="pagination.itemsPerPage"
                :disabled="loading"
                @change="onChangePageSize"
            />
        </div>
    </div>
</template>

<script setup lang="ts" generic="T">
import { computed, type CSSProperties, ref } from 'vue';
import {
    type Column,
    type ColumnDef,
    type ColumnPinningState,
    type ColumnSizingState,
    FlexRender,
    getCoreRowModel,
    getSortedRowModel,
    type Header,
    useVueTable,
    type VisibilityState
} from '@tanstack/vue-table';
import { PIcon } from '@/shared/ui';
import { useVirtualizer } from '@tanstack/vue-virtual';
import type { Pagination } from '@/shared/model/types/Pagination';
import PPagination from './PPagination.vue';
import PPageSizes from './PageSizes.vue';

export interface TanstackTableState<V> {
    columnVisibility?: Partial<Record<keyof V, boolean>> & VisibilityState;
    columnSizing?: Partial<Record<keyof V, number>> & ColumnSizingState;
    columnPinning?: Partial<Record<'left' | 'right', (keyof V | string)[]>> & ColumnPinningState;
}

export type TanstackTableColumn<V> = ColumnDef<V> & { accessorKey?: keyof V | string };

type ColumnSizing<V> = NonNullable<TanstackTableState<V>['columnSizing']>;
type ColumnVisibility<V> = NonNullable<TanstackTableState<V>['columnVisibility']>;

type SortedDirType = 'asc' | 'desc';

interface PaginationType extends Pagination {
    sortField?: string | null;
    sortOrder?: SortedDirType | null;
}

interface Props {
    data: T[];
    columns: TanstackTableColumn<T>[];
    pageSizes?: number[];
    pagination?: PaginationType | Pagination;
    height?: number | `${number}${'px' | '%' | 'vh'}`;
    loading?: boolean;
    rowHeight?: number;
    rowClick?: (row: T) => void;
}

defineSlots<{
    [K in string]?: (scope: { data: T; index: number }) => void;
}>();

const props = withDefaults(defineProps<Props>(), {
    height: 460,
    rowHeight: 60,
    loading: false,
    pagination: undefined,
    pageSizes: () => [10, 25, 50],
    rowClick: undefined
});

const emit = defineEmits<{
    (e: 'update-pagination', pagination: Pagination): void;
}>();

const parentRef = ref<HTMLElement | null>(null);
const tableState = defineModel<TanstackTableState<T>>('state');

const table = useVueTable({
    get data() {
        return props.data;
    },
    get columns() {
        return props.columns;
    },
    state: tableState.value,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnVisibilityChange: updateMethod => {
        if (typeof updateMethod === 'function' && tableState.value?.columnVisibility) {
            tableState.value.columnVisibility = updateMethod(table.getState().columnVisibility) as ColumnVisibility<T>;
        }
    },
    onColumnSizingChange: updateMethod => {
        if (typeof updateMethod === 'function' && tableState.value?.columnSizing) {
            tableState.value.columnSizing = updateMethod(table.getState().columnSizing) as ColumnSizing<T>;
        }
    }
});

const rows = computed(() => {
    return table.getRowModel().rows;
});

const getVisibleCells = (virtualRowIndex: number) => {
    return rows.value[virtualRowIndex].getAllCells().filter(cell => cell.column.getIsVisible());
};

const isVisibleColumns = computed(() => {
    return (
        props.data.length > 0 &&
        rows.value
            .map(row => row.getAllCells())
            .flat()
            .filter(cell => cell.column.getCanHide())
            .some(cell => cell.column.getIsVisible())
    );
});

const rowVirtualizerOptions = computed(() => {
    return {
        count: rows.value.length,
        getScrollElement: () => parentRef.value as HTMLElement,
        estimateSize: () => props.rowHeight,
        overscan: 5
    };
});

const rowVirtualizer = useVirtualizer(rowVirtualizerOptions);
const virtualRows = computed(() => rowVirtualizer.value.getVirtualItems());
const totalSize = computed(() => (isVisibleColumns.value ? rowVirtualizer.value.getTotalSize() : 0));

const isSortableColumn = (column: Column<T>) => {
    return column.columnDef.enableSorting;
};

const paramsUpdate = (updatePagination: Partial<Pagination | PaginationType>): void => {
    (parentRef.value as HTMLElement).scrollTop = 0;
    emit('update-pagination', {
        ...props.pagination,
        ...updatePagination
    } as Pagination);
};

const changePage = (page: number): void => {
    paramsUpdate({ currentPage: page });
};

const changeSortColumn = (header: Header<T, unknown>): void => {
    if (!(props.pagination && 'sortField' in props.pagination)) return;

    if (props.pagination.sortField !== header.id) {
        paramsUpdate({
            sortField: header.id,
            sortOrder: 'asc'
        });
    } else {
        paramsUpdate({
            sortOrder: props.pagination.sortOrder === 'desc' ? 'asc' : 'desc'
        });
    }
};

const activeSortColumn = (columnName: string, sortedDirType: SortedDirType) => {
    if (!(props.pagination && 'sortField' in props.pagination)) return;
    return props.pagination.sortField === columnName && props.pagination.sortOrder === sortedDirType;
};

const onChangePageSize = (pageSize: number) => {
    paramsUpdate({
        itemsPerPage: pageSize,
        currentPage: 1
    });
};

const getPinningColumn = (column: Column<T>): CSSProperties => {
    const isPinned = column.columnDef.enablePinning && column.getIsPinned();
    const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left');
    const isFirstRightPinnedColumn = isPinned === 'right' && column.getIsFirstColumn('right');

    return {
        boxShadow: isLastLeftPinnedColumn
            ? '-2px 0 2px -2px var(--border-light) inset'
            : isFirstRightPinnedColumn
            ? '2px 0 2px -2px var(--border-light) inset'
            : undefined,
        left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
        right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
        opacity: isPinned ? 0.95 : 1,
        position: isPinned ? 'sticky' : 'relative',
        zIndex: isPinned ? 1 : 0
    };
};

const computedHeight = computed(() => {
    if (typeof props.height === 'number') {
        return `${props.height}px`;
    }

    return props.height;
});
</script>

<style lang="scss" scoped>
@import '@/shared/styles/mixin.scss';

.table-container {
    position: relative;
}

.table-wrapper {
    position: relative;
    height: v-bind(computedHeight);
    border-radius: var(--border-radius-8);
    box-shadow: 0 0 5px rgba(9, 29, 52, 0.03);
    overflow: auto;
    scroll-behavior: smooth;
    @include scrollbar();
}

.cover-fill {
    position: absolute;
    inset: 0;
    display: flex;
    gap: 2px;
    align-items: center;
    justify-content: center;
    font-size: var(--text-size-14);
    color: var(--text-color-light);
    background-color: rgba(255, 255, 255, 0.8);
    font-weight: 500;

    .loader {
        overflow: clip;
        pointer-events: none;
        user-select: none;

        display: flex;
        align-items: center;
        gap: 1rem;

        &::before {
            content: url('@/assets/loader.svg');
            width: 30px;
            height: 30px;
        }
    }
}

.table-pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-top: 1rem;

    :deep(*:not(.control__placeholder)) {
        font-size: 1.5rem;
    }
}

table {
    --table-body-height: 100px;
    --table-col-width: 150px;
    --table-row-translate-y: 0;
    --table-row-height: calc(v-bind(rowHeight) * 1px);

    display: grid;
    width: 100%;

    thead {
        display: grid;
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: white;

        tr {
            display: flex;
            width: 100%;
            box-shadow: 0 0 5px rgba(9, 29, 52, 0.03);
        }

        tr,
        th {
            background-color: white;
        }

        th {
            position: relative;
            display: flex;
            align-items: center;
            text-align: left;
            width: var(--table-col-width);
            padding-block: 1.4rem;
            font-weight: normal;
            font-size: var(--text-size-12);
            color: var(--text-color-light);
            user-select: none;

            .header-cell {
                display: flex;
                align-items: center;
                padding: 0.5rem;
                border-radius: 8px;

                &:has(.sortable) {
                    cursor: pointer;

                    &:hover {
                        background-color: var(--disable-bg-color);
                    }
                }

                .sortable {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    color: var(--gray-light);

                    svg:first-child {
                        margin-bottom: -1rem;
                    }

                    svg.active {
                        color: var(--primary-light);
                    }
                }
            }

            &:first-child .header-cell {
                padding-left: 2rem;
            }

            .resizer {
                position: absolute;
                right: 0;
                width: 3px;
                height: 25px;
                border-radius: 2px;
                cursor: col-resize;
                background-color: var(--border-light);

                &:hover {
                    background-color: var(--gray-light);
                }

                &:after {
                    content: '';
                    position: absolute;
                    inset: 0;
                    margin: -8px;
                }

                &.is-resizing {
                    background-color: var(--primary-lighten);
                }
            }
        }
    }

    tbody {
        display: grid;
        position: relative;
        height: var(--table-body-height);

        tr,
        td {
            background-color: white;
        }

        tr:hover,
        tr:hover td {
            background-color: var(--bg-color);
        }

        tr {
            position: absolute;
            display: flex;
            align-items: center;
            width: 100%;
            height: var(--table-row-height);
            transform: translateY(var(--table-row-translate-y));

            &:not(:last-child) {
                border-bottom: 1px solid var(--border-light);
            }
        }

        td {
            height: 100%;
            display: grid;
            gap: 0.2rem;
            align-items: center;
            justify-content: start;
            width: var(--table-col-width);
            padding: 1rem;
            font-size: var(--text-size-14);

            &:first-child {
                padding-left: 2rem;
            }

            &:last-child {
                padding-right: 2rem;
            }

            .empty-cell {
                color: var(--disable);
            }
        }

        .is-row-clicked {
            cursor: pointer;
        }
    }
}
</style>
