<template>
    <PSelect
        v-model="documentStatus"
        :label="label"
        :clearable="clearable"
        :options="documentOptions"
        :disabled="disabled"
    />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { PSelect } from '@/shared/ui';
import { BookingDocumentStatus } from '../model/Booking';
import { getBookingDocumentStatusName } from '../model/utils';

withDefaults(
    defineProps<{
        disabled?: boolean;
        label?: string;
        clearable?: boolean;
    }>(),
    {
        label: 'Статус документа'
    }
);

const documentStatus = defineModel('modelValue');

const documentOptions = computed(() => {
    return Object.values(BookingDocumentStatus).map(status => ({
        id: status,
        name: getBookingDocumentStatusName(status)
    }));
});
</script>
