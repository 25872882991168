<template>
    <div class="flex flex-col gap-4">
        <FileUpload
            post-action="/api/v1/products/assortment/parse"
            upload-only
            extensions="xlsx"
            :disabled="loading"
            :payload="wabcCodePayload"
            :validate="confirmUpload"
            @upload="fileUpload"
        />

        <a
            href="/docs/шаблон_списка_товаров.xlsx"
            class="button button--text w-fit"
            download="Шаблон Списка Товаров.xlsx"
        >
            <PIcon name="file-blank-outline" size="18" />
            Скачать шаблон товаров
        </a>

        <div v-if="totalCount" class="text-[14px]">
            <p>
                Всего в списке: <strong class="text-green-900">{{ totalCount }}</strong>
            </p>

            <div v-if="successCount">
                <p class="text-green-800">
                    Успешно добавлено: <strong class="text-green-900">{{ successCount }}</strong>
                </p>
            </div>

            <div v-if="errorsCount" class="flex flex-col gap-2">
                <p class="text-red-500">
                    Количество ошибок: <strong class="text-red-600">{{ errorsCount }}</strong>
                    <PButton variant="text" class="ml-3" @click="toggleErrorList">
                        [{{ isShowError ? 'закрыть' : 'подробнее' }}]
                    </PButton>
                </p>

                <PAlert v-show="isShowError" :visible-icon="false">
                    <ul class="errors-wrapper">
                        <li v-for="(error, i) in errors" :key="i" class="text-red-500">
                            <strong class="text-red-600 font-medium">Строка: {{ error.line }};</strong>
                            {{ error.message }}
                        </li>
                    </ul>
                </PAlert>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { FileUpload, PAlert, PButton, PIcon } from '@/shared/ui';
import { useConfirmStore } from '@/shared/ui/PConfirm/useConfirmStore';
import type { Medicine, MedicineId } from '@/entities/Medicine';
import type { CompanyProtekId } from '@/shared/model/types/Company';
import ConfirmUploadModal from './ConfirmUploadModal.vue';

interface FileParseInfo {
    successCount: number;
    errorCount: number;
    totalCount: number;
    success: { name: string; protekCode: MedicineId }[];
    errors: { line: number; message: string }[];
}

const props = defineProps<{
    modelValue: Medicine[];
    wabcCode?: CompanyProtekId | null;
    loading: boolean;
}>();
const medicines = defineModel<Medicine[]>('modelValue', { required: true });

const wabcCodePayload = computed(() => {
    return props.wabcCode ? { wabcCode: props.wabcCode } : undefined;
});

const formatToMedicine = (item: { name: string; protekCode: MedicineId }) => {
    return {
        currentWabcName: item.name,
        medicineCode: item.protekCode
    } as Medicine;
};

const fileParseInfo = ref<FileParseInfo>();
const confirmStore = useConfirmStore();
const isShowError = ref(false);

const confirmUpload = async (file: File) => {
    const { isConfirmed } = await confirmStore.add({ component: ConfirmUploadModal, props: { file } });
    return isConfirmed as boolean;
};

const fileUpload = (fileInfo: FileParseInfo) => {
    fileParseInfo.value = fileInfo;

    const successItems = fileInfo.success.map(formatToMedicine);

    if (successItems.length) {
        medicines.value = successItems;
    }
};

const totalCount = computed(() => fileParseInfo.value?.totalCount);
const successCount = computed(() => fileParseInfo.value?.successCount);
const errorsCount = computed(() => fileParseInfo.value?.errorCount);

const errors = computed(() => fileParseInfo.value?.errors);

const toggleErrorList = () => {
    isShowError.value = !isShowError.value;
};

watch(medicines, values => {
    if (values.length === 0) {
        fileParseInfo.value = undefined;
    }
});
</script>

<style lang="scss" scoped>
@import '@/shared/styles/mixin.scss';

.errors-wrapper {
    display: grid;
    gap: 1rem;
    border: 1px solid var(--bg-color);
    color: var(--danger-light);
    max-height: 150px;
    overflow: auto;
    font-size: 14px;
    border-radius: var(--border-radius-8);
    @include scrollbar();

    strong {
        color: var(--danger);
    }
}
</style>
