<template>
    <PSelect
        v-model="value"
        search
        :clearable="clearable"
        :disabled="disabled"
        :label="label"
        :loading="isSearching || isLoadingInit"
        :multiple="multiple"
        :options="options"
        :placement="placement"
        :return-object="returnObject"
        :tags="tags"
        @open="prefetchData"
        @add-item="changeItem"
        @remove-item="changeItem"
        @update:search-input="searchMedications"
    >
        <template #list-item="{ item }: { item: { raw: ZdravcityCategory } }">
            <div>
                <span class="text-gray-500">[{{ item.raw.code }}]</span>
                {{ item.raw.name }}
            </div>
        </template>
        <template #afterList>
            <div v-if="!isLoadedAllData" ref="lastItemEl" class="p-[10px_20px] text-gray-500 mb-4">
                <div v-if="isLoadingItemData" class="loading-item">Загрузка...</div>
            </div>
        </template>
    </PSelect>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import { useIntersectionObserver } from '@vueuse/core';
import isEqual from 'lodash/isEqual';
import uniqBy from 'lodash/uniqBy';
import { CanceledError } from 'axios';
import { PSelect } from '@/shared/ui';
import type { Pagination } from '@/shared/model/types/Pagination';
import { NetworkError } from '@/errors/NetworkError';
import { notifyError } from '@/shared/model/utils/showNotify';
import sentry from '@/shared/lib/sentry/sentry';
import { logger } from '@/shared/model/utils';
import { type Placement } from '@/shared/lib/popper';

import type { ZdravcityCategory, ZdravcityCategoryId } from '../model/ZdravcityCategory';
import type { CategoryParams } from '../api/ZdravcityCategoryService';
import { useZdravcityCategoryStore } from '../model/ZdravcityCategoryStore';
import { formatParams } from '../model/utils';

enum Status {
    DEFAULT = 'default',
    LOADING_ITEM = 'loading-item',
    LOADING_SEARCH = 'loading-search',
    LOADING_ALL = 'loading-all',
    LOADING_INIT = 'loading-init'
}

type ZdravcityCategoryModel =
    | ZdravcityCategoryId
    | ZdravcityCategoryId[]
    | ZdravcityCategory
    | ZdravcityCategory[]
    | null;

interface ZdravcityCategoryProps {
    modelValue?: ZdravcityCategoryModel;
    label?: string;
    disabled?: boolean;
    multiple?: boolean;
    clearable?: boolean;
    tags?: boolean;
    returnObject?: boolean;
    prefetch?: boolean;
    onlySearch?: boolean;
    placement?: Placement;
}

const props = withDefaults(defineProps<ZdravcityCategoryProps>(), {
    label: 'Категории',
    modelValue: null,
    disabled: false,
    multiple: false,
    returnObject: false,
    filters: undefined,
    prefetch: false,
    onlySearch: false,
    placement: 'bottom-start'
});

const emit = defineEmits<{
    (e: 'update:modelValue', val: ZdravcityCategoryModel): void;
    (e: 'change', val: ZdravcityCategoryModel): void;
}>();

const value = defineModel<ZdravcityCategoryModel>('modelValue', { required: true });

const categoryStore = useZdravcityCategoryStore();
const searchValue = ref('');
const options = ref<ZdravcityCategory[]>([]);
const lastItemEl = ref<HTMLElement>();
const status = ref<Status>(Status.DEFAULT);
const currentPage = ref(0);
let abortController: AbortController;

const isLoadingInit = computed(() => status.value === Status.LOADING_INIT);
const isLoadedAllData = computed(() => status.value === Status.LOADING_ALL);
const isLoadingItemData = computed(() => status.value === Status.LOADING_ITEM);
const isSearching = computed(() => status.value === Status.LOADING_SEARCH);

const getMedicineCodesFromValue = (inputValue: ZdravcityCategoryModel): ZdravcityCategoryId[] => {
    const formatValues = Array.isArray(inputValue) ? inputValue : [inputValue];
    return props.returnObject
        ? (formatValues as ZdravcityCategory[]).map(m => m.id)
        : (formatValues as ZdravcityCategoryId[]);
};

const checkAllDataLoaded = (pagination: Pagination) => {
    return pagination.totalItems <= pagination.currentPage * pagination.itemsPerPage;
};

const searchParams = computed(() => ({
    ...formatParams(searchValue.value)
}));

const initialData = async (categoryId: ZdravcityCategoryId[]) => {
    if (props.onlySearch) return;

    try {
        abortController?.abort();
        abortController = new AbortController();
        status.value = Status.LOADING_INIT;
        options.value = await categoryStore.fetchCategoriesByIdCached(categoryId, abortController);
    } catch (error) {
        if (error instanceof CanceledError) {
            return [];
        }

        let message = 'Произошла ошибка при инициализации данных поиска по товарам';

        if (error instanceof NetworkError) {
            message += '. ' + error.message;
        }

        notifyError(message);
        sentry.captureException(error);
        logger.error(error);
    } finally {
        status.value = Status.DEFAULT;
    }
};

watch(
    () => props.modelValue,
    async newValue => {
        if (!newValue || (Array.isArray(newValue) && newValue.length === 0)) {
            return;
        }

        if (!isEqual(newValue, value.value)) {
            const medicineCodes = getMedicineCodesFromValue(newValue);
            await initialData(medicineCodes);
        }

        if (Array.isArray(newValue) && newValue.length > options.value.length) {
            const medicineCodes = getMedicineCodesFromValue(newValue);
            await initialData(medicineCodes);
        }
    },
    { immediate: true, deep: true }
);

watch(
    () => props.filters,
    (newFilters, oldFilters) => {
        if (!isEqual(newFilters, oldFilters)) {
            options.value = [];
            value.value = props.multiple ? [] : null;
        }
    }
);

const searchMedications = async (search: string) => {
    try {
        searchValue.value = search;
        abortController?.abort();
        abortController = new AbortController();

        status.value = Status.LOADING_SEARCH;
        currentPage.value = 1;
        const { data, pagination } = await categoryStore.fetchCategories(
            {
                ...searchParams.value,
                pagination: { currentPage: currentPage.value }
            },
            abortController
        );

        options.value = data;

        if (checkAllDataLoaded(pagination)) {
            status.value = Status.LOADING_ALL;
            return;
        }

        status.value = Status.DEFAULT;
    } catch (error) {
        if (error instanceof CanceledError) {
            return [];
        }

        let message = 'Произошла ошибка при поиске категорий';

        if (error instanceof NetworkError) {
            message += '. ' + error.message;
        }

        notifyError(message);
        sentry.captureException(error);
        logger.error(error);
        status.value = Status.DEFAULT;
    }
};

const changeItem = () => {
    emit('change', value.value);
};

const prefetchData = async () => {
    if (props.prefetch) {
        await searchMedications('');
    }
};

const getNextMedications = async (params: CategoryParams) => {
    try {
        status.value = Status.LOADING_ITEM;
        return await categoryStore.fetchCategories(params);
    } catch (error) {
        let message = 'Произошла ошибка при запросе списка данных товаров';

        if (error instanceof NetworkError) {
            message += '. ' + error.message;
        }

        notifyError(message);
        sentry.captureException(error);
        logger.error(error);
    } finally {
        status.value = Status.DEFAULT;
    }
};

useIntersectionObserver(lastItemEl, async ([{ isIntersecting }]) => {
    if (isLoadedAllData.value || isLoadingItemData.value) {
        return;
    }

    if (isIntersecting) {
        const payload = {
            pagination: { currentPage: (currentPage.value += 1) },
            ...searchParams.value
        };

        const response = await getNextMedications(payload);

        if (!response) {
            return;
        }

        const { data, pagination } = response;

        if (checkAllDataLoaded(pagination)) {
            status.value = Status.LOADING_ALL;
        }

        options.value = uniqBy([...options.value, ...data], item => item.id);
    }
});

onMounted(async () => {
    const medicineCodes = value.value && getMedicineCodesFromValue(value.value);

    if (medicineCodes?.length) {
        await initialData(medicineCodes);
    }
});

onBeforeRouteLeave(() => {
    abortController?.abort();
});
</script>

<style lang="scss" scoped>
.loading-item {
    display: flex;

    &:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 2px solid var(--text-color-light);
        border-top-color: transparent;
        animation: rotate 0.7s linear infinite;
        margin-right: 1rem;
    }

    @keyframes rotate {
        to {
            transform: rotate(1turn);
        }
    }
}
</style>
