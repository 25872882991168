import { computed, reactive } from 'vue';
import { defineStore } from 'pinia';
import type { ZdravcityCategory, ZdravcityCategoryId } from './ZdravcityCategory';
import { getZdravcityCategories, type CategoryParams } from '../api/ZdravcityCategoryService';
import type { Pagination } from '@/shared/model/types/Pagination';

const cache = new Map<string, { data: ZdravcityCategory[]; pagination: Pagination }>();

export const useZdravcityCategoryStore = defineStore('zdravcityCategory', () => {
    const categoriesMap = reactive(new Map<ZdravcityCategoryId, ZdravcityCategory>());

    const categories = computed(() => {
        return Array.from(categoriesMap.values());
    });

    const getCategoryById = (categoryId: ZdravcityCategoryId) => {
        return categoriesMap.get(categoryId);
    };

    const addCategories = (categories: ZdravcityCategory[]) => {
        for (const category of categories) {
            categoriesMap.set(category.id, category);
        }
    };

    const fetchCategories = async (params: CategoryParams, abortController?: AbortController) => {
        const cacheKey = JSON.stringify(params ?? 'default');
        const cachedData = cache.get(cacheKey);

        if (cachedData) {
            return cachedData;
        }

        const response = await getZdravcityCategories(params, abortController);

        addCategories(response.data);

        cache.set(cacheKey, response);

        return response;
    };

    const fetchCategoriesByIdCached = async (categoryIds: ZdravcityCategoryId[], abortController?: AbortController) => {
        const oldIdsSet = new Set(categoriesMap.keys());
        const newIds = categoryIds.filter(categoryId => !oldIdsSet.has(categoryId));

        if (newIds.length) {
            await fetchCategories(
                {
                    ids: newIds,
                    pagination: {
                        currentPage: 1,
                        itemsPerPage: newIds.length
                    }
                },
                abortController
            );
        }

        const categoryIdsSet = new Set(categoryIds);
        return categories.value.filter((m: ZdravcityCategory) => categoryIdsSet.has(m.id));
    };

    return {
        categories,
        getCategoryById,
        fetchCategories,
        fetchCategoriesByIdCached,
        addCategories
    };
});
