import type { Brand, dateISO } from '@/shared/model/types/Kernel';
import type { CompanyId, CompanySystemId } from '@/shared/model/types/Company';
import type { PlacementId } from '@/entities/Placement/model/Placement';

export type QueueFileTaskId = Brand<number, 'queueFileTask'>;

export enum QueueFileTaskType {
    /**
     * Загрузка данных из Excel для ключевых слов
     */
    KEYWORD_STATISTICS_PARSE = 'KEYWORD_STATISTICS_PARSE',

    /**
     * Сформировать шаблон для ключевых слов
     */
    KEYWORD_STATISTICS_REPORT = 'KEYWORD_STATISTICS_REPORT',

    /**
     * Сформировать отчёт для цен товаров
     */
    PRODUCT_PRICES_REPORT = 'PRODUCT_PRICES_REPORT',

    /**
     * Сформировать отчёт для товаров
     */
    PRODUCTS_REPORT = 'PRODUCTS_REPORT'
}

export enum QueueFileTaskStatus {
    /**
     * Задача добавлена в очередь
     */
    NEW = 'NEW',

    /**
     * Задача в обработке
     */
    IN_PROGRESS = 'IN_PROGRESS',

    /**
     * Задача не обработана
     */
    FAILED = 'FAILED',

    /**
     * Задача завершена
     */
    COMPLETED = 'COMPLETED'
}

interface QueueFileTaskCompany {
    id: CompanyId;
    name: string;
    systemId: CompanySystemId;
}

interface QueueFileTaskPlacement {
    placementId: PlacementId;
    year: number;
}

export interface QueueFileTask {
    id: QueueFileTaskId;
    fileName: string | null;
    type: QueueFileTaskType;
    status: QueueFileTaskStatus;
    companies: QueueFileTaskCompany[] | null;
    params: QueueFileTaskPlacement | null;
    hasErrors: boolean;
    createdAt: dateISO;
    updatedAt: dateISO;
}

export interface QueueFileTaskError {
    line: number;
    messages: string[];
}
