<template>
    <PSelect
        v-model="agreementType"
        :label="label"
        :clearable="clearable"
        :options="agreementOptions"
        :disabled="disabled"
        :multiple="multiple"
    />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { PSelect } from '@/shared/ui';
import { BookingAgreementType } from '../model/Booking';
import { getBookingAgreementTypeName } from '../model/utils';

withDefaults(
    defineProps<{
        disabled?: boolean;
        label?: string;
        clearable?: boolean;
        multiple?: boolean;
    }>(),
    {
        label: 'Тип договора'
    }
);

const agreementType = defineModel('modelValue');

const agreementOptions = computed(() => {
    return Object.values(BookingAgreementType).map(type => ({
        id: type,
        name: getBookingAgreementTypeName(type)
    }));
});
</script>
