<template>
    <teleport to="#drawer">
        <transition name="drawer">
            <div
                v-if="isOpenModel"
                class="drawer-backdrop"
                data-testid="drawer-backdrop"
                @click.self.stop="closeOnBackdrop"
            >
                <focus-trap :active="isActiveFocusTrap" :escape-deactivates="false">
                    <div v-loading="loading" class="drawer" :style="styleDrawer" v-bind="additionalAttrs">
                        <PButton
                            v-if="visibleCloseButton"
                            variant="text"
                            class="drawer-close-button"
                            icon="close"
                            aria-label="Закрыть окно"
                            @click.prevent="closeDrawer"
                        />
                        <slot :open="openDrawer" :close="closeDrawer" />
                    </div>
                </focus-trap>
            </div>
        </transition>
    </teleport>
</template>

<script lang="ts" setup>
import { FocusTrap } from 'focus-trap-vue';
import { computed, onBeforeUnmount, onMounted, useAttrs, watch } from 'vue';
import { PButton } from '@/shared/ui';
import { isNumericString } from '@/shared/model/utils';
import { useBodyOverflow } from '@/shared/model/composables/useBodyOverflow';

interface Props {
    isOpen: boolean;
    width?: number | string;
    closedOnBackdrop?: boolean;
    closedOnEscape?: boolean;
    visibleCloseButton?: boolean;
    loading?: boolean;
    focusTrap?: boolean;
}

defineOptions({
    inheritAttrs: false
});

defineSlots<{
    default: (scope: { open: VoidFunction; close: VoidFunction }) => void;
}>();

const props = withDefaults(defineProps<Props>(), {
    width: 560,
    closedOnBackdrop: true,
    closedOnEscape: true,
    visibleCloseButton: true,
    loading: false,
    focusTrap: true
});

const attrs = useAttrs();
const isOpenModel = defineModel<boolean>('isOpen', { required: true });
const { setBodyOverflow } = useBodyOverflow();

watch(isOpenModel, setBodyOverflow, { immediate: true });

const isActiveFocusTrap = computed<boolean>(() => props.focusTrap && isOpenModel.value);

const additionalAttrs = computed(() => {
    const { class: className } = attrs;
    return {
        class: className
    };
});

const openDrawer = async () => {
    isOpenModel.value = true;
};

const closeDrawer = async () => {
    isOpenModel.value = false;
};

const styleDrawer = computed(() => {
    return {
        width: isNumericString(`${props.width}`) ? `${props.width}px` : props.width
    };
});

const closeOnBackdrop = () => {
    if (props.closedOnBackdrop) {
        isOpenModel.value = false;
    }
};

const escapeHandler = (event: KeyboardEvent) => {
    if (!props.closedOnEscape) return;

    if (event.key === 'Escape') {
        isOpenModel.value = false;
    }
};

onMounted(() => {
    document.addEventListener('keydown', escapeHandler, false);
});

onBeforeUnmount(() => {
    document.removeEventListener('keydown', escapeHandler, false);
});
</script>

<style lang="scss" scoped>
@import '@/shared/styles/mixin';

.drawer-backdrop {
    position: fixed;
    inset: 0;
    z-index: var(--z-index-drawer-backdrop, 1000);
    overflow: hidden;
    background-color: rgba(19, 19, 19, 0.52);
    transition: opacity 0.3s ease-in-out; // Анимация появления
    overscroll-behavior: contain;
}

.drawer {
    position: absolute;
    right: 0;
    padding: 3.2rem;
    height: 100%;
    background-color: var(--white);
    border-radius: var(--border-radius-16) 0 0 var(--border-radius-16);
    box-shadow: var(--shadow-card-dark);
    pointer-events: auto;
    translate: 0;
    transition: 0.3s translate ease-in-out 0.3s; // Анимация появления
    overflow: auto;
    overscroll-behavior: contain;
    @include scrollbar();
}

.drawer-close-button {
    position: absolute;
    right: 1.8rem;
    top: 2rem;
    width: 2.4rem;
    height: 2.4rem;
    color: var(--text-color-light);

    &:hover {
        color: var(--text-color);
    }

    &:not(:focus-visible) {
        outline: none;
    }
}

.drawer-enter-to.drawer-backdrop,
.drawer-leave-from.drawer-backdrop {
    opacity: 1;
}

.drawer-enter-from.drawer-backdrop,
.drawer-leave-to.drawer-backdrop {
    opacity: 0;
}

.drawer-enter-active .drawer,
.drawer-leave-active .drawer {
    translate: 0;
}

.drawer-enter-from .drawer,
.drawer-leave-to .drawer {
    translate: 100%;
}
</style>
