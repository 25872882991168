import type { AnyAbility, SubjectType } from '@casl/ability';
import { ref } from 'vue';

// SEE: https://github.com/stalniy/casl/blob/master/packages/casl-vue/src/reactiveAbility.ts
export function reactiveAbility(ability: AnyAbility) {
    if (Object.hasOwn(ability, 'possibleRulesFor')) {
        return ability;
    }

    const watcher = ref(true);
    ability.on('updated', () => {
        watcher.value = !watcher.value;
    });

    const possibleRulesFor = ability.possibleRulesFor.bind(ability);
    ability.possibleRulesFor = (action: string, subject: SubjectType) => {
        watcher.value = watcher.value; // eslint-disable-line
        return possibleRulesFor(action, subject);
    };
    ability.can = ability.can.bind(ability);
    ability.cannot = ability.cannot.bind(ability);

    return ability;
}
