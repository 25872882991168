import { PlacementParamType, PlacementContentType, PlacementType } from './Placement';

export const getPlacementParamTypeName = (paramType: PlacementParamType) => {
    switch (paramType) {
        case PlacementParamType.DEFAULT:
            return 'Без привязки';
        case PlacementParamType.CATEGORY:
            return 'Категория';
        case PlacementParamType.CAROUSEL:
            return 'Место в карусели';
        case PlacementParamType.WORDS:
            return 'Привязка к словам';
        case PlacementParamType.GUID:
            return 'Привязка к кодам товара (GUID)';
        case PlacementParamType.MNN:
            return 'Привязка к действующему веществу (МНН)';
        case PlacementParamType.SPECIAL_EVENTS:
            return 'Страница спец. мероприятий';
        case PlacementParamType.BRAND:
            return 'Привязка к бренду';
        case PlacementParamType.CATEGORY_OR_CVP:
            return 'Категория или код CVP товара';
    }
};

export const getPlacementContentTypeName = (contentType: PlacementContentType) => {
    switch (contentType) {
        case PlacementContentType.BANNER:
            return 'Banner';
        case PlacementContentType.HTML:
            return 'HTML';
    }
};

export const getPlacementTypeName = (type: PlacementType) => {
    switch (type) {
        case PlacementType.BY_TIME:
            return 'By Time';
        case PlacementType.CPM:
            return 'CPM';
        case PlacementType.EXTERNAL:
            return 'Внешнее';
    }
};
