<template>
    <PSelect
        v-model="value"
        label="Привязка к..."
        :disabled="disabled"
        :clearable="clearable"
        :options="paramTypeOptions"
        data-testid="param-type-select"
    />
</template>

<script lang="ts" setup>
import { PSelect } from '@/shared/ui';
import { PlacementParamType } from '../model/Placement';
import { getPlacementParamTypeName } from '../model/utils';

withDefaults(
    defineProps<{
        disabled?: boolean;
        clearable?: boolean;
    }>(),
    {
        clearable: true
    }
);

const value = defineModel<PlacementParamType | null>('modelValue', { required: true });

const paramTypeOptions = [
    PlacementParamType.DEFAULT,
    PlacementParamType.CATEGORY,
    PlacementParamType.CATEGORY_OR_CVP,
    PlacementParamType.CAROUSEL,
    PlacementParamType.WORDS,
    PlacementParamType.GUID,
    PlacementParamType.MNN,
    PlacementParamType.SPECIAL_EVENTS,
    PlacementParamType.BRAND
].map(paramType => ({
    id: paramType,
    name: getPlacementParamTypeName(paramType)
}));
</script>
