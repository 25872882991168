<template>
    <div
        tabindex="-1"
        :class="[
            'control control--datepicker',
            {
                'control--focused': isOpen,
                'control--has-value': hasValue,
                disabled: disabled
            }
        ]"
        @click.stop="onOpen"
    >
        <div class="control__wrapper">
            <div class="control__placeholder">{{ label }}</div>
            <DatePicker
                v-model:value="date"
                v-model:open="isOpen"
                input-class="control__element"
                :spellcheck="false"
                :prefix-class="`${range ? 'range' : 'control'}`"
                :format="formatInput"
                popup-class="datepicker"
                :type="type"
                :value-type="format"
                :disabled-date="disabledDate"
                :range="range"
                :lang="datepickerLocale"
                editable
                @change="onChange"
            >
                <template #input>
                    <input
                        ref="inputMaskElement"
                        type="text"
                        class="control__element"
                        spellcheck="false"
                        :disabled="disabled"
                        data-testid="datepicker-input"
                        @change="updateManualInput"
                    />
                </template>
                <template #icon-clear><span /></template>
                <template #icon-calendar><span /></template>
            </DatePicker>
        </div>

        <div class="control__actions">
            <PButton v-if="clearable" class="control__clear-button" variant="text" icon="close" @click.stop="onClear" />
            <slot name="append" />
            <Icon name="date" class="control__icon" />
        </div>

        <div ref="datepicker" class="datepicker" tabindex="-1"></div>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { datepickerLocale } from '@/shared/config/locale';
import { dayjs } from '@/shared/lib/dayjs/dayjs';
import PButton from '@/shared/ui/PButton/PButton.vue';
import Icon from '@/shared/ui/PIcon/PIcon.vue';
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import './style.scss';
import { IMask, useIMask } from 'vue-imask';

type FormatInput = 'DD.MM.YYYY' | 'YYYY';

interface Props {
    /**
     * Тип отображения календаря
     */
    type?: 'date' | 'month' | 'year';
    label?: string;
    /**
     * Формат выводимого значения в поле ввода
     */
    formatInput?: FormatInput;
    /**
     * Формат выводимого значения
     */
    format?: string;
    /**
     * Отображение кнопки для сброса значения
     */
    clearable?: boolean;
    /**
     * Метод для блокировки дней (date) => boolean
     * @param {date} date
     */
    disabledDate?: (date: Date) => boolean;
    /**
     * Отображение в формате readonly
     */
    readonly?: boolean;
    disabled?: boolean;
    range?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    type: 'date',
    label: 'Дата',
    formatInput: 'DD.MM.YYYY',
    format: 'YYYY-MM-DDT00:00:00',
    disabledDate: undefined
});

defineSlots<{
    append: () => void;
}>();

const isOpen = ref(false);
// если типы объявить тут явно, то во многих местах
// по проекту перестанет проходить проверка типов
// export type DATEPICKER_DATE = string | null | undefined;
// export type DATEPICKER_MODEL = DATEPICKER_DATE | [DATEPICKER_DATE, DATEPICKER_DATE] | [];
const date = defineModel('modelValue', { local: true });

const getOptions = () => {
    let mask: string = props.formatInput;
    if (props.range) {
        mask = `${mask} - ${mask}`;
    }
    return {
        mask,
        lazy: false,
        blocks: {
            YYYY: {
                mask: IMask.MaskedRange,
                from: 1900,
                to: 2999,
                placeholderChar: 'г'
            },

            MM: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 12,
                placeholderChar: 'м'
            },

            DD: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 31,
                placeholderChar: 'д'
            }
        }
    };
};

const {
    el: inputMaskElement,
    masked,
    unmasked
} = useIMask(getOptions(), {
    onComplete() {
        value.value = masked.value;
    }
});
const updateManualInput = () => {
    if (!unmasked.value.length) {
        date.value = props.range ? [] : '';
    }

    if (props.range) {
        if (masked.value !== value.value) {
            masked.value = value.value;
        }
        return;
    }

    const isValid = (date = '') => {
        const _date = dayjs(date, props.formatInput);
        return _date.isValid() && !props.disabledDate?.(_date.toDate());
    };

    if (!isValid(unmasked.value)) {
        masked.value = value.value;
    }
};

const value = computed({
    get() {
        const _date = date.value;
        const format = (date = '') =>
            dayjs(date, props.format).isValid() ? dayjs(date, props.format).format(props.formatInput) : date;

        if (!Array.isArray(_date)) {
            return format(String(_date));
        }

        return _date.map(format).join(` - `);
    },
    set(toValue) {
        const isValid = (date = '') => {
            const _date = dayjs(date, props.formatInput);
            return _date.isValid() && !props.disabledDate?.(_date.toDate());
        };
        const format = (date = '') => dayjs(date, props.formatInput).format(props.format);

        if (!toValue) {
            toValue = '';
        }

        if (!props.range) {
            if (isValid(toValue)) {
                date.value = format(toValue);
            }
            return;
        }

        const dates = toValue
            .split('-')
            .map(s => s.trim())
            .filter(isValid)
            .map(format);
        if (dates.length === 2 && dayjs(dates[0], props.format).isSameOrBefore(dayjs(dates[1], props.format), 'day')) {
            date.value = dates;
        }
    }
});
watch(
    value,
    toValue => {
        masked.value = toValue;
    },
    {
        immediate: true
    }
);

const hasValue = computed<boolean>(() => {
    if (Array.isArray(date.value)) {
        return date.value.filter(Boolean).length > 0;
    }

    return !!date.value;
});

const onOpen = () => {
    if (isOpen.value) {
        return;
    }

    isOpen.value = true;

    if (!props.range) {
        (inputMaskElement.value as HTMLInputElement)?.focus();
    }
};

const onClose = () => {
    isOpen.value = false;
};

const onClear = () => {
    if (props.range) {
        date.value = [];
    } else {
        date.value = undefined;
    }

    onClose();
};

const onChange = (dateValue?: unknown) => {
    if (dateValue && typeof dateValue === 'string') {
        date.value = dateValue;
    }

    if (Array.isArray(dateValue)) {
        const startDate = typeof dateValue[0] === 'string' ? dateValue[0] : null;
        const endDate = typeof dateValue[1] === 'string' ? dateValue[1] : null;

        date.value = startDate && endDate ? [startDate, endDate] : [];
    }
};
</script>

<style lang="scss">
.datepicker .control-range-wrapper {
    display: grid;
    grid-template-columns: 300px 300px;
}

.not-current-month div {
    opacity: 0;
    cursor: default;
    pointer-events: none;
}
</style>
