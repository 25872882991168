import type { PropExtensions } from './types';
import { isEmpty } from 'lodash';

export const validationDimensions = (
    file: File,
    options: { width?: number | string; height?: number | string; validationDimensionsType: 'equal' | 'within' }
) => {
    return new Promise<boolean>(resolve => {
        const isImageType = file.type.includes('image');

        let isValid = true;

        if (isImageType && (options.width || options.height)) {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            const image = new Image();

            reader.addEventListener(
                'load',
                (event: Event) => {
                    image.src = (event.target as FileReader).result as string;
                },
                { once: true }
            );

            image.addEventListener(
                'load',
                (event: Event) => {
                    const width = (event.target as HTMLImageElement).width as number;
                    const height = (event.target as HTMLImageElement).height as number;

                    const _width = Number(options.width);
                    const _height = Number(options.height);
                    if (options.validationDimensionsType === 'equal') {
                        isValid = _width === width && _height === height;
                    }

                    if (options.validationDimensionsType === 'within') {
                        isValid = _width >= width && _height >= height;
                    }

                    resolve(isValid);
                },
                { once: true }
            );
        } else {
            resolve(isValid);
        }
    });
};

export const validateExtension = (fileName: string, options: { extensions: PropExtensions }): boolean => {
    if (options.extensions === '*') {
        return true;
    }

    let extensions: (string | RegExp)[];
    if (typeof options.extensions === 'string') {
        extensions = options.extensions.trim().split(' ');
    } else if (options.extensions instanceof RegExp) {
        extensions = [options.extensions];
    } else {
        extensions = options.extensions;
    }

    if (extensions.length === 0) {
        return true;
    }

    return extensions.some(extension => {
        return typeof extension === 'string'
            ? fileName.toLowerCase().endsWith(extension.toLowerCase())
            : extension.test(fileName);
    });
};

export const validateSize = (size: number, options: { size: string | number }): boolean => {
    return !options.size || size < +options.size;
};

export const validateExistExtension = (fileName: string, options: { extensions: PropExtensions }): boolean => {
    if (options.extensions === '*' || isEmpty(options.extensions)) {
        return true;
    }

    return !!fileName.split('.').at(-1);
};
