import { TypeOrder } from './B2BAsyncReport';
import dayjs from 'dayjs';
import kebabCase from 'lodash/kebabCase';

export function convertTypeOrderToString(typeOrder: TypeOrder) {
    switch (typeOrder) {
        case TypeOrder.YES:
            return 'Да';
        case TypeOrder.NO:
            return 'Нет';
        default:
            return '';
    }
}
export const getReportTarget = ([typeOrder]: TypeOrder[] = []) => {
    switch (typeOrder) {
        case TypeOrder.NO:
            return 'b2b';
        case TypeOrder.YES:
            return 'b2c';
        default:
            return 'common';
    }
};

export const formatReportFileName = (payload: {
    fileName: string;
    reportName: string;
    startDate: string;
    endDate: string;
}): string => {
    const formattedDates = [payload.startDate, payload.endDate]
        .filter(Boolean)
        .map(date => (date ? dayjs(date, ['DDMMYYYY', 'DD.MM.YYYY', 'YYYY.MM.DD']).format('DD.MM.YYYY') : ''))
        .join('_');

    const currentDate = dayjs().format('DD.MM.YYYY_HH-mm-ss');
    return `${currentDate}-${kebabCase(payload.fileName)}-${kebabCase(payload.reportName)}${
        formattedDates ? `-${formattedDates}` : ''
    }`;
};
