import type { Brand } from '@/shared/model/types/Kernel';

export enum TypeOrder {
    YES = 1,
    NO = 0,
    ALL = -1
}

export enum ReportType {
    CSV = 'csv',
    DBF = 'dbf'
}

export enum B2BAsyncReportStatus {
    ACCEPTED = 'ACCEPTED',
    COMPLETED = 'COMPLETED',
    IN_PROGRESS = 'IN_PROGRESS',
    FAILED = 'FAILED'
}
export interface B2BAsyncReport {
    id: Brand<number, 'b2bAsyncReport'>;
    deleted: number;
    format: string;
    insDate: string;
    parameters: string;
    resultUrl: string;
    status: B2BAsyncReportStatus;
    taskEnd: string;
    taskStart: string;
    taskType: string;
}
