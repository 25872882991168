<template>
    <PModal v-model="isOpenModal" width="690" :closed-on-backdrop="false" :loading="loading">
        <div>
            <h2 class="mb-4">Список отчётов</h2>
            <div class="text-gray-400 font-[500] text-[14px]">
                {{ getActionReportTypeName(reportActionType) }}
            </div>
        </div>

        <DataTable :data="reports" :columns="columns" class="-ml-[32px] -mr-[44px]">
            <template #status="{ data }">
                <StatusView
                    v-loading.skeleton="statusInProgressOrAccept(data.status) && refreshStatus"
                    :status="data.status"
                />
            </template>

            <template #action="{ data }">
                <div class="truncate space-x-5 text-right">
                    <a v-if="data.resultUrl" v-tooltip :href="data.resultUrl" content="Скачать" class="link" download>
                        <Icon name="download" size="20" />
                    </a>
                </div>
            </template>
        </DataTable>

        <PButton variant="outline" :loading="loading" @click="closeModal"> Закрыть </PButton>
    </PModal>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { CanceledError } from 'axios';
import { DataTable, PButton, PIcon as Icon, PModal } from '@/shared/ui';
import type { CompanyProtekId } from '@/shared/model/types/Company';
import { notifyError } from '@/shared/model/utils/showNotify';
import { logger } from '@/shared/model/utils';
import sentry from '@/shared/lib/sentry/sentry';
import { getActionReportAsyncType, getActionReportTypeName, ReportActionType } from '@/entities/ActionPromo';
import {
    type B2BAsyncReport,
    B2BAsyncReportService,
    B2BAsyncReportStatus,
    StatusView,
    useRefreshTasksStatus
} from '@/entities/B2BAsyncReport';
import { ACTION_PROMO_ASYNC_REPORT_COLUMNS } from '../model/columns';

const props = defineProps<{
    reportActionType: ReportActionType;
    supplierCode: CompanyProtekId | null;
    disabled: boolean;
}>();

const isOpenModal = defineModel<boolean>('isOpen', { required: true });

const loading = ref(false);
const columns = ACTION_PROMO_ASYNC_REPORT_COLUMNS;
const reports = ref<B2BAsyncReport[]>([]);
let abortController = new AbortController();

const closeModal = () => {
    isOpenModal.value = false;
};

const statusInProgressOrAccept = (status: B2BAsyncReportStatus) => {
    return status === B2BAsyncReportStatus.IN_PROGRESS || status === B2BAsyncReportStatus.ACCEPTED;
};

const fetchReports = async () => {
    try {
        const data = await B2BAsyncReportService.getAsyncReports(
            getActionReportAsyncType(props.reportActionType),
            {
                currentPage: 1,
                itemsPerPage: 6,
                supplierId: props.supplierCode
            },
            abortController
        );

        reports.value = data.tasks;
    } catch (error) {
        if (error instanceof CanceledError) {
            return;
        }

        notifyError({
            title: 'Ошибка',
            text: 'Не удалось получилось данные по списку отчётов'
        });
        sentry.captureException(error);
        logger.error(error);
    }
};

const refreshStatus = useRefreshTasksStatus(reports, fetchReports);

watch(isOpenModal, isOpen => {
    if (isOpen) {
        loading.value = true;
        fetchReports().finally(() => {
            loading.value = false;
        });
    } else {
        abortController.abort();
        abortController = new AbortController();
        reports.value = [];
    }
});
</script>
