<template>
    <div class="space-y-3">
        <KeywordsSelect v-model="keywordIds" multiple clearable :disabled="loading || selectedLimited" />

        <div v-if="selectedKeywords.length" class="view-scroller">
            <div class="view-scroller__title">Выбранные ключевые слова</div>
            <ul class="view-scroller__list">
                <li v-for="keyword in selectedKeywords" :key="keyword?.id">
                    <PButton
                        variant="text"
                        color="navy-blue"
                        icon="close-small"
                        icon-color="text-gray-400"
                        text-wrap
                        @click="removeKeyword(keyword.id)"
                    >
                        {{ keyword.name }}
                    </PButton>
                </li>
            </ul>
        </div>

        <FileUpload
            post-action="/api/advertising/upload-anchor-words?toLowerCase=true&withId=true"
            extensions=".xlsx"
            upload-only
            @upload="uploadKeywords"
        >
            <template #title> Загрузите список ключевых слов </template>
        </FileUpload>

        <div v-if="keywordErrors.length" class="errorList">
            <div class="errorList_header">
                <span class="errorList_title">
                    Ошибка обработки строк из файла: <strong>{{ keywordErrors.length }}</strong>
                </span>
            </div>
            <ul class="errorList_container">
                <li v-for="(error, i) in keywordErrors" :key="`error${i}`" class="errorList_item">
                    <span class="errorList_text">&#8226; {{ error.message }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { FileUpload, PButton } from '@/shared/ui';
import { type Keyword, type KeywordId, KeywordsSelect, useKeywordsStore } from '@/entities/Keywords';

const KEYWORDS_LIMIT = 50;

defineProps<{ loading: boolean }>();

interface ErrorItem {
    line: number;
    message: string;
}

const keywordIds = defineModel<KeywordId[]>('modelValue', { required: true });
const keywordsStore = useKeywordsStore();

const selectedKeywords = computed(() => {
    return keywordIds.value.map(keywordsStore.getKeywordById).filter(Boolean) as Keyword[];
});

const removeKeyword = (keywordId: KeywordId) => {
    keywordIds.value = keywordIds.value.filter(wordId => wordId !== keywordId);
};

const selectedLimited = computed(() => {
    return selectedKeywords.value.length === KEYWORDS_LIMIT;
});

const keywordErrors = ref<ErrorItem[]>([]);
const uploadKeywords = (payload: { data: Keyword[]; notFoundKeywords: string[] }) => {
    const newKeywords = payload.data.filter(keyword => {
        return !keywordIds.value.some(keywordId => keywordId === keyword.id);
    });
    const newKeywordsIds = Array.from(new Set(newKeywords.map(k => k.id)));

    keywordsStore.addKeywords(newKeywords);
    keywordIds.value.push(...newKeywordsIds);

    keywordErrors.value = payload.notFoundKeywords.map((keyword, i) => {
        return {
            line: i,
            message: `Не найдено ключевое слово "${keyword}"`
        };
    });
};
</script>

<style lang="scss" scoped>
@import '@/shared/styles/mixin.scss';

.view-scroller {
    border: 1px solid var(--border-light);
    background: var(--bg-light);
    border-radius: 8px;
    padding: 10px;

    &__title {
        font-weight: 500;
        font-size: 12px;
        color: var(--text-color-light);
        margin-bottom: 6px;
    }

    &__list {
        max-height: 200px;
        overflow-y: auto;
        overscroll-behavior: contain;
        @include scrollbar-x(6px);
    }

    :deep(.button > span:not(:root)) {
        display: inline-block;
    }
}

.errorList {
    padding: 12px 16px 14px;
    border: 1px solid #eb5757;
    background-color: #fcefef;
    color: #eb5757;
    border-radius: 8px;

    &_header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2.8rem;
    }

    &_container {
        list-style: none;
        max-height: 32rem;
        overflow: auto;

        @include scrollbar();
    }

    &_item {
        display: flex;
        line-height: 2rem;
        width: 100%;
    }

    &_line {
        display: flex;
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: bold;
        min-width: 94px;
        padding-right: 12px;
    }

    &_btn {
        cursor: pointer;
        margin-left: 19px;
    }
}
</style>
