import { AdvertisementType, CampaignStatus } from './types';

export const CAMPAIGN_STATUS: Record<CampaignStatus, string> = {
    [CampaignStatus.ACTIVE]: 'Активна',
    [CampaignStatus.INACTIVE]: 'Не активна',
    [CampaignStatus.MODERATION]: 'На модерации',
    [CampaignStatus.REJECTED]: 'Отклонена',
    [CampaignStatus.BOOKING]: 'Забронировано'
};

export const ADVERTISEMENT_TYPE: Record<AdvertisementType, string> = {
    [AdvertisementType.AGENCY]: 'Агенство',
    [AdvertisementType.PROVIDER]: 'Поставщик',
    [AdvertisementType.SELF_ADVERTISEMENT]: 'Самореклама'
};
