<template>
    <div
        ref="controlEl"
        v-click-outside="onClose"
        v-loading.skeleton="!isOpen && loading"
        :class="[
            'control control--select',
            {
                'control--focused': isOpen,
                'control--has-value': hasValue,
                disabled: disabled
            }
        ]"
        role="listbox"
        @keydown.esc="onClose"
        @click="onToggle"
    >
        <div class="control__wrapper">
            <div class="control__placeholder" data-testid="label" role="label">{{ label }}</div>
            <div class="control__element" :title="title" data-testid="title">
                <!-- @slot строка выбранного слота -->
                <slot name="selected-label" :data="selected" :items="selections">
                    <span>{{ title }}</span>
                </slot>
            </div>
        </div>

        <div class="control__actions">
            <PButton
                v-if="canClear"
                class="control__clear-button"
                variant="text"
                icon="close"
                data-testid="clearButton"
                @click.stop.prevent="onClear"
            />

            <!-- @slot Значение после поля ввода, до иконки -->
            <slot name="append-after" :data="items" />

            <Icon class="control__icon" :name="isOpen ? 'chevron-up' : 'chevron-down'" />

            <!-- @slot Значение после поля ввода, после иконки -->
            <slot name="append" :data="items" />
        </div>

        <div v-show="isOpen" ref="dropdownEl" class="dropdown dropdown--max-content" data-testid="dropdown" @click.stop>
            <div v-if="search || tags" class="tags">
                <div ref="tagsEl" class="tags__inner">
                    <template v-if="tags">
                        <span
                            v-for="(item, index) in selections"
                            :key="`${index} + ${item.label}`"
                            class="tag"
                            :data-testid="`tag-item-${index}`"
                        >
                            <slot name="list-item" :item="item">
                                {{ item.label }}
                            </slot>
                            <PButton
                                variant="text"
                                icon-size="16"
                                icon="close"
                                class="tag__remove-btn"
                                @click.stop="removeItem(item)"
                            />
                        </span>
                    </template>
                </div>

                <input
                    v-if="search"
                    ref="searchEl"
                    v-model.trim="searchValue"
                    class="tags__input"
                    :placeholder="placeholder"
                    tabindex="-1"
                    data-testid="searchInput"
                    @input="updateSearchInput"
                    @click.stop
                />

                <div v-if="search" class="tags__indicators">
                    <Icon v-if="!loading" class="tags__search-icon" name="search" />
                    <div v-else class="tags__loading"></div>
                </div>
            </div>

            <div
                v-if="!loading && items.length && isOpen"
                ref="optionsEl"
                class="dropdown__list"
                data-testid="dropdown-list"
            >
                <slot name="beforeList"></slot>
                <label v-if="canSeeCheckedAllOption" class="dropdown__list-item checkbox" data-testid="selectAll">
                    <input v-model="isCheckedAll" class="checkbox" name="selected-option" type="checkbox" />
                    <span>Выбрать все</span>
                </label>
                <span
                    v-for="(item, index) in items"
                    :key="`${index}${item.label}`"
                    class="dropdown__list-item"
                    :class="{ checkbox: props.multiple, disabled: item.disabled, active: isChecked(item) }"
                    :data-testid="`option${index}`"
                    @click.stop="onSelect(item)"
                >
                    <input
                        v-if="props.multiple"
                        ref="checkbox"
                        type="checkbox"
                        name="checkbox-item"
                        :checked="isChecked(item)"
                        :disabled="item.disabled"
                    />
                    <span>
                        <slot name="list-item" :item="item">
                            {{ item.label }}
                        </slot>
                    </span>
                </span>
                <slot name="afterList"></slot>
            </div>

            <p v-if="loading" class="dropdown__empty-text" data-testid="dropdown-loading-text">
                {{ loading ? 'Загрузка данных' : '' }}
            </p>

            <slot v-if="items.length === 0 && !loading" name="empty">
                <p class="dropdown__empty-text" data-testid="dropdown-empty-text">Нет данных</p>
            </slot>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, reactive, ref, watch } from 'vue';
import Icon from '@/shared/ui/PIcon/PIcon.vue';
import PButton from '@/shared/ui/PButton/PButton.vue';
import { debounce, get as getValue } from 'lodash';
import { type Placement, usePopper } from '@/shared/lib/popper';

const popper = usePopper();

interface Props {
    /**
     * Модель данных
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    modelValue?: any;

    /**
     * Массив-данных для отображения в списке
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options?: any[];

    /**
     * Ключ объекта, по которому нужно фильтровать и отображать список, по умолчанию это поле `id`.
     * Значение можно указать через любую валидную вложенность выбранного ключа, например `info.dataset[0].name`
     *
     * @example
     * v-model="2"
     * valueKey="customId"
     * options=[{ customId: 1, name: 'some name' }, { customId: 2, name: 'some name' }]
     */
    valueKey?: string;

    /**
     * Возвращать в модель данных весь объект. Значение valueKey не учитывается
     */
    returnObject?: boolean;

    /**
     * Ключ по которому выводится текст для item-пунктов
     */
    labelKey?: string;

    /**
     * Ключ по которому определяются disabled состояния для item-пунктов
     */
    disabledKey?: string;

    /**
     * Описание заголовка над полем ввода
     */
    label: string;

    /**
     * Текст по умолчанию в input, когда нет данных
     */
    defaultTitle?: string;

    /**
     * Флаг для отображения множественного выбора. Обрабатывает модель данных в виде массива
     */
    multiple?: boolean;

    /**
     * Отображения кнопки 'Выбрать все' в dropdown-меню.
     * Доступно только для флага multiple
     */
    isCheckedAllOption?: boolean;

    /**
     * Флаг для блока в dropdown-меню с отображением всех выбранных позиций.
     * Доступно только для флага multiple
     */
    tags?: boolean;

    /**
     * Отображение кнопки для сброса значения
     */
    clearable?: boolean;

    /**
     * Отображение строки поиска. Работает совместно с @update:search-input
     */
    search?: boolean;

    /**
     * Значение, которое вызывается, когда обновляется строка поиска
     */
    searchInput?: string;

    /**
     * Задержка для debounce для ввода значения для метода поиска, по умолчанию 300ms
     */
    searchWait?: number;

    /**
     * Описание placeholder для строки поиска в dropdown-меню
     */
    placeholder?: string;

    /**
     * Флаг для блокировки блока
     */
    disabled?: boolean;

    /**
     * Флаг для отображения индикатора загрузки
     */
    loading?: boolean;

    /**
     * Позиция отображения контента для выпадающего списка
     */
    placement?: Placement;
}

const props = withDefaults(defineProps<Props>(), {
    modelValue: undefined,
    valueKey: 'id',
    labelKey: 'name',
    disabledKey: 'disabled',
    options: () => [],
    placeholder: 'Введите текст',
    placement: 'bottom-start',
    searchWait: 300,
    defaultTitle: undefined,
    searchInput: undefined,
    loading: false
});

const emit = defineEmits<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: 'update:modelValue', payload: any): void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: 'update:searchInput', payload: any): void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: 'add-item', payload: any): void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: 'remove-item', payload: any): void;
    (e: 'clear'): void;
    (e: 'open'): void;
    (e: 'close'): void;
    (e: 'checkedAll'): void;
    (e: 'uncheckedAll'): void;
}>();

const controlEl = ref<HTMLDivElement>();
const dropdownEl = ref<HTMLDivElement>();
const searchEl = ref<HTMLInputElement>();
const isOpen = ref(false);

/**
 * Для хранения всех найденных значений,
 * которые подгружаются в список при включенном флаге `search` и tags
 */
const optionsCache = reactive<Map<unknown, InternalItem>>(new Map());

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface InternalItem<T = any> {
    label: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
    disabled: boolean;
    raw: T;
}

const popperSetup = () => {
    const focusSearch = () => searchEl.value?.focus();
    popper.setup(controlEl.value, dropdownEl.value, {
        placement: props.placement,
        onFirstUpdate: focusSearch,
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 8]
                }
            },
            {
                name: 'onUpdate',
                enabled: true,
                phase: 'afterWrite',
                fn: focusSearch
            }
        ]
    });
};

const isChecked = (item: InternalItem): boolean => {
    return selected.value.some(s => s === item.value);
};

const transformItem = (item: unknown): InternalItem => {
    const label: string = getValue(item, props.labelKey, item) as string;
    const value: unknown = getValue(item, props.valueKey, item);
    const disabled: boolean = getValue(item, props.disabledKey) === true;

    return {
        label,
        value,
        disabled,
        raw: item
    };
};

const model = computed<InternalItem[]>({
    get() {
        if (props.modelValue === null || props.modelValue === undefined) {
            return [];
        }

        let items: unknown[] = [props.modelValue];
        if (props.multiple && Array.isArray(props.modelValue)) {
            items = props.modelValue;
        }

        return items.map(transformItem);
    },
    set(value) {
        const values = value.map(v => (props.returnObject ? v.raw : v.value));
        emit('update:modelValue', props.multiple ? values : values[0] ?? null);
    }
});

const title = computed<string>(() => {
    if (!selections.value.length) {
        return props.defaultTitle ?? '';
    }
    if (props.multiple) {
        if (selections.value.length === 1) {
            return selections.value[0].label;
        }
        return `Выбрано: ${selections.value.length}`;
    }

    return String(selections.value[0].label);
});

const hasValue = computed(() => title.value || model.value.length);

const _searchValue = ref(props.searchInput);
const searchValue = computed<string>({
    get() {
        return props.searchInput ?? _searchValue.value ?? '';
    },
    set(value: string) {
        _searchValue.value = value;
    }
});

const updateSearchInput = debounce((event: Event) => {
    const target = event.target as HTMLInputElement;
    emit('update:searchInput', target.value.trim());
}, props.searchWait);

const items = computed<InternalItem[]>(() => {
    return props.options.map(transformItem);
});

watch(
    items,
    items => {
        items.forEach(item => optionsCache.set(item.value, item));
    },
    {
        immediate: true
    }
);

watch(() => items.value.length, popperSetup);

const selections = computed(() => {
    return model.value.map(v => {
        return optionsCache.get(v.value) || v;
    });
});
const selected = computed(() => selections.value.map(selection => selection.value));

const canClear = computed<boolean>(() => {
    return !!props.clearable; // && !!model.value.length;
});

const onClear = () => {
    model.value = [];
    searchValue.value = '';
    emit('clear');
};

const addItem = (item: InternalItem) => {
    if (props.multiple) {
        model.value = model.value.concat([item]);
    } else {
        model.value = [item];
    }
    emit('add-item', item.raw);
};

const removeItem = (item: InternalItem) => {
    model.value = model.value.filter(v => v.value !== item.value);
    emit('remove-item', item.raw);
};

const onClose = () => {
    if (isOpen.value === false) {
        return;
    }

    isOpen.value = false;
    emit('close');
};

const onOpen = () => {
    isOpen.value = true;
    popperSetup();
    emit('open');
};

const onToggle = () => {
    isOpen.value ? onClose() : onOpen();
};

const onSelect = (item: InternalItem) => {
    if (props.disabled || item.disabled) {
        return;
    }
    const hasItem = model.value.some(v => {
        return v.value === item.value;
    });
    if (hasItem) {
        removeItem(item);
    } else {
        addItem(item);
    }
    if (!props.multiple) {
        onClose();
    }
};

const isCheckedAll = computed<boolean>({
    get() {
        return items.value.every(isChecked);
    },
    set(value: boolean) {
        if (!value) {
            model.value = [];
            emit('uncheckedAll');
            return;
        }
        model.value = items.value.filter(s => !s.disabled);
        emit('checkedAll');
    }
});

const canSeeCheckedAllOption = computed(() => {
    return props.isCheckedAllOption && props.multiple && !props.search;
});
</script>

<style lang="scss" scoped>
@import '@/shared/styles/mixin.scss';

.tags {
    position: relative;
    padding: 6px 6px 2px 16px;
    cursor: default;
    box-shadow: 0 14px 0 -13px var(--border-dropdown);
    margin-bottom: 1px;

    &__inner {
        padding-top: 6px;
        display: flex;
        flex-wrap: wrap;
        max-height: 180px;
        overflow-y: auto;
        overscroll-behavior: contain;
        @include scrollbar();

        &:empty {
            display: none;
        }
    }

    .tag {
        display: inline-flex;
        line-height: 1;
        align-items: center;
        font-size: var(--text-size-14);
        background-color: #edf2fc;
        color: #8f969e;
        border-radius: 0.25rem;
        user-select: none;
        padding: 0.4rem 0.8rem;
        margin-right: 0.5rem;
        margin-bottom: 0.8rem;
        width: max-content;

        &__remove-btn {
            margin-left: 0.4rem;
            margin-bottom: -0.2rem;
        }
    }

    &__input {
        appearance: none;
        font-size: var(--text-size-16);
        font-weight: 400;
        border: none;
        height: 24px;
        margin-bottom: 0.8rem;
        width: 100%;
        padding-right: 6rem;

        &:focus {
            outline: none;
        }

        &::placeholder {
            color: var(--text-color-light);
        }
    }

    &__indicators {
        position: absolute;
        right: 16px;
        bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__search-icon {
        color: var(--text-color-light);
        &:not(:focus-visible) {
            outline: none;
        }
    }

    &__loading {
        position: absolute;
        right: -5px;
        bottom: 5px;

        &::before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            border: 2px solid var(--text-color-light);
            border-top-color: transparent;
            animation: rotate 0.7s linear infinite;
            margin-right: 1rem;
        }
    }

    @keyframes rotate {
        to {
            transform: rotate(1turn);
        }
    }
}
</style>
