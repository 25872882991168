<template>
    <form @submit.prevent="onSubmit" @reset.prevent="onReset">
        <slot></slot>
    </form>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'PForm'
});
</script>

<script setup lang="ts">
import { ref } from 'vue';
import { useForm } from 'vee-validate';
import { isEmpty } from 'lodash';
import { useInitFormSubmitted } from '../model/composables';
const { setErrors, validate, setFieldError, meta } = useForm();

const emit = defineEmits<{
    (e: 'submit', event: SubmitEvent): void;
    (e: 'reset', event: SubmitEvent): void;
}>();

const isSubmitted = ref(false);

const onSubmit = async (event: Event) => {
    isSubmitted.value = true;
    emit('submit', event as SubmitEvent);
};

const onReset = async (event: Event) => {
    emit('reset', event as SubmitEvent);
};

useInitFormSubmitted(isSubmitted);

defineExpose({
    setErrors,
    setFieldError,
    meta,
    validate: async (): Promise<boolean> => {
        const response = await validate();
        isSubmitted.value = true;
        return isEmpty(response.errors);
    }
});
</script>
