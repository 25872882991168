import { type Placement, usePlacementBinding } from '@/entities/Placement';
import { BookingAgreementType, BookingDocumentStatus, BookingStatus } from './Booking';

export const getBookingStatusName = (status: BookingStatus): string => {
    switch (status) {
        case BookingStatus.CREATED:
            return 'Создано';
        case BookingStatus.CONTENT_REQUEST:
            return 'Запрос материалов';
        case BookingStatus.LAWYERS_REVIEW:
            return 'На согласовании';
        case BookingStatus.READY_FOR_START:
            return 'Готово к запуску';
        case BookingStatus.AD_WAS_STARTED:
            return 'РК создана';
        case BookingStatus.CANCELED:
            return 'Отменено';
        case BookingStatus.DELETED:
            return 'Удалено';
        default:
            return `Неизвестный статус ${status}`;
    }
};

export const getBookingAgreementTypeName = (type: BookingAgreementType): string => {
    switch (type) {
        case BookingAgreementType.ADVERTISING:
            return 'Реклама';
        case BookingAgreementType.INFO:
            return 'Информационный';
        case BookingAgreementType.CREDIT_NOTE:
            return 'Кредитная нота';
        default:
            return `Неизвестный тип ${type}`;
    }
};

export const getBookingDocumentStatusName = (status: BookingDocumentStatus) => {
    switch (status) {
        case BookingDocumentStatus.NEW:
            return 'Новое';
        case BookingDocumentStatus.ON_THE_SIGNATURE:
            return 'На подписи';
        case BookingDocumentStatus.ARCHIVE:
            return 'Архив';
        case BookingDocumentStatus.CANCELLED:
            return 'Отменён';
        case BookingDocumentStatus.AGREEMENT:
            return 'Согласование';
        case BookingDocumentStatus.NOT_REQUIRED:
            return 'Не требуется';
        case BookingDocumentStatus.CREDIT_NOTE:
            return 'Кредит нота';
        default:
            return `Неизвестный статус ${status}`;
    }
};
export const getPlacementBookingBinding = (placement: Placement) => {
    const binding = usePlacementBinding(placement);

    return {
        cpmWithoutBindings: binding.isCPMType.value && binding.isBindingDefaultType.value,
        cpmWithBindingsToCategories: binding.isCPMType.value && binding.isBindingCategoryType.value,
        byTimeWithBindingsToKeywords: binding.isByTimeType.value && binding.isBindingWordsType.value,
        byTimeWithBindingToCategories: binding.isByTimeType.value && binding.isBindingCategoryType.value
    };
};
