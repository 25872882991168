import type { Brand, dateISO } from '@/shared/model/types/Kernel';
import type { PromotionRequestId } from '@/entities/Promotion';

export type PromotionId = Brand<number, 'Promotion'>;

export enum DepartmentType {
    ONLS = 'ONLS',
    OZ = 'OZ'
}

export enum PromotionType {
    CATEGORY = 'CATEGORY',
    SPECIAL_DAY = 'SPECIAL_DAY',
    SEASON = 'SEASON',
    INDIVIDUAL = 'INDIVIDUAL',
    SALE = 'SALE'
}

export enum MechanicType {
    STRICT = 'STRICT'
}

export interface Promotion {
    id: PromotionId;
    name: string;
    activeCompanyRequestId: PromotionRequestId | null;
    startDate: dateISO;
    endDate: dateISO;
    requestStartDate: dateISO;
    requestEndDate: dateISO;
    type: PromotionType;
    department: DepartmentType;
    mechanic: MechanicType;
    recommendedCategoryNames: Array<string>;
    mainBanner: boolean;
    landing: boolean;
    seasonBanner: boolean;
    promoBanner: boolean;
    popup: boolean;
    email: boolean;
    push: boolean;
    smm: boolean;
    context: boolean;
    mediaAds: boolean;
    highlightDiscount: boolean;
    readonly createdAt: dateISO;
    readonly updatedAt: dateISO;
}
