import { ref, onMounted, onUnmounted, type Ref } from 'vue';

interface UseToggleOverflow {
    setBodyOverflow: (hidden: boolean) => void;
    isOverflowHidden: Readonly<Ref<boolean>>;
}

const CLASS_OVERFLOW_HIDDEN = 'overflow-hidden';

/**
 * Добавление/снятие класса `overflow-hidden` на body элементе, для блокирования scroll
 */
export function useBodyOverflow(): UseToggleOverflow {
    const isOverflowHidden = ref(false);

    const updateOverflow = (hidden: boolean) => {
        if (hidden) {
            document.body.classList.add(CLASS_OVERFLOW_HIDDEN);
        } else {
            document.body.classList.remove(CLASS_OVERFLOW_HIDDEN);
        }
        isOverflowHidden.value = hidden;
    };

    const setBodyOverflow = (hidden: boolean) => {
        updateOverflow(hidden);
    };

    onMounted(() => {
        if (document.body.classList.contains(CLASS_OVERFLOW_HIDDEN)) {
            updateOverflow(true);
        }
    });

    onUnmounted(() => {
        updateOverflow(false);
    });

    return { setBodyOverflow, isOverflowHidden };
}
