import { NetworkError } from './NetworkError';

export class NotFoundError extends NetworkError {
    readonly name: string = 'NotFoundError';

    constructor(
        message: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        readonly response?: any
    ) {
        super(message, response);
    }
}
