import { computed, type MaybeRefOrGetter, toValue } from 'vue';
import {
    type Placement,
    PlacementContentType,
    PlacementParamType,
    PlacementType
} from '@/entities/Placement/model/Placement';

export const usePlacementBinding = (
    placementData: MaybeRefOrGetter<Pick<Placement, 'paramType' | 'type' | 'contentType'> | undefined>
) => {
    const placement = computed(() => toValue(placementData));
    const paramType = computed(() => placement.value?.paramType);

    const isCPMType = computed(() => placement.value?.type === PlacementType.CPM);
    const isByTimeType = computed(() => placement.value?.type === PlacementType.BY_TIME);
    const isByExternalType = computed(() => placement.value?.type === PlacementType.EXTERNAL);

    const isBindingDefaultType = computed(() => paramType.value === PlacementParamType.DEFAULT);
    const isBindingCategoryType = computed(() => paramType.value === PlacementParamType.CATEGORY);
    const isBindingCarouselType = computed(() => paramType.value === PlacementParamType.CAROUSEL);
    const isBindingWordsType = computed(() => paramType.value === PlacementParamType.WORDS);
    const isBindingGUIDType = computed(() => paramType.value === PlacementParamType.GUID);
    const isBindingMNNType = computed(() => paramType.value === PlacementParamType.MNN);
    const isBindingSpecialEventsType = computed(() => paramType.value === PlacementParamType.SPECIAL_EVENTS);
    const isBindingBrand = computed(() => paramType.value === PlacementParamType.BRAND);
    const isBindingCategoryOrCVP = computed(() => paramType.value === PlacementParamType.CATEGORY_OR_CVP);

    const isBannerContentType = computed(() => placement.value?.contentType === PlacementContentType.BANNER);
    const isHTMLContentType = computed(() => placement.value?.contentType === PlacementContentType.HTML);

    return {
        isCPMType,
        isByTimeType,
        isByExternalType,
        isBindingDefaultType,
        isBindingCategoryType,
        isBindingCarouselType,
        isBindingWordsType,
        isBindingGUIDType,
        isBindingMNNType,
        isBindingSpecialEventsType,
        isBannerContentType,
        isHTMLContentType,
        isBindingBrand,
        isBindingCategoryOrCVP
    };
};
