import type { ColumnType } from '@/shared/ui/DataTable/DataTable.vue';
import type { B2BAsyncReport } from '@/entities/B2BAsyncReport';
import { convertDateCustom } from '@/utils';
import { dayjs } from '@/shared/lib/dayjs/dayjs';

export const ACTION_PROMO_ASYNC_REPORT_COLUMNS: ColumnType<keyof B2BAsyncReport>[] = [
    {
        label: 'Дата формирования',
        prop: 'insDate',
        format: date => dayjs(date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY в HH:mm:ss'),
        width: '30%'
    },
    {
        label: 'Период отчета',
        prop: 'insDate',
        width: '30%',
        render: ({ parameters }: { parameters: string }) => {
            const params = JSON.parse(parameters) as { start_date0: string; end_date0: string };

            const startDate = convertDateCustom(params.start_date0, 'DDMMYYYY', 'DD.MM.YYYY');
            const endDate = convertDateCustom(params.end_date0, 'DDMMYYYY', 'DD.MM.YYYY');

            if (startDate && endDate) {
                return `${startDate} - ${endDate}`;
            }

            if (startDate && !endDate) {
                return startDate;
            }

            return 'Не указано';
        }
    },
    {
        label: 'Статус',
        prop: 'status',
        slotCell: 'status'
    },
    {
        slotCell: 'action',
        width: '10%'
    }
];
