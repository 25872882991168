export enum ReportActionType {
    BONUS_PACKAGE = 1,
    SALE_IN_PRICE = 2,
    BONUS_PACKAGE_BY_RUBLE = 3
}

export enum ReportActionAsyncType {
    BONUS_PACKAGE = 'promo-action-info-rep35',
    SALE_IN_PRICE = 'promo-action-info-rep60',
    BONUS_PACKAGE_BY_RUBLE = 'promo-action-info-rep89'
}
