import { defineStore } from 'pinia';
import type { Medicine, MedicineId } from './Medicine';
import { getMedications, type MedicationsParams } from '../api/MedicineService';
import type { Pagination } from '@/shared/model/types/Pagination';

export interface MedicineStore {
    medications: Record<MedicineId, Medicine>;
}

const cache = new Map<string, { data: Medicine[]; pagination: Pagination }>();

export const useMedicineStore = defineStore('medicine', {
    state: () => ({ medications: {} }) as MedicineStore,

    getters: {
        medicationsArray: state => Object.values(state.medications) as Medicine[],
        getMedicine: state => (medicineCode: MedicineId) => state.medications[medicineCode]
    },

    actions: {
        async fetchMedications(
            params: MedicationsParams,
            abortController?: AbortController
        ): Promise<{ data: Medicine[]; pagination: Pagination }> {
            const cacheKey = JSON.stringify(params ?? 'default');

            const cachedData = cache.get(cacheKey);

            if (cachedData) {
                return cachedData;
            }

            const response = await getMedications(params, abortController);

            for (const medicine of response.data) {
                this.medications[medicine.medicineCode] = medicine;
            }

            cache.set(cacheKey, response);

            return response;
        },
        async fetchMedicationsByMedicineCodesCached(
            medicineCodes: MedicineId[],
            abortController?: AbortController
        ): Promise<Medicine[]> {
            const oldIdsSet = new Set(Object.keys(this.medications).map(Number));
            let newIds = medicineCodes.filter(medicineCode => !oldIdsSet.has(medicineCode));

            if (newIds.length) {
                /*
                    протек может вернуть по одному и тому же medicineCode одинаковый товар несколько раз,
                    если товар привязан к нескольким поставщикам, поэтому если за раз все не получилось
                    подгрузить, то делаем ещё 3 попытки
                 */
                let attempts = 3;
                // пытаемся сразу сделать запрос с запасом, чтобы все товары получить
                const itemsPerPage = Math.max(newIds.length, 100);

                while (newIds.length && attempts > 0) {
                    try {
                        const response = await this.fetchMedications(
                            {
                                medicineCodes: newIds,
                                pagination: { itemsPerPage, currentPage: 1 }
                            },
                            abortController
                        );
                        newIds = newIds.filter(id => !response.data.some(m => m.medicineCode === id));
                    } finally {
                        attempts--;
                    }
                }
            }

            const medicineCodesSet = new Set(medicineCodes);
            return this.medicationsArray.filter((m: Medicine) => medicineCodesSet.has(m.medicineCode));
        },

        async updateMedicationsByMedicineCodesCached(medicines: Medicine[]) {
            for (const medicine of medicines) {
                this.medications[medicine.medicineCode] = medicine;
            }
        }
    }
});
