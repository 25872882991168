<template>
    <header class="header-page">
        <div class="header-page__container">
            <h1 class="header-page__title">
                {{ pageTitle }}
            </h1>
            <ul class="toolbar header-page__toolbar">
                <li class="toolbar__item">
                    <a
                        class="toolbar__action"
                        target="_blank"
                        href="https://help-partners-protek.notion.site/470a94d120c5491082558d19aca38268"
                    >
                        <Icon class="toolbar__icon" name="help-circle-outline" size="26" />
                        <span>Помощь</span>
                    </a>
                </li>
                <li class="toolbar__item">
                    <!-- .toolbar-dropdown-->
                    <div v-click-outside="closeCompanyProfile" class="toolbar-dropdown">
                        <button
                            class="toolbar-dropdown__info"
                            :class="{
                                'toolbar-dropdown__info--no-active': !visibleCompaniesList
                            }"
                            type="button"
                            @click="toggleCompanyProfile"
                        >
                            <span class="toolbar-dropdown__info-inner">
                                <Icon name="settings-future" />
                                <span :title="activeCompanyView.name">
                                    [{{ activeCompanyView.wabcCode }}]
                                    {{ activeCompanyView.name }}
                                </span>
                                <Icon
                                    v-if="visibleCompaniesList"
                                    :name="isOpenCompanyProfile ? 'chevron-up' : 'chevron-down'"
                                />
                            </span>
                        </button>

                        <ul
                            v-if="visibleCompaniesList"
                            :class="[
                                'toolbar-dropdown__list',
                                'toolbar-dropdown__list--full',
                                { 'toolbar-dropdown__list--open': isOpenCompanyProfile }
                            ]"
                        >
                            <li v-for="company in companiesViewList" :key="company.id" class="toolbar-dropdown__item">
                                <div class="toolbar-dropdown__link" @click="switchToActiveCampaign(company.id)">
                                    <Icon name="label" />
                                    <span :title="company.name">
                                        [{{ company.wabcCode }}]
                                        {{ company.name }}
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="toolbar__item">
                    <!-- .toolbar-dropdown-->
                    <div v-click-outside="closeUserProfile" class="toolbar-dropdown">
                        <button class="toolbar-dropdown__info" type="button" @click="toggleUserProfile">
                            <span class="toolbar-dropdown__info-inner">
                                <Icon name="profile" />
                                <span data-testid="headerToolbarUserFullName">{{ userFullName }}</span>
                                <Icon :name="isOpenUserProfile ? 'chevron-up' : 'chevron-down'" />
                            </span>
                        </button>
                        <ul :class="['toolbar-dropdown__list', { 'toolbar-dropdown__list--open': isOpenUserProfile }]">
                            <li
                                v-if="!isCompanyActive && isRegistrationCurrentCompanyStage"
                                class="toolbar-dropdown__item"
                            >
                                <router-link :to="{ name: 'company-profile' }" class="toolbar-dropdown__link">
                                    <Icon name="outline" />
                                    <span>Регистрация компании</span>
                                </router-link>
                            </li>
                            <li class="toolbar-dropdown__item">
                                <router-link :to="{ name: 'profile' }" class="toolbar-dropdown__link">
                                    <Icon name="profile" />
                                    <span>Профиль</span>
                                </router-link>
                            </li>
                            <li v-if="canOpenSettings" class="toolbar-dropdown__item">
                                <router-link :to="{ name: 'settings' }" class="toolbar-dropdown__link">
                                    <Icon name="settings" />
                                    <span>Настройки</span>
                                </router-link>
                            </li>
                            <li v-if="!userStore.isActiveImpersonateUser" class="toolbar-dropdown__item">
                                <a href="javascript: void 0;" class="toolbar-dropdown__link" @click="showLogoutModal">
                                    <Icon name="logout" class="ml-1" />
                                    <span>Выход</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>

        <PModal v-model="isOpenLogoutModal" width="360">
            <LogoutModal />
        </PModal>
    </header>
</template>

<script lang="ts">
import { PIcon, PModal } from '@/shared/ui';
import LogoutModal from '@/layout/LogoutModal.vue';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'PHeader',

    components: {
        Icon: PIcon,
        LogoutModal,
        PModal
    }
});
</script>

<script lang="ts" setup>
import { CompanyRegistrationStage, CompanyStatus } from '@/models';
import { notifyError } from '@/shared/model/utils/showNotify';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useUserStore } from '@/shared/model/store/UserStore';
import type { Company, CompanyId } from '@/shared/model/types/Company';
import { PermissionDictionary } from '@/shared/model/types/Permission';
import { useAccessStore } from '@/shared/model/store/AccessStore';
import type { UserId } from '@/shared/model/types/User';
import { switchActiveProfileToImpersonateProfile, switchUserToActiveCompany } from '@/processes/Auth';

const route = useRoute();
const router = useRouter();
const accessStore = useAccessStore();

const isOpenUserProfile = ref(false);
const isOpenCompanyProfile = ref(false);
const isOpenLogoutModal = ref(false);

const userStore = useUserStore();
const companiesViewList = computed(() => {
    return userStore.companies.filter(company => company.id !== userStore.company?.id);
});

const activeCompanyView = computed(() => {
    return (userStore.company as Company) ?? {};
});

const visibleCompaniesList = computed(() => {
    // TODO: CAB-4655 для отладки логики отображения списка компаний со стороны бэкенда
    return companiesViewList.value.length > 0; //  && !userStore.isActiveImpersonateUser;
});

const userFullName = computed(() => userStore.userProfile?.fullName ?? 'нет данных');

const pageTitle = computed(() => route.matched[0]?.meta?.title);

const checkPermission = accessStore.checkPermission;
const canOpenSettings = computed(() => checkPermission(PermissionDictionary.OPTIONS_SECTION));

const companyStatus = computed(() => userStore.company?.status);
const isRegistrationCurrentCompanyStage = computed(() => {
    if (!userStore.company) {
        return false;
    }
    const stage: (CompanyRegistrationStage | null)[] = [
        CompanyRegistrationStage.PRE_REGISTRATION,
        CompanyRegistrationStage.COMMON,
        CompanyRegistrationStage.BANK_ACCOUNTS,
        CompanyRegistrationStage.FORM,
        CompanyRegistrationStage.DOCUMENTS,
        CompanyRegistrationStage.CONTRACT
    ];
    return stage.includes(userStore.company.registrationStage);
});
const isCompanyActive = computed<boolean>(() => companyStatus.value === CompanyStatus.ACTIVE);

const toggleUserProfile = () => {
    isOpenUserProfile.value = !isOpenUserProfile.value;
};

const toggleCompanyProfile = () => {
    isOpenCompanyProfile.value = !isOpenUserProfile.value;
};

const closeUserProfile = () => {
    isOpenUserProfile.value = false;
};

const closeCompanyProfile = () => {
    isOpenCompanyProfile.value = false;
};

const showLogoutModal = () => {
    isOpenUserProfile.value = false;
    isOpenLogoutModal.value = true;
};

const switchToActiveCampaign = async (companyId: CompanyId) => {
    try {
        isOpenCompanyProfile.value = false;

        if (userStore.isActiveImpersonateUser) {
            const email = userStore.user?.email as string;
            await switchActiveProfileToImpersonateProfile({ email, companyId });
        } else {
            const userId = userStore.user?.id as UserId;
            await switchUserToActiveCompany({ userId, companyId });
        }

        await router.replace({ name: 'profile' });
    } catch (e) {
        notifyError('Произошла ошибка при смене активной компании');
    } finally {
        closeCompanyProfile();
    }
};
</script>

<style scoped lang="scss">
.header-page {
    background-color: var(--white);
    min-height: var(--header-height);
    box-shadow: var(--shadow-header);

    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        min-height: var(--header-height);
        background-color: var(--white);
        pointer-events: none;
    }

    &__container {
        padding: 0 5.6rem;
        display: grid;
        grid-template-columns: 1fr max-content;
        align-items: center;
    }

    &__title {
        font-size: var(--text-size-32);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.toolbar {
    display: flex;
    align-items: center;

    &__item {
        border-left: 1px solid var(--border-light);
        &:last-child {
            border-right: 1px solid var(--border-light);
        }
    }

    &__action {
        display: flex;
        align-items: center;
        min-height: var(--header-height);
        padding: 0 2.6rem;
        color: var(--text-color);
        cursor: pointer;

        &:hover,
        &:focus {
            outline: none;
            color: var(--primary-lighten);
        }
    }

    &__icon {
        margin-right: 5px;
    }
}

.badge {
    color: var(--text-color-light);
}

.toolbar-dropdown {
    position: relative;
    &__info {
        position: relative;
        z-index: 2;
        border: none;
        min-width: 260px;
        background-color: transparent;

        min-height: var(--header-height);
        padding: 0 2.6rem;
        color: var(--text-color);
        cursor: pointer;

        font-size: var(--text-size-14);

        &-inner {
            display: flex;
            align-items: center;
            justify-content: space-between;

            > span {
                text-overflow: ellipsis;
                text-align: left;
                overflow: hidden;
                white-space: nowrap;
                display: inline-block;
                max-width: 200px;
                flex: 1;
                margin: 0 10px;
            }
        }

        &:hover:not(&--no-active),
        &:focus:not(&--no-active) {
            outline: none;
            color: var(--primary-lighten);
        }

        &--no-active {
            cursor: default;
        }
    }

    &__list {
        position: absolute;
        top: calc(100% - 1px);
        left: 0;
        z-index: var(--z-index-toolbar-dropdown);
        width: 100%;
        background-color: var(--white);
        border: 1px solid var(--border-light);
        border-top: none;
        box-shadow: var(--shadow-header-toolbar);
        padding: 0.8rem 0 2.4rem;
        border-radius: 0 0 var(--border-radius-8) var(--border-radius-8);

        grid-gap: 2.4rem;
        display: none;

        &--open {
            display: grid;
            animation: 0.2s slide-down ease-in-out backwards;
        }

        &--full {
            width: max-content;
            max-width: 400px;
            min-width: 100%;
        }

        @keyframes slide-down {
            from {
                opacity: 0;
                transform: translateY(-10px);
            }

            to {
                opacity: 1;
                transform: translate(0);
            }
        }
    }

    &__link {
        display: grid;
        align-items: center;
        grid-gap: 1rem;
        grid-template-columns: 24px 1fr;
        padding: 0 2.6rem;
        color: var(--text-color);
        cursor: pointer;

        &:hover,
        &:focus {
            outline: none;
            color: var(--primary-lighten);
        }
    }
}
</style>
