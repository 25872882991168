<template>
    <div class="page-sizes">
        <span>Показать</span>

        <span>
            <PSelect
                :model-value="pageSize"
                label="Пунктов"
                :options="options"
                :disabled="disabled"
                @update:model-value="onChangePageSize"
            />
        </span>

        <span> из {{ numberFormatDigits(totalItems) }} </span>
    </div>
</template>

<script lang="ts">
import PSelect from '../PSelect/PSelect.vue';
import { defineComponent } from 'vue';
import { numberFormatDigits } from '@/utils/convertData';

export default defineComponent({
    name: 'PageSizes',

    components: {
        PSelect
    }
});
</script>

<script lang="ts" setup>
import { computed } from 'vue';

interface Props {
    pageSize?: number;
    totalItems?: number;
    pageSizes?: number[];
    disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    pageSize: 10,
    totalItems: 0,
    pageSizes: () => [10, 25, 50, 75, 100],
    disabled: false
});

const emit = defineEmits<{
    (e: 'change', payload: number): void;
}>();

const options = computed(() => {
    return props.pageSizes.map(size => ({
        id: size,
        name: size
    }));
});

const onChangePageSize = (id: number) => {
    emit('change', id);
};
</script>

<style scoped>
.page-sizes {
    display: inline-grid;
    grid-template-columns: 7rem 11rem 1fr;
    grid-gap: 1rem;
    align-items: center;
    color: var(--text-color-light);
}
</style>
