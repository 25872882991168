import client from '@/shared/api/client';
import type { Pagination, ServerPagination } from '@/shared/model/types/Pagination';
import type { Keyword, KeywordId } from '../model/Keyword';

export interface KeywordsParams {
    search?: string;
    ids?: KeywordId[];
    pagination?: Partial<ServerPagination>;
}

export const getKeywords = async (params: KeywordsParams, abortController = new AbortController()) => {
    const { data } = await client.get<{ data: Keyword[]; meta: Pagination }>('/api/handbooks/search-words/with-id', {
        params: {
            ids: params.ids,
            search: params.search,
            page: params.pagination?.currentPage ?? 1,
            limit: params.pagination?.itemsPerPage ?? 15
        },
        signal: abortController.signal
    });

    return {
        data: data.data,
        pagination: data.meta
    };
};
