<template>
    <div
        :class="[
            'control',
            {
                'control--focused': focused,
                'control--has-value': hasValue,
                'control--is-ghost': isGhost,
                'control--readonly': readonly,
                disabled: disabled
            }
        ]"
        role="group"
        aria-label="control-input"
        @click="setInputFocus"
    >
        <div class="control__wrapper">
            <div class="control__placeholder">
                <slot name="label">{{ label }}</slot>
            </div>
            <input
                ref="inputElement"
                class="control__element"
                autocapitalize="none"
                spellcheck="false"
                :type="getType"
                :autocomplete="autocomplete"
                :placeholder="placeholder"
                :disabled="disabled"
                :readonly="readonly"
                :tabindex="hasTabindex"
                role="textbox"
                @focus="onFocus"
                @blur="onBlur"
            />
        </div>
        <div class="control__actions">
            <slot name="append" />
            <PButton
                v-if="visibleClearableButton"
                class="control__clear-button"
                variant="text"
                icon="close"
                @click.stop.prevent="clearValue"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import PButton from '@/shared/ui/PButton/PButton.vue';
import { type MaskOptions, useInputMask } from '@/shared/model/composables/useInputMask';

interface Props {
    label: string;
    type?: 'text' | 'number' | 'date' | 'password';
    placeholder?: string;
    isGhost?: boolean;
    clearable?: boolean;
    autocomplete?: string;
    readonly?: boolean;
    disabled?: boolean;
    tabindex?: number;
    maskOptions?: MaskOptions;
}

const props = withDefaults(defineProps<Props>(), {
    type: 'text',
    placeholder: '',
    autocomplete: 'off',
    tabindex: 0,
    maskOptions: undefined
});

const emit = defineEmits<{
    (e: 'clear'): void;
    (e: 'focus', event: FocusEvent): void;
    (e: 'blur', event: FocusEvent): void;
}>();

defineSlots<{
    label: () => void;
    append: () => void;
}>();

const modelValue = defineModel<string | number | null>('modelValue', { local: true });
const { inputElement } = useInputMask(modelValue, props.type, props.maskOptions);
const focused = ref(false);

const isClickable = computed(() => !props.disabled && !props.readonly);
const hasTabindex = computed(() => (isClickable.value ? props.tabindex : -1));
const hasValue = computed(() => [undefined, null, ''].every(value => modelValue.value !== value) || props.placeholder);
const visibleClearableButton = computed(() => props.clearable && !props.readonly && modelValue.value);

const getType = computed(() => {
    if (props.type === 'password') {
        return 'password';
    }

    return 'text';
});

const clearValue = () => {
    modelValue.value = undefined;
    emit('clear');
};

const setInputFocus = () => {
    if (!isClickable.value) return;
    (inputElement.value as HTMLInputElement)?.focus();
};

const onFocus = (event: FocusEvent) => {
    if (!isClickable.value) return;
    focused.value = true;
    emit('focus', event);
};

const onBlur = (event: FocusEvent) => {
    focused.value = false;
    emit('blur', event);
};
</script>
