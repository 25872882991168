import type { CompanyContract } from '@/shared/model/types/CompanyContract';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toClient = (data: any): CompanyContract => {
    return {
        id: data.id,
        ...data.attributes
    } as CompanyContract;
};

export const toServer = (data: Partial<CompanyContract>) => {
    const response = { ...data };

    // у человека мб не быть отчества, но бек не пропускает тут null
    if (typeof response.lastName !== 'string') {
        response.lastName = '';
    }

    return response;
};
