import { fileSaveAs, generateUUID } from '@/utils';
import { NetworkError } from '@/errors/NetworkError';
import type { Pagination, ServerPagination } from '@/shared/model/types/Pagination';
import {
    ADJUSTMENT_GROUP_BY_CHART,
    ADJUSTMENT_GROUP_BY_DETAIL_CHART,
    ADJUSTMENT_GROUP_BY_DETAIL_TABLE,
    ADJUSTMENT_GROUP_BY_TABLE,
    ADJUSTMENT_METRICS_BY_CHART,
    ADJUSTMENT_METRICS_BY_DETAIL_CHART,
    ADJUSTMENT_METRICS_BY_DETAIL_TABLE,
    ADJUSTMENT_METRICS_BY_TABLE,
    type ChartAdjustedByDetail,
    type ChartAdjustedByTable,
    type ChartRawByDetail,
    type ChartRawByTable,
    GROUP_BY_CHART,
    GROUP_BY_DETAIL_CHART,
    GROUP_BY_DETAIL_TABLE,
    GROUP_BY_TABLE,
    METRICS_BY_CHART,
    METRICS_BY_TABLE,
    REPORT_EXCEL_DETAIL_GROUP_BY_CHART,
    REPORT_EXCEL_DETAIL_GROUP_BY_TABLE,
    REPORT_EXCEL_DETAIL_METRICS_BY_CHART,
    REPORT_EXCEL_DETAIL_METRICS_BY_TABLE,
    REPORT_EXCEL_GROUP_BY_CHART,
    REPORT_EXCEL_GROUP_BY_TABLE,
    REPORT_EXCEL_METRICS_BY_CHART,
    REPORT_EXCEL_METRICS_BY_TABLE,
    type ReportAdjustedByDetail,
    type ReportAdjustedByTable,
    type ReportRawByDetail,
    type ReportRawByTable
} from '../model/CampaignReport';
import type { ISchema, ObjectSchema } from 'yup';
import * as yup from 'yup';
import { getJSONRPCReportSchema, JSONRPCSchema } from '@/shared/api/schemas/JSONRPCSchema';
import sentry from '@/shared/lib/sentry/sentry';
import { logger } from '@/shared/model/utils';
import client from '@/shared/api/client';
import { dayjs } from '@/utils/dayjs';
import type { CompanyAdvId } from '@/shared/model/types/Company';

interface CampaignReportParams extends Partial<ServerPagination> {
    startDate: string;
    endDate: string;
    placementAdId?: number;
    advertiserIds?: number[];
    campaignAdIds: number[];
}

const ChartRawByTableSchema: ObjectSchema<ChartRawByTable> = yup.object().shape({
    campaignId: yup.number<CompanyAdvId>().required(),
    clicks: yup.number().required(),
    date: yup.string().required(),
    imps: yup.number().required(),
    ctr: yup.number().required(),
    payoutRub: yup.number().required(),
    revenueRub: yup.number().required()
});

const ChartRawByDetailSchema: ObjectSchema<ChartRawByDetail> = yup.object().shape({
    campaignId: yup.number<CompanyAdvId>().required(),
    clicks: yup.number().required(),
    date: yup.string().required(),
    imps: yup.number().required(),
    ctr: yup.number().required(),
    payoutRub: yup.number().required(),
    revenueRub: yup.number().required(),
    paramId: yup.string().required()
});

const ReportRawByTableSchema: ObjectSchema<ReportRawByTable> = yup.object().shape({
    campaignId: yup.number<CompanyAdvId>().required(),
    clicks: yup.number().required(),
    imps: yup.number().required(),
    ctr: yup.number().required(),
    payoutRub: yup.number().required(),
    revenueRub: yup.number().required()
});

const ReportRawByDetailSchema: ObjectSchema<ReportRawByDetail> = yup.object().shape({
    campaignId: yup.number<CompanyAdvId>().required(),
    clicks: yup.number().required(),
    imps: yup.number().required(),
    ctr: yup.number().required(),
    payoutRub: yup.number().required(),
    revenueRub: yup.number().required(),
    paramId: yup.string().required()
});

interface ChartRawByTableParams extends CampaignReportParams {
    metrics: typeof METRICS_BY_CHART;
    groupBy: typeof GROUP_BY_CHART;
}
interface ChartRawByDetailParams extends CampaignReportParams {
    metrics: typeof METRICS_BY_CHART;
    groupBy: typeof GROUP_BY_DETAIL_CHART;
}

interface ReportRawByTableParams extends CampaignReportParams {
    metrics: typeof METRICS_BY_TABLE;
    groupBy: typeof GROUP_BY_TABLE;
}

interface ReportRawByDetailParams extends CampaignReportParams {
    metrics: typeof METRICS_BY_TABLE;
    groupBy: typeof GROUP_BY_DETAIL_TABLE;
}

export function getCampaignsStatisticsReport(
    params: ChartRawByTableParams,
    abortController?: AbortController
): Promise<{ data: ChartRawByTable[]; pagination: Pagination }>;
export function getCampaignsStatisticsReport(
    params: ChartRawByDetailParams,
    abortController?: AbortController
): Promise<{ data: ChartRawByDetail[]; pagination: Pagination }>;
export function getCampaignsStatisticsReport(
    params: ReportRawByTableParams,
    abortController?: AbortController
): Promise<{ data: ReportRawByTable[]; pagination: Pagination }>;
export function getCampaignsStatisticsReport(
    params: ReportRawByDetailParams,
    abortController?: AbortController
): Promise<{ data: ReportRawByDetail[]; pagination: Pagination }>;
export async function getCampaignsStatisticsReport(
    params: ChartRawByTableParams | ChartRawByDetailParams | ReportRawByTableParams | ReportRawByDetailParams,
    abortController = new AbortController()
) {
    const _params: Record<string, unknown> = {
        startDate: params.startDate,
        endDate: params.endDate,
        metrics: params.metrics,
        groupBy: params.groupBy
    };
    if (params.placementAdId) {
        _params.placementId = String(params.placementAdId);
    }
    if (Array.isArray(params.advertiserIds) && params.advertiserIds.length > 0) {
        _params.advertiserId = String(params.advertiserIds);
    }
    if (Array.isArray(params.campaignAdIds) && params.campaignAdIds.length > 0) {
        _params.campaignId = String(params.campaignAdIds);
    }
    if (params.itemsPerPage) {
        _params.size = params.itemsPerPage;
    }
    if (params.currentPage) {
        _params.page = params.currentPage - 1; // adserver начинает отчёт с 0 страницы
    }

    const payload = {
        jsonrpc: '2.0',
        method: 'advertising.getCampaignsStatisticsReport',
        id: generateUUID(),
        params: _params
    };

    const response = await client.post('/rpc/advertising', payload, { signal: abortController.signal });

    if (response.data.result?.status === 'error') {
        throw new NetworkError(response.data.result.message, response.data.result);
    }

    let reportSchema;

    if (params.groupBy === GROUP_BY_CHART && params.metrics === METRICS_BY_CHART) {
        reportSchema = getJSONRPCReportSchema(ChartRawByTableSchema);
    }

    if (params.groupBy === GROUP_BY_DETAIL_CHART && params.metrics === METRICS_BY_CHART) {
        reportSchema = getJSONRPCReportSchema(ChartRawByDetailSchema);
    }

    if (params.groupBy === GROUP_BY_TABLE && params.metrics === METRICS_BY_TABLE) {
        reportSchema = getJSONRPCReportSchema(ReportRawByTableSchema);
    }

    if (params.groupBy === GROUP_BY_DETAIL_TABLE && params.metrics === METRICS_BY_TABLE) {
        reportSchema = getJSONRPCReportSchema(ReportRawByDetailSchema);
    }

    if (!reportSchema) {
        throw new Error('Не найдена подходящая схема для валидации ответа');
    }

    await reportSchema.validate(response.data).catch(error => {
        const message = 'Не валидный ответ JSON RPC advertising.getCampaignsStatisticsReport';
        sentry.captureMessage(message);
        sentry.captureException(error);
        logger.warn(message);
        logger.error(error);
    });

    const data = reportSchema.cast(response.data, {
        stripUnknown: true,
        assert: false
    });

    return {
        data: data.result.data,
        pagination: {
            currentPage: params.currentPage || 1,
            itemsPerPage: params.itemsPerPage,
            totalItems: data.result.totalCount
        }
    };
}

interface CampaignAdjustedReportParams extends Partial<ServerPagination> {
    advertiserIds?: number[];
    placementAdId?: number;
    campaignAdIds?: number[];
    campaignAdId?: number;
    startDate: string;
    endDate: string;
}

interface ChartByTableParams extends CampaignAdjustedReportParams {
    metrics: typeof ADJUSTMENT_METRICS_BY_CHART;
    groupBy: typeof ADJUSTMENT_GROUP_BY_CHART;
}
interface ChartByDetailParams extends CampaignAdjustedReportParams {
    metrics: typeof ADJUSTMENT_METRICS_BY_DETAIL_CHART;
    groupBy: typeof ADJUSTMENT_GROUP_BY_DETAIL_CHART;
}

interface ReportByTableParams extends CampaignAdjustedReportParams {
    metrics: typeof ADJUSTMENT_METRICS_BY_TABLE;
    groupBy: typeof ADJUSTMENT_GROUP_BY_TABLE;
}

interface ReportByDetailParams extends CampaignAdjustedReportParams {
    metrics: typeof ADJUSTMENT_METRICS_BY_DETAIL_TABLE;
    groupBy: typeof ADJUSTMENT_GROUP_BY_DETAIL_TABLE;
}

const ChartAdjustedByTableSchema: ObjectSchema<ChartAdjustedByTable> = yup
    .object()
    .transform(({ campaignAdId, ...value }) => {
        return {
            ...value,
            campaignId: campaignAdId
        };
    })
    .shape({
        campaignId: yup.number<CompanyAdvId>().required(),
        clicks: yup.number().required(),
        imps: yup.number().required(),
        ctr: yup.number().required(),
        date: yup.string().required()
    });

const ChartAdjustedByDetailSchema: ObjectSchema<ChartAdjustedByDetail> = yup
    .object()
    .transform(({ campaignAdId, ...value }) => {
        return {
            ...value,
            campaignId: campaignAdId
        };
    })
    .shape({
        campaignId: yup.number<CompanyAdvId>().required(),
        clicks: yup.number().required(),
        imps: yup.number().required(),
        ctr: yup.number().required(),
        date: yup.string().required(),
        paramId: yup.string().required()
    });

const ReportAdjustedByTableSchema: ObjectSchema<ReportAdjustedByTable> = yup
    .object()
    .transform(({ campaignAdId, ...value }) => {
        return {
            ...value,
            campaignId: campaignAdId
        };
    })
    .shape({
        campaignId: yup.number<CompanyAdvId>().required(),
        clicks: yup.number().required(),
        imps: yup.number().required(),
        ctr: yup.number().required(),
        revenueRub: yup.number().required(),
        placementAdId: yup.number().required()
    });

const ReportAdjustedByDetailSchema: ObjectSchema<ReportAdjustedByDetail> = yup
    .object()
    .transform(({ campaignAdId, ...value }) => {
        return {
            ...value,
            campaignId: campaignAdId
        };
    })
    .shape({
        campaignId: yup.number<CompanyAdvId>().required(),
        clicks: yup.number().required(),
        imps: yup.number().required(),
        ctr: yup.number().required(),
        revenueRub: yup.number().required(),
        placementAdId: yup.number().required(),
        paramId: yup.string().required()
    });

export function getAdvertisingCampaignsAdjustedStatistics(
    params: ChartByTableParams,
    abortController?: AbortController
): Promise<{ data: ChartAdjustedByTable[]; pagination: Pagination }>;
export function getAdvertisingCampaignsAdjustedStatistics(
    params: ChartByDetailParams,
    abortController?: AbortController
): Promise<{ data: ChartAdjustedByDetail[]; pagination: Pagination }>;
export function getAdvertisingCampaignsAdjustedStatistics(
    params: ReportByTableParams,
    abortController?: AbortController
): Promise<{ data: ReportAdjustedByTable[]; pagination: Pagination }>;
export function getAdvertisingCampaignsAdjustedStatistics(
    params: ReportByDetailParams,
    abortController?: AbortController
): Promise<{ data: ReportAdjustedByDetail[]; pagination: Pagination }>;
export async function getAdvertisingCampaignsAdjustedStatistics(
    params: ChartByTableParams | ChartByDetailParams | ReportByTableParams | ReportByDetailParams,
    abortController = new AbortController()
) {
    const getSchema = (dataSchema: ISchema<unknown>) => {
        return JSONRPCSchema.shape({
            result: yup
                .object()
                .transform(value => {
                    return {
                        ...value,
                        data: Array.isArray(value.data) ? value.data : []
                    };
                })
                .shape({
                    data: yup.array().of(dataSchema).default([]),
                    meta: yup.object({
                        currentPage: yup.number().required(),
                        itemCount: yup.number().required(),
                        itemsPerPage: yup.number().required(),
                        totalItems: yup.number().required(),
                        totalPages: yup.number().required()
                    })
                })
        });
    };

    const _params: Record<string, unknown> = {
        startDate: params.startDate,
        endDate: params.endDate,
        metrics: params.metrics,
        groupBy: params.groupBy
    };
    if (params.advertiserIds) {
        _params.advertiserId = params.advertiserIds;
    }
    if (params.placementAdId) {
        _params.placementAdId = params.placementAdId;
    }
    if (Array.isArray(params.campaignAdIds) && params.campaignAdIds.length > 0) {
        _params.campaignAdId = params.campaignAdIds;
    }
    if (params.campaignAdId) {
        _params.campaignAdId = params.campaignAdId;
    }
    if (params.itemsPerPage) {
        _params.itemsPerPage = params.itemsPerPage;
    }
    if (params.currentPage) {
        _params.currentPage = params.currentPage;
    }

    const payload = {
        jsonrpc: '2.0',
        method: 'advertising.getAdvertisingCampaignsStatistics',
        id: generateUUID(),
        params: _params
    };

    const response = await client.post('/rpc/advertising', payload, { signal: abortController.signal });

    if (response.data.result?.status === 'error') {
        throw new NetworkError(response.data.result.message, response.data.result);
    }

    let reportSchema;

    if (params.groupBy === ADJUSTMENT_GROUP_BY_CHART && params.metrics === ADJUSTMENT_METRICS_BY_CHART) {
        reportSchema = getSchema(ChartAdjustedByTableSchema);
    }

    if (params.groupBy === ADJUSTMENT_GROUP_BY_TABLE && params.metrics === ADJUSTMENT_METRICS_BY_TABLE) {
        reportSchema = getSchema(ReportAdjustedByTableSchema);
    }

    if (params.groupBy === ADJUSTMENT_GROUP_BY_DETAIL_CHART && params.metrics === ADJUSTMENT_METRICS_BY_DETAIL_CHART) {
        reportSchema = getSchema(ChartAdjustedByDetailSchema);
    }

    if (params.groupBy === ADJUSTMENT_GROUP_BY_DETAIL_TABLE && params.metrics === ADJUSTMENT_METRICS_BY_DETAIL_TABLE) {
        reportSchema = getSchema(ReportAdjustedByDetailSchema);
    }

    if (!reportSchema) {
        throw new Error('Не найдена подходящая схема для валидации ответа');
    }

    await reportSchema.validate(response.data).catch(error => {
        const message = 'Не валидный ответ JSON RPC advertising.getAdvertisingCampaignsAdjustedStatistics';
        sentry.captureMessage(message);
        sentry.captureException(error);
        logger.warn(message);
        logger.error(error);
    });

    const data = reportSchema.cast(response.data, {
        stripUnknown: true,
        assert: false
    });

    return {
        data: data.result.data,
        pagination: data.result.meta as Pagination
    };
}

interface CampaignsStatisticsExcelReport {
    campaignAdId?: number[];
    advertiserId?: number[];
    startDate: string;
    endDate: string;
    placementAdId?: number;
    metrics:
        | typeof REPORT_EXCEL_METRICS_BY_CHART
        | typeof REPORT_EXCEL_METRICS_BY_TABLE
        | typeof REPORT_EXCEL_DETAIL_METRICS_BY_CHART
        | typeof REPORT_EXCEL_DETAIL_METRICS_BY_TABLE;
    groupBy:
        | typeof REPORT_EXCEL_GROUP_BY_CHART
        | typeof REPORT_EXCEL_GROUP_BY_TABLE
        | typeof REPORT_EXCEL_DETAIL_GROUP_BY_CHART
        | typeof REPORT_EXCEL_DETAIL_GROUP_BY_TABLE;
}

export async function downloadCampaignsStatisticsExcelReport(payload: CampaignsStatisticsExcelReport) {
    const response = await client<Blob>({
        url: '/rpc/advertising/campaigns/statistics/report',
        method: 'POST',
        responseType: 'blob',
        data: payload
    });

    let fileName = 'Отчёт по рекламным кампаниям';

    if (payload.startDate && payload.endDate) {
        const dates = [payload.startDate, payload.endDate].map(date => dayjs(date).format('DD.MM.YYYY'));
        fileName += ` ${dates.join('-')}`;
    }

    fileSaveAs(response.data, `${fileName}.xlsx`);
}
