import client from '@/shared/api/client';
import type { ZdravcityCategory, ZdravcityCategoryId } from '../model/ZdravcityCategory';
import type { Pagination, ServerPagination } from '@/shared/model/types/Pagination';

export interface CategoryParams {
    name?: string;
    codes?: string | string[];
    ids?: ZdravcityCategoryId[];
    pagination?: Partial<ServerPagination>;
}

/**
 * Справочник категорий Здравсити
 */
export const getZdravcityCategories = async (
    payload: CategoryParams,
    abortController?: AbortController
): Promise<{ data: ZdravcityCategory[]; pagination: Pagination }> => {
    const params: {
        page: number;
        limit: number;
        name?: string;
        codes?: string;
        ids?: string;
    } = {
        page: payload.pagination?.currentPage ?? 1,
        limit: payload.pagination?.itemsPerPage ?? 15
    };

    if (payload.name) {
        params.name = payload.name;
    }

    if (payload.codes) {
        params.codes = String(payload.codes);
    }

    if (payload.ids) {
        params.ids = String(payload.ids);
    }

    const { data } = await client.get<{ data: ZdravcityCategory[]; meta: Pagination }>('/api/v1/zdravcity-categories', {
        params,
        signal: abortController?.signal
    });

    return {
        data: data.data,
        pagination: data.meta
    };
};
