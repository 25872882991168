<template>
    <div :data-testid="`formItem_${name}`">
        <div :class="['validation-field', statusClass]">
            <slot :field="field"></slot>
            <div v-if="isVisibleErrorMessage" class="validation-field__message">
                {{ errorMessage }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'FormItem'
});
</script>

<script lang="ts" setup>
import { computed, watch } from 'vue';
import { type RuleExpression, useField } from 'vee-validate';
import { useIsFormSubmitted } from '../model/composables';

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    modelValue: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rules?: RuleExpression<any>;
    name: string;
    successStatusVisible?: boolean;
    isVisibleErrorMessage?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
    isVisibleErrorMessage: true,
    rules: ''
});

defineEmits<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: 'update:modelValue', val?: any): void;
}>();

const fieldContext = useField(
    computed(() => props.name),
    computed(() => props.rules)
);
const isFormSubmitted = useIsFormSubmitted();
const statusClass = computed(() => {
    const { valid, touched } = fieldContext.meta;
    return {
        error: (touched || isFormSubmitted.value) && !valid,
        success: props.successStatusVisible && valid
    };
});

watch(
    () => props.modelValue,
    value => {
        fieldContext.value.value = value;
    },
    {
        deep: true,
        immediate: true
    }
);

const field = computed(() => {
    return {
        modelValue: fieldContext.value.value,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        'onUpdate:modelValue': (value?: any) => {
            fieldContext.value.value = value;
        },
        onBlur: (event?: unknown) => {
            fieldContext.handleBlur(event instanceof Event ? event : undefined);
        },
        onReset: () => {
            fieldContext.handleReset();
        }
    };
});

const errorMessage = computed(() => {
    const errorMessage = fieldContext.errorMessage.value ?? '';
    return errorMessage.replace(/^"|"$/g, '');
});
</script>
