import { QueueFileTaskStatus, QueueFileTaskType } from './types';

export const QUEUE_FILE_TASK_TYPE_NAME: Record<QueueFileTaskType, string> = {
    [QueueFileTaskType.KEYWORD_STATISTICS_PARSE]: 'Шаблон из загруженных данных',
    [QueueFileTaskType.KEYWORD_STATISTICS_REPORT]: 'Шаблон отчёта',
    [QueueFileTaskType.PRODUCT_PRICES_REPORT]: 'Отчёт по ценам',
    [QueueFileTaskType.PRODUCTS_REPORT]: 'Отчёт по товарам'
};

export const QUEUE_FILE_TASK_STATUS_NAME: Record<QueueFileTaskStatus, string> = {
    [QueueFileTaskStatus.NEW]: 'В обработке',
    [QueueFileTaskStatus.IN_PROGRESS]: 'В обработке',
    [QueueFileTaskStatus.FAILED]: 'Ошибка',
    [QueueFileTaskStatus.COMPLETED]: 'Завершено'
};
