<template>
    <label
        :class="[
            'control',
            {
                'control--focused': focused,
                'control--has-value': hasValue,
                'control--is-ghost': isGhost
            }
        ]"
        :disabled="disabled ? disabled : null"
    >
        <div class="control__wrapper">
            <div class="control__placeholder">
                <slot name="label">{{ label }}</slot>
            </div>
            <!-- @slot default slot replace input -->
            <slot>
                <input
                    :id="id"
                    class="control__element"
                    autocapitalize="none"
                    :autocomplete="autocomplete"
                    :type="type"
                    :placeholder="placeholder"
                    :value="modelValue"
                    :disabled="disabled ? disabled : undefined"
                    :readonly="readonly ? readonly : undefined"
                    data-testid="input"
                    @input="onInput"
                    @change="onChange"
                    @focus="onFocus"
                    @blur="onBlur"
                />
            </slot>
        </div>

        <div class="control__actions">
            <!-- @slot append slot -->
            <slot name="append"></slot>

            <PButton
                v-if="clearable && modelValue"
                class="control__clear-button"
                variant="text"
                icon="close"
                data-testid="clear"
                @click.stop.prevent="clearValue"
            />
        </div>
    </label>
</template>

<script lang="ts">
import PButton from '@/shared/ui/PButton/PButton.vue';
import { computed, defineComponent, type PropType, ref } from 'vue';
import { generateUUID } from '@/utils';

export default defineComponent({
    name: 'InputText',

    components: {
        PButton
    },

    props: {
        /**
         * Значение введенного в input
         * @model
         */
        modelValue: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            type: [String, Number, Object] as PropType<any>,
            default: undefined
        },

        /**
         * Тип поля ввода
         */
        type: {
            type: String,
            default: 'text'
        },

        label: {
            type: String,
            default: ''
        },

        /**
         * Строка для placeholder
         */
        placeholder: {
            type: String,
            default: ''
        },

        /**
         * Скрыть/показать пароль
         */
        visiblePassword: {
            type: Boolean,
            default: false
        },

        /**
         * Отображение без border'a
         */
        isGhost: {
            type: Boolean,
            default: false
        },

        /**
         * Отображение кнопки для сброса значения
         */
        clearable: {
            type: Boolean,
            default: false
        },

        autocomplete: {
            type: String,
            default: 'off'
        },

        readonly: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false
        }
    },

    emits: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        blur: (event: Event) => true,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        focus: (event: Event) => true,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        input: (event: Event) => true,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        change: (event: Event) => true,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        clear: (event: Event) => true,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        'update:modelValue': (val?: string | null) => true
    },

    setup(props, ctx) {
        const id = generateUUID();
        const focused = ref<boolean>(false);

        const hasValue = computed<boolean>(() => {
            const isEmpty: boolean =
                props.modelValue === null || props.modelValue === undefined || props.modelValue === '';
            return !isEmpty || !!props.placeholder;
        });

        const onInput = (event: Event) => {
            ctx.emit('update:modelValue', (event.target as HTMLInputElement).value);
            ctx.emit('input', event);
        };

        const onChange = (event: Event) => {
            ctx.emit('change', event);
        };

        const clearValue = (event: Event) => {
            ctx.emit('update:modelValue');
            ctx.emit('change', event);
            ctx.emit('clear', event);
        };

        const onFocus = (event: Event) => {
            focused.value = true;
            ctx.emit('focus', event);
        };

        const onBlur = (event: Event) => {
            focused.value = false;
            ctx.emit('blur', event);
        };

        return {
            id,
            hasValue,
            focused,
            onInput,
            onChange,
            clearValue,
            onFocus,
            onBlur
        };
    }
});
</script>
