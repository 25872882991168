import { inject, type InjectionKey, provide, type Ref } from 'vue';

const FORM_SUBMIT_SYMBOL = Symbol('is_form_submit') as InjectionKey<Ref<boolean>>;

export const useInitFormSubmitted = (isSubmitted: Ref<boolean>): void => {
    provide(FORM_SUBMIT_SYMBOL, isSubmitted);
};

export const useIsFormSubmitted = (): Ref<boolean> => {
    const isFormSubmit = inject(FORM_SUBMIT_SYMBOL);
    if (!isFormSubmit) {
        throw new Error('Можно использовать только после вызова useInitFormSubmit');
    }
    return isFormSubmit;
};
