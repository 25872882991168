export const getFileNameByUrl = (url: string): string => {
    try {
        const urlInstance = new URL(url);
        const file = new URLSearchParams(urlInstance.search);
        const fullFileName = file.get('filename');
        let name = fullFileName?.split('.').slice(1).join('.');
        if (!name) {
            name = urlInstance.pathname.split('/').pop();
        }
        return name || url;
    } catch (error) {
        return url;
    }
};
