<template>
    <label
        v-loading.skeleton="loading"
        :class="[
            'input-edit',
            {
                'input-edit--is-edit': isEdit,
                'input-edit--loading': loading
            }
        ]"
    >
        <input
            ref="inputElement"
            class="input-edit__element"
            type="text"
            spellcheck="false"
            autocapitalize="none"
            autocomplete="off"
            :disabled="loading"
            @focus="onSwitchEdit"
            @blur="updateValue"
            @keydown.enter.esc="onBlur"
        />
        <span class="input-edit__space-ghost">{{ maskedValue }}</span>
        <PIcon class="input-edit__icon" name="edit" size="20" />
    </label>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { PIcon } from '@/shared/ui';
import { type MaskOptions, useInputMask } from '@/shared/model/composables/useInputMask';
import { NetworkError } from '@/errors/NetworkError';
import { notifyError } from '@/shared/model/utils/showNotify';
import sentry from '@/shared/lib/sentry/sentry';
import { logger } from '@/shared/model/utils';

interface UpdateMethodParams {
    placementId: number;
    year: number;
    value: number;
    categoryType?: string;
    month?: number;
}

interface Props {
    year: string | number;
    placementId: number;
    categoryType?: string;
    month?: string | number;
    maskOptions?: MaskOptions;
    updateMethod: (params: UpdateMethodParams) => Promise<void>;
}

const props = withDefaults(defineProps<Props>(), {
    categoryType: undefined,
    month: undefined,
    maskOptions: undefined
});

const integer = defineModel<number | null | undefined>('modelValue', { required: true });

const loading = ref(false);
const isEdit = ref(false);
const initialValue = ref<number | null | undefined>(0);

const { inputElement, maskedValue } = useInputMask(integer, 'number', {
    thousandsSeparator: ' ',
    scale: 0,
    ...props.maskOptions
});

const onBlur = () => {
    isEdit.value = false;
    initialValue.value = 0;
    (inputElement.value as HTMLInputElement)?.blur();
};

const onSwitchEdit = () => {
    isEdit.value = !isEdit.value;
    initialValue.value = integer.value;
    (inputElement.value as HTMLInputElement)?.select();
};

const updateValue = async () => {
    try {
        isEdit.value = false;
        integer.value ??= 0;

        if (initialValue.value === integer.value) {
            return;
        }

        loading.value = true;

        const payload = {
            placementId: props.placementId,
            year: Number(props.year),
            value: integer.value ?? 0,
            ...(props.month ? { month: Number(props.month) } : undefined),
            ...(props.categoryType ? { categoryType: props.categoryType } : undefined)
        };

        await props.updateMethod(payload);
    } catch (error) {
        let message = 'Ошибка при обработки данных';

        if (error instanceof NetworkError) {
            message += '. ' + error.message;
        }

        integer.value = initialValue.value;

        notifyError({ title: 'Ошибка', text: message });
        sentry.captureException(error);
        logger.error(error);
        throw error;
    } finally {
        loading.value = false;
    }
};
</script>

<style lang="scss" scoped>
.input-edit {
    position: relative;
    display: block;
    padding: 0 30px 0 16px;
    height: 40px;
    border: 2px dashed transparent;
    border-radius: var(--border-radius-8);
    font-size: var(--text-size-16);
    font-weight: 400;
    color: var(--text-color);
    transition: all 0.2s ease;
    cursor: pointer;

    &:hover:not(.input-edit--loading) {
        background-color: var(--white);
        border-color: var(--border-light);
    }

    &:focus {
        outline: none;
    }

    &--is-edit {
        border-style: solid;
        background-color: var(--white);
        border-color: var(--border-light);
    }

    &__element {
        position: absolute;
        inset: 0;
        appearance: none;
        padding: 0 30px 0 16px;
        border: none;
        line-height: 1;
        font-size: var(--text-size-16);
        font-weight: 400;
        background-color: inherit;
        border-radius: var(--border-radius-8);

        transition: all 0.14s ease;
        cursor: inherit;

        &[disabled] {
            background-color: inherit;
            cursor: not-allowed;
        }

        &:focus {
            outline: none;
        }

        &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 50px white inset;
            -webkit-text-fill-color: #333;
        }

        &::-ms-clear {
            display: none;
        }
    }

    &__space-ghost {
        white-space: nowrap;
        line-height: 40px;
        height: 40px;
        display: inline-block;
        visibility: hidden;
    }

    &__icon {
        position: absolute;
        right: 4px;
        top: 8px;
        opacity: 0;
        color: var(--gray-light);

        @at-root .input-edit:hover & {
            opacity: 1;
        }
    }
}
</style>
