import sentry from '@/shared/lib/sentry/sentry';
import * as AuthService from '../api/AuthService';
import { useUserStore } from '@/shared/model/store/UserStore';
import { type CompanyId, CompanyRegistrationStage } from '@/shared/model/types/Company';
import * as UserService from '@/shared/api/modules/User';
import * as CompanyService from '@/shared/api/modules/Company';
import { accessToken, impersonateToken, removeToken, setAccessToken } from '@/shared/model/TokenService';
import { getImpersonationUser } from '@/api/auth';
import Analytics from '@/shared/lib/analytics/Analytics';
import { type UserId, UserStatus } from '@/shared/model/types/User';
import { useStore } from '@/shared/model/store/Store';
import { setActiveCompany } from '@/api/json-rpc/userActiveCompany';

export const login = async (payload: Parameters<typeof AuthService.login>[0]) => {
    const authUser = await AuthService.login(payload);

    sentry.setUser({
        id: authUser.userId
    });

    accessToken.set(authUser.accessToken);
    const store = useStore();
    await store.init();
    // завести задачу на бек, на создание одного api endpoint которые возвращает данные пользователя по token
};

export const logout = async () => {
    const userStore = useUserStore();
    removeToken();

    // store.dispatch('logout')
    const store = useStore();
    await store.init();

    // logout в самостоятельных store на pinia
    await userStore.logout();
    sentry.unsetUser();
    Analytics.send({
        action: 'logout'
    });
    Analytics.logout();
};

export const verifyInviteLink = async (hash: string) => {
    const userStore = useUserStore();
    const authUser = await AuthService.verifyLink(hash);

    /**
     * Приглашённого пользователя принудительно заставляем задать пароль
     */
    userStore.isRequestChangePassword = true;
    accessToken.set(authUser.accessToken);

    const store = useStore();
    await store.init();
};

export const confirmationEmail = async (hash: string) => {
    const authUser = await AuthService.verifyLink(hash);
    accessToken.set(authUser.accessToken);
    /**
     * думаю смену статуса пользователя и статуса компании лучше перенести на бекенд
     */
    await UserService.updateUser(authUser.userId, { status: UserStatus.ACTIVE });
    const {
        companies: [company]
    } = await CompanyService.getCompanies({ userId: authUser.userId });
    await CompanyService.updateCompany({
        id: company.id,
        registrationStage: CompanyRegistrationStage.PRE_REGISTRATION
    });
    const store = useStore();
    await store.init();
};

export const switchUserToActiveCompany = async (payload: { userId: UserId; companyId: CompanyId }) => {
    const activeData = await setActiveCompany(payload.userId, payload.companyId);
    setAccessToken((activeData as { accessToken: string }).accessToken);
    const store = useStore();
    await store.init();
};

export const switchActiveProfileToImpersonateProfile = async ({
    companyId,
    email
}: {
    companyId: CompanyId;
    email: string;
}) => {
    const userStore = useUserStore();
    const user = await getImpersonationUser({ email, companyId });
    impersonateToken.set(user.accessToken);
    userStore.isActiveImpersonateUser = true;
    const store = useStore();
    await store.init(companyId);
};

export const switchImpersonateProfileToActiveProfile = async () => {
    const userStore = useUserStore();
    impersonateToken.remove();
    userStore.isActiveImpersonateUser = false;
    const store = useStore();
    await store.init();
};
