import { computed, onMounted, type Ref, ref, watch } from 'vue';
import type { FactoryOpts } from 'imask';
import { IMask, useIMask } from 'vue-imask';
import { dayjs } from '@/shared/lib/dayjs/dayjs';

export type MaskOptions = FactoryOpts & {
    dateFormatString?: string;
};

export enum InputType {
    TEXT = 'text',
    NUMBER = 'number',
    DATE = 'date',
    PASSWORD = 'password'
}

export function useInputMask(
    modelValue: Ref<number | string | null | undefined>,
    inputType: 'text' | 'number' | 'date' | 'password',
    options?: MaskOptions
) {
    const maskReady = ref(false);

    const optionsInit = computed(() => {
        let config = options;

        if (inputType === InputType.DATE) {
            config = {
                ...config,
                mask: Date,
                pattern: 'd{.}`m{.}`Y',
                lazy: false,
                autofix: true,
                overwrite: true,
                blocks: {
                    d: { mask: IMask.MaskedRange, placeholderChar: 'д', from: 1, to: 31, maxLength: 2 },
                    m: { mask: IMask.MaskedRange, placeholderChar: 'м', from: 1, to: 12, maxLength: 2 },
                    Y: { mask: IMask.MaskedRange, placeholderChar: 'г', from: 1900, to: 2050, maxLength: 4 }
                },
                dateFormatString: options?.dateFormatString ?? 'YYYY-MM-DD'
            } as MaskOptions;
        }

        if (inputType === InputType.NUMBER) {
            config = {
                mask: Number,
                min: 0,
                ...config
            } as MaskOptions;
        }

        if (inputType === InputType.PASSWORD) {
            config = {
                mask: /^\S*$/
            } as MaskOptions;
        }

        return config ?? { mask: /./ };
    });

    const { el, mask, typed, masked, unmasked } = useIMask(
        computed(() => optionsInit.value),
        {
            onAccept: () => {
                if (!maskReady.value) return;

                switch (inputType) {
                    case InputType.NUMBER:
                        formatNumberMask();
                        break;
                    case InputType.DATE:
                        formatDateMask();
                        break;
                    default:
                        formatDefaultMask();
                        break;
                }
            }
        }
    );

    function formatNumberMask() {
        if (masked.value === '') {
            const currentValue = modelValue.value;
            modelValue.value = currentValue !== null && currentValue !== undefined ? undefined : currentValue;
            return;
        }

        modelValue.value = typeof typed.value !== 'number' ? Number(typed.value) : typed.value;
    }

    function formatDateMask() {
        if (typed.value === null || typed.value === '') {
            modelValue.value = modelValue.value ? '' : modelValue.value;
            return;
        }

        const date = typed.value instanceof Date ? typed.value : dayjs(typed.value).toDate();
        modelValue.value = dayjs(date).format(optionsInit.value.dateFormatString);
    }

    function formatDefaultMask() {
        if (modelValue.value || unmasked.value) {
            modelValue.value = unmasked.value;
        }
    }

    function updateTypedValue(value: number | string | null | undefined) {
        if (!maskReady.value) return;

        if (value && inputType === InputType.DATE) {
            typed.value = dayjs(value, optionsInit.value.dateFormatString).toDate();
        } else {
            typed.value = value ?? '';
        }
    }

    watch(modelValue, updateTypedValue);

    onMounted(() => {
        if (mask.value) {
            maskReady.value = true;
            updateTypedValue(modelValue.value);
        }
    });

    return {
        inputElement: el,
        maskedValue: masked
    };
}
