import client from '@/shared/api/client';
import { CampaignTypes } from '@/entities/Campaign';
import type { CompanyAdvId, CompanyId } from '@/shared/model/types/Company';
import type { Pagination, ServerPagination } from '@/shared/model/types/Pagination';

export interface CampaignParams {
    name?: string;
    ids?: CampaignTypes.CampaignId[];
    providerIds?: CompanyId[];
    adIds?: CompanyAdvId[];
    statuses?: CampaignTypes.CampaignStatus[];
    placementId?: number;
    placementIds?: number[];
    startDate?: string;
    endDate?: string;
    erId?: string;
    /**
     * Предоставляет ли данная РК отчётность
     */
    receiveReport?: boolean;
    pagination?: ServerPagination;
    hasGoods?: boolean;
}
export const getCampaigns = async (
    payload: CampaignParams = {
        statuses: [CampaignTypes.CampaignStatus.ACTIVE, CampaignTypes.CampaignStatus.INACTIVE]
    },
    abortController?: AbortController
): Promise<{
    campaigns: CampaignTypes.Campaign[];
    pagination: Pagination;
}> => {
    const params: Record<string, string | number | boolean | (string | number)[]> = {
        page: payload.pagination?.currentPage ?? 1,
        limit: payload.pagination?.itemsPerPage ?? 10
    };
    if (payload.ids && payload.ids.length) {
        params.id = payload.ids;
    }
    if (payload.adIds && payload.adIds.length) {
        params.adId = payload.adIds;
    }
    if (payload.statuses && payload.statuses.length) {
        params.status = payload.statuses;
    }
    if (payload.providerIds && payload.providerIds.length) {
        params.providerId = payload.providerIds;
    }
    if (payload.name) {
        params.name = payload.name;
    }
    if (payload.placementId) {
        params.placementId = [payload.placementId];
    }
    if (payload.placementIds && payload.placementIds.length) {
        params.placementId = payload.placementIds;
    }
    if (payload.erId) {
        params.erId = payload.erId;
    }
    if (payload.receiveReport) {
        params.receiveReport = payload.receiveReport;
    }
    if (payload.startDate) {
        params.startDate = payload.startDate;
    }
    if (payload.endDate) {
        params.endDate = payload.endDate;
    }
    if (payload.hasGoods) {
        params.hasGoods = payload.hasGoods;
    }

    const { data } = await client({
        method: 'post',
        url: `/api/advertising-campaigns/find`,
        data: params,
        signal: abortController?.signal
    });

    let campaigns: CampaignTypes.Campaign[] = [];
    if (Array.isArray(data.items)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        campaigns = data.items.map((campaign: any) => {
            const paramValues = Array.isArray(campaign.paramValues) ? campaign.paramValues : [];
            const paramState = Array.isArray(campaign.paramState) ? campaign.paramState : [];
            return {
                ...campaign,
                paramValues,
                paramState
            };
        });
    }

    return {
        campaigns,
        pagination: {
            totalItems: data.meta.totalItems,
            currentPageItemsCount: campaigns.length,
            itemsPerPage: data.meta.itemsPerPage,
            totalPages: data.meta.totalPages,
            currentPage: data.meta.currentPage
        }
    };
};

const formatCampaignPayload = (payload: CampaignTypes.Campaign) => {
    return {
        ...payload,
        exclude: payload.exclude.map(String),
        dataDispair: false
    };
};

/**
 * Добавить рекламную кампанию
 */
export const addCampaign = async (payload: CampaignTypes.Campaign) => {
    const response = await client({
        method: 'POST',
        url: '/api/advertising-campaigns',
        data: formatCampaignPayload(payload)
    });

    return response.data;
};

/**
 * Получить данные рекламной кампании по ID
 */
export const getCampaignById = async (id: CampaignTypes.CampaignId): Promise<CampaignTypes.Campaign> => {
    const { data } = await client<CampaignTypes.Campaign>(`/api/advertising-campaigns/${id}`);
    return data;
};

/**
 * Обновить рекламную кампанию
 */
export const updateCampaign = async (payload: CampaignTypes.Campaign) => {
    const response = await client<CampaignTypes.Campaign>({
        method: 'PUT',
        url: `/api/advertising-campaigns/${payload.id}`,
        data: formatCampaignPayload(payload)
    });

    return response.data;
};

/**
 * Удалить рекламную кампанию по ID
 */
export const deleteCampaignById = (id: CampaignTypes.CampaignId) => {
    return client({
        method: 'DELETE',
        url: `/api/advertising-campaigns/${id}`
    });
};
