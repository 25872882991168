import type { Brand } from '@/shared/model/types/Kernel';
import type { StaffId } from '@/entities/Staff';

export enum CompanyStatus {
    /**
     * Активные
     */
    ACTIVE = 'active',

    /**
     * Не активные
     */
    INACTIVE = 'inactive',

    /**
     * Заявка на сотрудничество
     */
    REQUEST = 'request',

    /**
     * Приглашенные
     */
    INVITED = 'invited',

    /**
     * Заблокированные
     */
    BLOCKED = 'blocked',

    /**
     * Не подтвержденная
     */
    UNCONFIRMED = 'unconfirmed'
}

/**
 * Тип регистрации компании
 */
export type CompanyType = 'RESIDENT' | 'IP' | 'NON-RESIDENT';

/**
 * Этапы заполненных форм при регистрации
 */
export enum CompanyRegistrationStage {
    PRE_REGISTRATION = 'PRE-REGISTRATION',
    COMMON = 'COMMON',
    BANK_ACCOUNTS = 'BANK-ACCOUNTS',
    FORM = 'FORM',
    DOCUMENTS = 'DOCUMENTS',
    CONTRACT = 'CONTRACT',
    COMPLETE = 'COMPLETE',
    ADDITIONAL = 'ADDITIONAL'
}

/**
 * Система налогообложения компании
 */
export type CompanyTaxSystemType =
    | 'COMMON' // Общая (с НДС)
    | 'SIMPLE'; // Упрощённая (без НДС)

export type CompanyLegalFormType =
    | 'ГУП'
    | 'ГКП'
    | 'ПТ'
    | 'МУП'
    | 'ГО'
    | 'АО'
    | 'ОО'
    | 'НП'
    | 'ИП'
    | 'ОКУ'
    | 'ООО'
    | 'ОАО'
    | 'ПБОЮЛ'
    | 'учр.'
    | 'ПК'
    | 'фонд'
    | 'ГБУ'
    | 'ФЛ'
    | 'ПАО'
    | 'МУ'
    | 'ГБУЗ'
    | 'АНО'
    | 'ЗП'
    | 'ГК'
    | 'ГКУЗ'
    | 'ГУ'
    | 'ОДО'
    | 'ПО'
    | 'ОЮЛ'
    | 'ПКООП'
    | 'ФБУЗ'
    | 'ЗАО'
    | 'НАО';

export type CompanyId = Brand<number, 'company'>;
export const MakeCompanyId = (id: number): CompanyId => id as CompanyId;
// ID на рекламном сервере, он же луна
export type CompanyAdvId = Brand<number, 'companyAdvertiser'>;
// ID отделения компании в Протек
export type CompanyProtekId = Brand<number, 'companyWabcCode'>;
// ID компании в Протек
export type CompanyVendorId = Brand<number, 'companyVendor'>;
// ID компании в СПАРГО, он же единый справочник
export type CompanySystemId = Brand<number, 'companySpargo'>;

/**
 * Данные компаний
 */
export interface Company {
    id: CompanyId;
    advertiserId: null | CompanyAdvId;
    systemId: null | CompanySystemId;
    wabcCode: null | CompanyProtekId;
    vendorId: null | CompanyVendorId;
    name: string;
    inn: string | null;
    registrationStage: null | CompanyRegistrationStage;
    type: null | CompanyType;
    taxSystem: null | CompanyTaxSystemType;
    kpp: null | string;
    legalForm: null | CompanyLegalFormType;
    ogrn: null | string;
    ogrnip: null | string;
    ogrnDate: null | string;
    oktmo: null | string;
    telephone: null | string;
    site: null | string;
    addressMatch: boolean | null;
    countUsers: number;
    activeUsers: number;
    status: CompanyStatus;
    startDateReports: null | string;
    endDateReports: null | string;
    advertisingDiscount: number;
    email: string | null;
    fullForm: string;
    phoneCode: null | string;
    commercialManagerId: null | StaffId;
    adSalesReport: boolean;
    readonly createdAt: string;
    readonly updatedAt: string;
    actionPromoStartDate: string | null;
    formId: number | null;
    shortNameCompany: null;
    contactName: string;
    contactPhone: string;
    contactEmail: string;
}

export interface CompanyRelationships {
    id: CompanyId;
    addressIds: number[];
    advertisingCampaignsDraftIds: number[];
    bankAccountIds: number[];
    contractIds: number[];
    documentIds: number[];
    managerIds: number[];
    userIds: number[];
    wabcCodeIds: number[];
    form: number;
    commercialManagerId: StaffId | null;
}
