import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { type Placement, type PlacementAdId, type PlacementId } from './Placement';
import { useAccessStore } from '@/shared/model/store/AccessStore';
import type { Permission } from '@/shared/model/types/Permission';
import * as PlacementService from '../api/PlacementService';

export const usePlacementStore = defineStore('placement', () => {
    const placements = ref<Placement[]>([]);
    const accessStore = useAccessStore();

    const placementsIdsMap = computed(() => {
        return new Map(placements.value.map(placement => [placement.id, placement]));
    });

    const placementsAdIdsMap = computed(() => {
        return new Map(placements.value.map(placement => [placement.adId, placement]));
    });

    const getPlacementById = (id: PlacementId) => {
        return placementsIdsMap.value.get(id);
    };

    const getPlacementNameById = (id: PlacementId) => {
        return placementsIdsMap.value.get(id)?.name;
    };

    const getPlacementNameByAdId = (adId: PlacementAdId) => {
        return placementsAdIdsMap.value.get(adId)?.name;
    };

    const getPlacementByAdId = (adId: PlacementAdId) => {
        return placementsAdIdsMap.value.get(adId);
    };

    const placementsArrayForUser = computed(() => {
        if (accessStore.isManager) {
            return placements.value;
        }

        const accessUserPermissionsKeys = [...accessStore.permissionsUser]
            .map(key => accessStore.permissions.get(key) as Permission)
            .filter(permission => permission?.visible)
            .map(permission => permission?.key);

        const permissionKey = new Set(accessUserPermissionsKeys);

        return placements.value.filter(
            placement => placement.permissionKey && permissionKey.has(placement.permissionKey)
        );
    });

    const fetchPlacements = async () => {
        placements.value = await PlacementService.getPlacements();
    };

    return {
        placements,
        placementsArrayForUser,

        getPlacementById,
        getPlacementByAdId,
        getPlacementNameById,
        getPlacementNameByAdId,

        fetchPlacements
    };
});
