<template>
    <PSelect
        v-model="value"
        label-key="name"
        search
        :value-key="valueKey"
        :return-object="returnObject"
        :clearable="clearable"
        :disabled="disabled"
        :label="label"
        :loading="isSearching || isLoadingInit"
        :multiple="multiple"
        :options="options"
        :tags="tags"
        @update:search-input="searchCampaigns"
        @add-item="changeItem"
        @remove-item="changeItem"
        @open="prefetchData"
    >
        <template #afterList>
            <div v-if="!isLoadedAllData" ref="lastItemEl" class="p-[10px_20px] text-gray-500 mb-4">
                <div v-if="isLoadingItemData" class="loading-item">Загрузка...</div>
            </div>
        </template>
    </PSelect>
</template>

<script setup lang="ts" generic="T extends Campaign | CompanyAdvId | CampaignId">
import { computed, onMounted, ref, watch } from 'vue';
import { CanceledError } from 'axios';
import { useIntersectionObserver } from '@vueuse/core';
import { onBeforeRouteLeave } from 'vue-router';
import uniqBy from 'lodash/uniqBy';
import isEqual from 'lodash/isEqual';
import { PSelect } from '@/shared/ui';
import type { Pagination } from '@/shared/model/types/Pagination';
import { notifyError } from '@/shared/model/utils/showNotify';
import { logger } from '@/shared/model/utils';
import { NetworkError } from '@/errors/NetworkError';
import sentry from '@/shared/lib/sentry/sentry';

import type { Campaign, CampaignId } from '../model/types';
import * as CampaignService from '../api/CampaignService';
import { CampaignStatus } from '../model/types';
import type { CampaignParams } from '../api/CampaignService';
import type { CompanyAdvId } from '@/shared/model/types/Company';

type ModelValue = T | T[] | null;

enum Status {
    DEFAULT = 'default',
    LOADING_ITEM = 'loading-item',
    LOADING_SEARCH = 'loading-search',
    LOADING_ALL = 'loading-all',
    LOADING_INIT = 'loading-init'
}

defineOptions({
    name: 'CampaignFilter'
});

const props = withDefaults(
    defineProps<{
        modelValue: ModelValue;
        label?: string;
        valueKey?: 'id' | 'adId';
        disabled?: boolean;
        multiple?: boolean;
        clearable?: boolean;
        tags?: boolean;
        filters?: Omit<CampaignParams, 'name' | 'pagination'>;
        returnObject?: boolean;
        prefetch?: boolean;
        onlySearch?: boolean;
    }>(),
    {
        label: 'Рекламная кампании',
        valueKey: 'adId',
        disabled: false,
        multiple: false,
        clearable: true,
        tags: false,
        returnObject: false,
        filters: undefined,
        prefetch: false,
        onlySearch: false
    }
);

const emit = defineEmits<{
    (e: 'change', val: ModelValue): void;
}>();

const value = defineModel<ModelValue>('modelValue', {
    required: true,
    local: true
});

const ITEMS_PER_PAGE = 15;
const searchValue = ref('');
const options = ref<Campaign[]>([]);
const lastItemEl = ref<HTMLElement>();
const status = ref<Status>(Status.DEFAULT);
const currentPage = ref(0);
let abortController: AbortController;

const isLoadingInit = computed(() => status.value === Status.LOADING_INIT);
const isLoadedAllData = computed(() => status.value === Status.LOADING_ALL);
const isLoadingItemData = computed(() => status.value === Status.LOADING_ITEM);
const isSearching = computed(() => status.value === Status.LOADING_SEARCH);

const formatValueKeyForRequest = (key: keyof Campaign) => {
    const keyRequestMap = {
        id: 'adIds',
        adId: 'adIds'
    } as Record<keyof Campaign, keyof CampaignParams>;

    return keyRequestMap[key] as keyof CampaignParams;
};

const getCampaignFromValues = (inputValue: ModelValue): T[] => {
    if (inputValue === null) {
        return [];
    }

    const formatValues = Array.isArray(inputValue) ? inputValue : [inputValue];

    if (!props.returnObject) {
        return formatValues;
    }

    return formatValues.map(value => ('id' in value && (value[props.valueKey] as T)) || value);
};

const checkAllDataLoaded = (pagination: Pagination) => {
    return pagination.totalItems <= pagination.currentPage * pagination.itemsPerPage;
};

const searchParams = computed(() => ({
    statuses: [CampaignStatus.ACTIVE, CampaignStatus.INACTIVE],
    ...props.filters
}));

const initialData = async (campaignParams: T[]) => {
    if (props.onlySearch) return;

    try {
        abortController?.abort();
        abortController = new AbortController();
        status.value = Status.LOADING_INIT;
        currentPage.value = 1;

        const { campaigns } = await CampaignService.getCampaigns(
            {
                [formatValueKeyForRequest(props.valueKey)]: campaignParams,
                pagination: { currentPage: currentPage.value, itemsPerPage: ITEMS_PER_PAGE },
                ...searchParams.value
            },
            abortController
        );

        options.value = campaigns;
    } catch (error) {
        if (error instanceof CanceledError) {
            return [];
        }

        let message = 'Произошла ошибка при инициализации данных поиска по товарам';

        if (error instanceof NetworkError) {
            message += '. ' + error.message;
        }

        notifyError(message);
        sentry.captureException(error);
        logger.error(error);
    } finally {
        status.value = Status.DEFAULT;
    }
};

watch(
    () => props.modelValue,
    async newValue => {
        if (!newValue || (Array.isArray(newValue) && newValue.length === 0)) {
            return;
        }

        if (!isEqual(newValue, value.value)) {
            const campaignValues = getCampaignFromValues(newValue);
            await initialData(campaignValues);
        }
    },
    { immediate: true, deep: true }
);

watch(
    () => props.filters,
    (newFilters, oldFilters) => {
        if (!isEqual(newFilters, oldFilters)) {
            options.value = [];
            value.value = props.multiple ? [] : null;
        }
    }
);

const searchCampaigns = async (search: string) => {
    try {
        searchValue.value = search;
        abortController?.abort();
        abortController = new AbortController();

        status.value = Status.LOADING_SEARCH;
        currentPage.value = 1;
        const { campaigns, pagination } = await CampaignService.getCampaigns(
            {
                ...searchParams.value,
                name: search,
                pagination: { currentPage: currentPage.value, itemsPerPage: ITEMS_PER_PAGE }
            },
            abortController
        );

        options.value = campaigns;

        if (checkAllDataLoaded(pagination)) {
            status.value = Status.LOADING_ALL;
            return;
        }

        status.value = Status.DEFAULT;
    } catch (error) {
        if (error instanceof CanceledError) {
            return [];
        }

        let message = 'Произошла ошибка при поиске данных товаров';

        if (error instanceof NetworkError) {
            message += '. ' + error.message;
        }

        notifyError(message);
        sentry.captureException(error);
        logger.error(error);
        status.value = Status.DEFAULT;
    }
};

const changeItem = () => {
    emit('change', value.value);
};

const prefetchData = async () => {
    if (props.prefetch) {
        await searchCampaigns('');
    }
};

const getNextPage = async (params: CampaignParams) => {
    try {
        status.value = Status.LOADING_ITEM;
        return await CampaignService.getCampaigns(params);
    } catch (error) {
        let message = 'Произошла ошибка при запросе списка рекламных кампаний';

        if (error instanceof NetworkError) {
            message += '. ' + error.message;
        }

        notifyError(message);
        sentry.captureException(error);
        logger.error(error);
    } finally {
        status.value = Status.DEFAULT;
    }
};

useIntersectionObserver(lastItemEl, async ([{ isIntersecting }]) => {
    if (isLoadedAllData.value || isLoadingItemData.value) {
        return;
    }

    if (isIntersecting) {
        const payload = {
            pagination: { currentPage: (currentPage.value += 1), itemsPerPage: ITEMS_PER_PAGE },
            ...searchParams.value
        } as CampaignParams;

        const response = await getNextPage(payload);

        if (!response) {
            return;
        }

        const { campaigns, pagination } = response;

        if (checkAllDataLoaded(pagination)) {
            status.value = Status.LOADING_ALL;
        }

        options.value = uniqBy([...options.value, ...campaigns], item => item.id);
    }
});

onMounted(async () => {
    const campaignParams = getCampaignFromValues(value.value);

    if (campaignParams?.length) {
        await initialData(campaignParams);
    }
});

onBeforeRouteLeave(() => {
    abortController?.abort();
});
</script>

<style lang="scss" scoped>
.loading-item {
    display: flex;

    &:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 2px solid var(--text-color-light);
        border-top-color: transparent;
        animation: rotate 0.7s linear infinite;
        margin-right: 1rem;
    }

    @keyframes rotate {
        to {
            transform: rotate(1turn);
        }
    }
}
</style>
