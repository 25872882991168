<template>
    <teleport to="#modals">
        <transition name="modal">
            <div v-if="isOpen" class="modal-backdrop" :style="styleBackdrop" @click.stop.self="closeOnBackdrop">
                <focus-trap :active="isActiveFocusTrap" :escape-deactivates="false">
                    <div v-loading="loading" class="modal" :style="styleModal" :class="classModal">
                        <PButton
                            v-if="visibleCloseButton"
                            variant="text"
                            class="modal-close"
                            icon="close"
                            @click.prevent="closeModal"
                        />
                        <slot :open="openModal" :close="closeModal"></slot>
                    </div>
                </focus-trap>
            </div>
        </transition>
    </teleport>
</template>

<script lang="ts">
import { FocusTrap } from 'focus-trap-vue';
import PButton from '../PButton/PButton.vue';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'PModal',

    components: {
        PButton,
        FocusTrap
    }
});
</script>

<script lang="ts" setup>
import { isNumericString } from '@/shared/model/utils';
import { computed, onBeforeUnmount, onMounted, useAttrs } from 'vue';
import { useVModel } from '@vueuse/core';

interface Props {
    modelValue?: boolean;
    width?: number | string;
    closedOnBackdrop?: boolean;
    closedOnEscape?: boolean;
    visibleCloseButton?: boolean;
    backdropBgColor?: string;
    backdropZIndex?: number;
    loading?: boolean;
    focusTrap?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    width: 380,
    closedOnBackdrop: true,
    closedOnEscape: true,
    visibleCloseButton: true,
    backdropBgColor: 'rgba(19, 19, 19, 0.52)',
    backdropZIndex: 550,
    focusTrap: true
});

defineOptions({
    inheritAttrs: false
});

const attrs = useAttrs();

const emit = defineEmits<{
    (e: 'update:modelValue', data: boolean): void;
}>();

const isOpen = useVModel(props, 'modelValue', emit, { defaultValue: false });
const openModal = () => {
    isOpen.value = true;
};
const closeModal = () => {
    isOpen.value = false;
};

const styleBackdrop = computed(() => {
    return {
        'background-color': props.backdropBgColor,
        'z-index': props.backdropZIndex
    };
});

const classModal = computed(() => {
    return attrs.class;
});

const styleModal = computed(() => {
    if (typeof props.width === 'number' || isNumericString(props.width)) {
        return `width: ${props.width}px`;
    } else {
        return `width: ${props.width}`;
    }
});

const closeOnBackdrop = () => {
    if (props.closedOnBackdrop) {
        isOpen.value = false;
    }
};

const escapeHandler = (event: KeyboardEvent) => {
    if (!props.closedOnEscape) return;
    if (event.key === 'Escape') {
        isOpen.value = false;
    }
};

const isActiveFocusTrap = computed<boolean>(() => props.focusTrap && isOpen.value);

onMounted(() => {
    document.addEventListener('keydown', escapeHandler, false);
});

onBeforeUnmount(() => {
    document.removeEventListener('keydown', escapeHandler, false);
});
</script>

<style lang="scss" scoped>
.modal-enter-active,
.modal-leave-active {
    transition: all 0.3s;
    transform: initial;
    transform-origin: center;
}

.modal-enter-from,
.modal-leave-to {
    opacity: 0;
    transform: scale(1.1);
}

.modal-backdrop:not(:root):not([hidden]) {
    position: fixed;
    z-index: var(--z-index-modal-backdrop);
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 5rem !important;

    display: grid;
    place-items: center;

    overscroll-behavior: contain;
    overflow: auto;
    border-radius: 0;

    &::-webkit-scrollbar {
        width: 0;
    }
}

.modal {
    position: relative;
    padding: 3.2rem 4.4rem 3.2rem 3.2rem;
    display: grid;
    grid-gap: 3rem;
    background-color: var(--white);
    border-radius: var(--border-radius-16);
    box-shadow: var(--shadow-card-dark);
    transition: all 0.4s;
    transform: translateX(0);
}

.modal-close {
    position: absolute;
    right: 1.8rem;
    top: 2rem;
    width: 2.4rem;
    height: 2.4rem;
    color: var(--text-color-light);

    &:hover {
        color: var(--text-color);
    }

    &:not(:focus-visible) {
        outline: none;
    }
}
</style>
