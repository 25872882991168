import { ACTION_PROMO_TYPE_ACTION, ACTION_REPORT_ACTION_TYPE } from './constants';
import {
    ActionPromoBonusPackageSubTypeAction,
    type ActionPromoTable,
    ActionPromoTypeAction,
    ReportActionAsyncType,
    ReportActionType,
    type ReportBonusPackage,
    type ReportBonusPackageByRuble,
    type ReportSaleInPrice
} from './types';

export const getActionPromoTypeName = (actionDetail: ActionPromoTable) => {
    const isByRuble = actionDetail.actionSubtype === ActionPromoBonusPackageSubTypeAction.BY_RUBLE;
    const isBonusPackage = actionDetail.actionType === ActionPromoTypeAction.BONUS_PACKAGE;

    if (isBonusPackage && isByRuble) {
        return ACTION_PROMO_TYPE_ACTION[actionDetail.actionType] + ' за сумму заказа';
    } else {
        return ACTION_PROMO_TYPE_ACTION[actionDetail.actionType];
    }
};

export const getActionReportTypeName = (reportActionType: ReportActionType) => {
    return ACTION_REPORT_ACTION_TYPE[reportActionType];
};

export const getActionReportAsyncType = (reportActionType: ReportActionType): string => {
    switch (reportActionType) {
        case ReportActionType.SALE_IN_PRICE:
            return ReportActionAsyncType.SALE_IN_PRICE;
        case ReportActionType.BONUS_PACKAGE:
            return ReportActionAsyncType.BONUS_PACKAGE;
        case ReportActionType.BONUS_PACKAGE_BY_RUBLE:
            return ReportActionAsyncType.BONUS_PACKAGE_BY_RUBLE;
    }
};

export type ActionPromoColumnNameKey = keyof (ReportBonusPackage & ReportBonusPackageByRuble & ReportSaleInPrice);

export const getActionPromoColumnNameByKey = (columnNameKey: ActionPromoColumnNameKey) => {
    const actionPromoColumnNameMap: Record<ActionPromoColumnNameKey, string> = {
        actionName: 'Название акции',
        asName: 'Наименование сети',
        billCdU: 'Код плательщика',
        billFactAddress: 'Фактический адрес плательщика',
        billInn: 'ИНН плательщика',
        billJurAddress: 'Юридический адрес плательщика',
        billName: 'Наименование плательщика',
        bonus: 'Бонус',
        bonusDocDate: 'Дата акцепта бесплатной накладной',
        bonusDocNum: 'Бесплатная накладная',
        bonusMetrics: 'Мера бонуса',
        bonusNachisl: 'Начисление бонуса',
        bonusName: 'Наименование бесплатного препарата',
        bonusProvider1p: 'Бонус за 1 пакет, руб',
        bonusProvider: 'Бонус от поставщика, руб',
        bonusRealized: 'Выданный бонус',
        cdU: 'Код получателя',
        compensation: 'Компенсация бонусной упаковки, руб',
        compensationCdU: 'Итоговая компенсация поставщику, руб',
        contractPrice: 'Контрактная цена, руб.',
        dateAccept: 'Дата акцепта',
        dateOrd: 'Дата заказа',
        dateOrdPay: 'Дата заказа платной накладной',
        dateYear: 'Год',
        deptName: 'Сбытовое подразделение',
        divided: 'Количество пакетов',
        docDate: 'Дата акцепта платной накладной',
        docNum: 'Накладная',
        docsNumFree: 'Бесп. накладная',
        docsNumPay: 'Плат. Накладная',
        dolg: 'Долг',
        factSum: 'Фактическая отгрузка',
        filCdu: 'Код филиала',
        fpriceWNds: 'Конкурсная отгрузка  (руб с НДС)',
        fpriceWoNds: 'Конкурсная отгрузка  (руб без НДС)',
        groupCond: '№гр /№усл.',
        inn: 'ИНН получателя',
        medAmn: 'Кратность, уп',
        medicineCode: 'Код препарата',
        medicineName: 'Наименование препарата',
        medsBonus: 'Код бесплатного препарата',
        medsPlan: 'Кратные уходы (упак)',
        nmActions: 'ИД акции',
        nmAgent: 'Менеджер',
        nmCrossActions: 'Код набора условий',
        nmU: 'Наименование получателя',
        planBegin: 'План «С»',
        planEnd: 'План «До»',
        remark: 'Примечание',
        saleManagment: 'РУ',
        segmentId: 'Код сегмента',
        segmentName: 'Наименование сегмента',
        shipfactAddress: 'Фактический адрес получателя',
        shipJurAddress: 'Юридический адрес получателя',
        souzfarmaFlag: 'Признак ассоциации Союзфарма'
    };

    return actionPromoColumnNameMap[columnNameKey];
};
